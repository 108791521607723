import { useGameMechanic } from 'src/hooks';
import {Col, Row} from 'react-bootstrap';
import GameNotFound from '../../../not-found';
import { THotspotGame } from './types';
import classnames from 'classnames';
import React, { useState } from 'react';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The hotspot game component
 * @constructor
 */
function HotspotGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    points,
    changeCorrectAnswersPoints
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    questions,
    boats
  } = gameMechanic.content as unknown as THotspotGame;

  const [selected, setSelected] = useState<number[]>([]);
  const [attempts, setAttempts] = useState<number>(0);
  const [foundBoats, setFoundBoats] = useState<Record<string, number>>({
    small: 0,
    medium: 0,
    big: 0
  });

  const checkAttemptsExceeded = () => {
    const attemptsScaleLimit = 2;
    return questions && Object.values(questions).length > 0 && attempts > attemptsScaleLimit * Object.values(questions).length;
  }

  function checkBoats(i: number) {
    if (checkAttemptsExceeded()) {
      alert('Not allowed. You exceeded all attempts to find the boat.')
      return;
    }
    setAttempts(attempts + 1);
    const finalFoundedBoats = {
      small: 0,
      medium: 0,
      big: 0
    };
    const currentSelected = [...selected, i];

    let isCompleted = true;
    let isHit = false;

    Object.values(boats).map((boat) => {
      boat.map((boatIndex) => {
        if (boatIndex === i) {
          isHit = true;
        }
      })
    });

    Object.values(boats).map((boat) => {
      const boatLength = boat.length;
      let isFound = true;
      switch (boatLength) {
        case 1:
        case 2:
          boat.map((boatIndex) => !currentSelected.includes(boatIndex) ? isFound = false : null);
          if (isFound) {
            finalFoundedBoats.small = finalFoundedBoats.small + 1;
          } else {
            isCompleted = false;
          }
          return;
        case 3:
        case 4:
          boat.map((boatIndex) => !currentSelected.includes(boatIndex) ? isFound = false : null);
          if (isFound) {
            finalFoundedBoats.medium = finalFoundedBoats.medium + 1;
          } else {
            isCompleted = false;
          }
          return;
        default:
          boat.map((boatIndex) => !currentSelected.includes(boatIndex) ? isFound = false : null);
          if (isFound) {
            finalFoundedBoats.big = finalFoundedBoats.big + 1;
          } else {
            isCompleted = false;
          }
          return;
      }
    });

    if (isCompleted) {
      changeCorrectAnswersPoints(points + 100);
    }

    if (!isHit) {
      // Set a timer to change it back after 2 seconds
      setTimeout(() => {
        setSelected([...selected]);
      }, 2000);
    }
    setSelected(currentSelected);
    setFoundBoats(finalFoundedBoats);
  }

  return (
    <div className='battleships-editor'>
      <div className='hotspot'>
        <Row>
          <Col md={7}>
            <div className='numbers-container'>
              {new Array(100).fill('')
                .map((el, i) => (
                  <span
                    className={
                      classnames({ selected: selected.includes(i + 1) })
                    }
                    key={i}
                    onClick={() => checkBoats(i + 1)}
                  >
                    {i + 1}
                  </span>
                ))}
            </div>
            <div className='images mb-5 mb-md-0'>
              <Row>
                <Col xs={4} className="mb-md-0 mb-3">
                  <div className='img-wrapper'>
                    <img
                      className='img-fluid'
                      src='/assets/images/BIG_BOAT.svg'
                      alt=''
                    />
                  </div>
                  <p>x {foundBoats.big}</p>
                </Col>
                <Col xs={4} className="mb-md-0 mb-3">
                  <div className='img-wrapper'>
                    <img
                      className='img-fluid'
                      src='/assets/images/SMALL_BOAT.svg'
                      alt=''
                    />
                  </div>
                  <p>x {foundBoats.medium}</p>
                </Col>
                <Col xs={4} className="mb-md-0 mb-3">
                  <div className='img-wrapper'>
                    <img
                      className='img-fluid'
                      src='/assets/images/RAFT.svg'
                      alt=''
                    />
                  </div>
                  <p>x {foundBoats.small}</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={5}>
            <ul className='questions ps-5 ps-md-0'>
              {Object.values(questions).map(el => <li key={el} dangerouslySetInnerHTML={{__html: el}}/>)}
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HotspotGame;
