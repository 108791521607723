import { ButtonMotion } from 'src/components';
import React, { memo, useState } from 'react';
import { ICrosswordEditorClue } from './types';
import { motion } from 'framer-motion';
import EditorCloseBtn from '../../../../assets/svg/buttons/editor-close-btn';
import { filledObj } from './crossword-game-editor';



interface CluePopupProps {
  cell: number;
  saveData: (data: ICrosswordEditorClue) => void;
  onClose: VoidFunction;
  data?: filledObj,
  clickedIndex?: number,
  width?: number
}
let emptyObj = { letter:'', answer: '', row:0, col:0, clue: ''};

function CluePopup({ cell, data, saveData, onClose, clickedIndex, width }: CluePopupProps) {
  const [clueData, setClueData] = useState<filledObj>(data || { across: { direction: 'across', ...emptyObj }, down:{ direction: 'down', ...emptyObj }});
  const [clueImg, setClueImg] = useState<File>();
  const [currentDirection, setCurrentDirection] = useState<string>(  (data?.down?.answer && !data?.across?.answer) ? 'down' : 'across')

  const { direction, clue, answer, order } = clueData[currentDirection];

  const oppositeOrder = currentDirection === 'across' ? clueData.down.order : clueData.across.order

  function saveClue() {
    saveData(clueData[currentDirection]);
  }

  function uploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files?.length) {
      let file = files[0];
      setClueImg(file);
      setClueData(prev => ({ ...prev, [currentDirection]: {...prev[currentDirection], clue: URL.createObjectURL(file) }}));
    }
  }

  return (
    <div className='clue-popup'>
      <EditorCloseBtn close={onClose} />
      <h2>{order || oppositeOrder || cell}</h2>
      <ButtonMotion
        className={direction === 'across' ? 'btn-success' : 'direction-btn'}
        variant={'custom'}
        onClick={() => setCurrentDirection('across')}
      >
        Horizontal
      </ButtonMotion>
      <ButtonMotion
        className={direction === 'down' ? 'btn-success' : 'direction-btn'}
        variant={'custom'}
        onClick={() =>  setCurrentDirection('down')}
      >
        Vertical
      </ButtonMotion>
      {clueImg ? <img className="img-fluid" src={clue} /> :
        <textarea
          value={clue || ''}
          placeholder={'Clue'}
          onChange={(e) => setClueData(prev => ({ ...prev,
            [currentDirection]: {...prev[currentDirection], clue: e.target.value }}))}
        >
          {clue}
        </textarea>
      }
      {
        <textarea
          value={answer || ''}
          placeholder={'Answer'}
          onChange={(e) => setClueData(prev => ({ ...prev,
            [currentDirection]: {...prev[currentDirection], answer: e.target.value }}))}
        >
          {answer}
        </textarea>}
      {/*<motion.label whileTap={{ scale: 0.97 }}>*/}
      {/*  <input type='file' onChange={uploadFile} />*/}
      {/*  <span>Upload Image </span>*/}
      {/*</motion.label>*/}
      <ButtonMotion variant={'success'} onClick={saveClue}>Save</ButtonMotion>
    </div>
  );
}

export default memo(CluePopup);