import { useGameMechanic } from '../../../../hooks';
import GameNotFound from '../../../not-found';
import React, { useMemo, useState } from 'react';
import { THighlightGame } from './types';
import classnames from 'classnames';


export default function HighlightWordsGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }


  /**
   * @description
   *    The content of the fill in the game component
   */
  const { sentence, highlight } = gameMechanic.content as unknown as THighlightGame;

  const descriptionArr = useMemo(() => sentence.split(' '), [sentence]);
  const [currentSelection, setCurrentSelection] = useState<number[]>([]);

  const calculateCorrectAnswers = (index:number) => {
      if(highlight.includes(index)){
        changeCorrectAnswersPoints(gameMechanic.points)
      }else{
        changeCorrectAnswersPoints(0)
      }
  }

  const toggleSelection = (index: number) => {
    if (currentSelection.includes(index)) {
      setCurrentSelection(prev=>prev.filter(n => n !== index))
    } else {
      setCurrentSelection( prev=>[...prev, index])
      calculateCorrectAnswers(index) // TODO update this functionality later
    }
  };

  return (
    <div className='highlight-word-editor game'>
      <div className='sentence-to-scramble text-area-style'><p className="d-flex flex-wrap">{
        descriptionArr.map((word, i) => (
          <div onClick={() => toggleSelection(i)}>
            <span
              key={`word-${i}`}
              className={classnames({highlighted: currentSelection.includes(i)})}
            >
              {word}
            </span>
          </div>
        ))}</p></div>
    </div>
  );

}