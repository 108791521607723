import { ButtonMotion } from '../index';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import { item } from '../avatar';
import { selectAvatarItem } from '../../redux/slices';
import Coin from '../../assets/svg/coin';
import { AvatarType } from '../../redux/slices/user';
import {SetStateAction, useEffect, useState} from 'react';
import {
  getExpenses,
  getProfile,
  updateProfile,
  createExpense,
  getScores
} from "../../api/v0";
import AzureAuth from "../../azure-component/AzureAuth";

export default function ShopItem({ items, listKey }: { items: item[], listKey: string, isShop?: boolean }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [profile, setProfile] = useState<any | null>(null);
  const [expenses, setExpenses] = useState(0);
  const [coins, setCoins] = useState(0);
  const parseCurrentPoints = (finalData: Record<string, any>[]) => {
    const finalPoints = finalData.reduce((acc, curr) => +curr.finalPoints + acc, 0);
    setCoins(finalPoints);
  };
  const account = AzureAuth.getAccount();
  const parseExpenses = (finalData: Record<string, any>[]) => {
    let totalExpenses = 0;
    finalData.map((data) => {
      totalExpenses += data.cost;
    })

    setExpenses(totalExpenses);
  }

  useEffect(() => {
    getProfile(account.localAccountId, (data) => setProfile(data?.[0] ?? null), console.log);
    getExpenses(account.localAccountId, parseExpenses);
    getScores(account.localAccountId, parseCurrentPoints);
  }, []);

  const handleBuyStaff = (item: item) => {
    if (profile) {
      createExpense(
        account.localAccountId,
        {expense_type: 'shop', game_id: account.localAccountId, hints: null, items: item, cost: 50, active: true},
        () => {
          updateProfile(profile.uid, {
            ...profile,
            shop: profile?.shop ? {...profile.shop, [listKey]: profile.shop[listKey] ? [...profile.shop[listKey], item.url] : [item.url]} : { [listKey]: [item.url] } }
          )
        }
      );
    }
  }

  const changeAvatar = (itemData: Record<string, string>) => {
    selectAvatarItem(itemData);
    updateProfile(profile.uid, { avatar: { ...profile.avatar, ...itemData }});
  }

  return (
    <div className='shop-item'>
      <div className='img-wrapper'>
        <Swiper navigation={true} modules={[Navigation]} className='mySwiper'
                onSlideChange={(e: { realIndex: SetStateAction<number>; }) => setActiveIndex(e.realIndex)}>
          {items?.map(item=> {
            return (
              <SwiperSlide key={item.url}>
                <img src={`/assets/images/shop-items/${item.url}.svg `} className='img-fluid' alt='item' />
                {item.isShop &&  <Coin />}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      {items?.[activeIndex]?.isShop && listKey !== 'shoes' && listKey !== 'accessories' ? (
        <ButtonMotion variant='success' onClick={() => handleBuyStaff(items[activeIndex])} className='buy-btn'>
          Buy
        </ButtonMotion>) : (
          <ButtonMotion variant='success' onClick={() => changeAvatar({[listKey as keyof AvatarType]:items[activeIndex].url})} className='buy-btn'>Select</ButtonMotion>
        )}
    </div>
  );
}