import { memo } from 'react';

function Coin(){
  return (
    <svg  viewBox="0 0 43 46" fill="none" xmlns="http://www.w3.org/2000/svg" className="coin-svg">
      <path d="M18.0296 0.97403L4.36894 9.03673L0.600006 15.4836V30.514L4.36894 36.9609L18.0296 45.026H25.5714L39.2311 37.3023L43 30.8544V15.3284L39.2311 8.88058L25.5714 0.97403H18.0296Z" fill="#E5A535"/>
      <path d="M35.2909 31.1228L21.7995 38.9688L8.30911 31.1228V15.4274L21.7995 7.58182L35.2909 15.4274V31.1228Z" fill="#FFDE52"/>
      <path d="M22.0733 7.58182L18.2208 0.97403H25.9299L22.0733 7.58182Z" fill="#FFDE52"/>
      <path d="M8.3091 15.2909H0.600006L4.45254 9.23376L8.3091 15.2909Z" fill="#FFDE52"/>
      <path d="M8.3091 30.7091L4.45455 37.3169L0.600006 30.7091H8.3091Z" fill="#FFDE52"/>
      <path d="M22.0773 38.9688L25.9299 45.026H18.2208L22.0773 38.9688Z" fill="#FFBC45"/>
      <path d="M35.2909 31.2597H43L39.1475 37.3169L35.2909 31.2597Z" fill="#FFBC45"/>
      <path opacity="0.5" d="M35.2909 31.2597H43L39.1475 37.3169L35.2909 31.2597Z" fill="#C97231"/>
      <path d="M35.2909 15.2909L39.1456 8.68314L43 15.2909H35.2909Z" fill="#FFBC45"/>
      <path d="M4.45456 9.23376L8.1959 15.2909L8.85975 14.6758L4.45456 9.23376Z" fill="white"/>
      <path d="M0.600006 30.5481L8.3091 30.7091V30.1584L0.600006 30.5481Z" fill="white"/>
      <path opacity="0.5" d="M8.30911 31.0092L22.626 23.5507L22.0289 38.9688L8.30911 31.0092Z" fill="#C97231"/>
      <path d="M9.51933 31.129V15.4314L22.626 7.92769L22.0205 7.58182L8.30911 15.4284V31.1222L22.0205 38.9688L22.626 38.623L9.51933 31.129Z" fill="white"/>
      <path opacity="0.5" d="M29.6972 34.013L22.626 23.5507L35.2909 30.7892L29.6972 34.013Z" fill="#C97231"/>
      <path opacity="0.5" d="M35.2909 15.344L24.3154 17.4935L22.626 7.58182L35.2909 15.344Z" fill="#C97231"/>
      <path opacity="0.4" d="M8.30911 23.0764L29.4802 11.987L35.2909 15.4459L11.7324 32.9117L8.30911 30.8223V23.0764Z" fill="white"/>
      <path opacity="0.4" d="M17.6701 36.4692L35.2909 18.0442V31.04L21.9081 38.9688L17.6701 36.4692Z" fill="white"/>
      <path d="M0.600006 25.7532V15.2909H8.3091C8.3091 15.2909 6.51146 15.3116 3.88214 18.8721C2.33594 20.9176 1.21913 23.2586 0.600006 25.7532Z" fill="#FFDE52"/>
      <path opacity="0.5" d="M40.7974 31.2597C40.7974 31.2597 38.8859 30.2386 38.2204 26.1996C37.5548 22.1606 40.132 15.2909 40.132 15.2909H35.2909V31.2597H40.7974Z" fill="#C97231"/>
      <path opacity="0.5" d="M25.8347 45.026L22.0753 38.764L35.7012 31.2597L38.0442 35.1635C38.0442 35.1635 36.5207 33.2339 32.628 35.0872C26.5006 38.0056 25.8347 45.026 25.8347 45.026Z" fill="#C97231"/>
      <path opacity="0.5" d="M24.9596 2.62598C24.9596 2.62598 24.6246 3.61868 26.2085 6.52691C27.7925 9.43514 33.0883 14.1896 33.0883 14.1896L22.0753 7.68381L24.9596 2.62598Z" fill="#C97231"/>
      <path d="M18.2208 0.97403L21.4912 8.68312L22.626 8.01773L18.2208 0.97403Z" fill="white"/>
      <path opacity="0.5" d="M35.4767 31.2597H36.9429L35.4767 15.2909C35.4767 15.2909 35.0588 31.2597 35.4767 31.2597Z" fill="#C97231"/>
      <path opacity="0.5" d="M22.0753 38.9688L36.3922 32.3498L35.7529 31.2597L22.0753 38.9688Z" fill="#C97231"/>
      <path opacity="0.1" d="M38.5948 13.0883C41.0277 13.0883 43 11.116 43 8.68312C43 6.2502 41.0277 4.27792 38.5948 4.27792C36.1619 4.27792 34.1896 6.2502 34.1896 8.68312C34.1896 11.116 36.1619 13.0883 38.5948 13.0883Z" fill="white"/>
      <path opacity="0.2" d="M42.4241 9.0904C42.3435 9.73886 42.0725 10.3489 41.6452 10.8433C41.2179 11.3377 40.6535 11.6945 40.0235 11.8682C39.3936 12.0418 38.7262 12.0249 38.1059 11.8193C37.4857 11.6137 36.9404 11.2286 36.5389 10.713C36.1374 10.1975 35.8977 9.57447 35.8503 8.92274C35.8029 8.27102 35.95 7.61982 36.2727 7.05161C36.5954 6.4834 37.0793 6.0235 37.6633 5.73034C38.2473 5.43718 38.905 5.3238 39.5535 5.40451C40.4229 5.51272 41.2139 5.9617 41.7523 6.65293C42.2906 7.34417 42.5322 8.22097 42.4241 9.0904Z" fill="white"/>
      <path opacity="0.7" d="M39.3653 6.60136C39.1275 7.52739 39.1137 8.72775 37.4935 8.66299C37.9826 8.90827 38.4052 9.27795 38.7229 9.73864C38.9789 10.2643 39.1131 10.8461 39.1147 11.4364C39.1655 10.8784 39.3309 10.3384 39.5995 9.85381C39.7414 9.66194 39.9228 9.50632 40.1303 9.39881C40.3379 9.29131 40.566 9.23465 40.7974 9.23315C40.321 8.86873 39.8685 8.85704 39.6344 8.16976C39.5411 7.91148 39.4702 7.64516 39.4225 7.37353C39.3773 7.09997 39.453 6.69705 39.3111 6.48053L39.3653 6.60136Z" fill="white"/>
      <path d="M39.6392 8.92761C39.4683 8.6106 39.2835 8.64763 39.1292 8.78261C38.327 8.55121 38.4429 10.1145 39.3764 9.72039C39.522 9.61061 39.6222 9.48138 39.6679 9.34339C39.7136 9.20541 39.7036 9.06284 39.6392 8.92761Z" fill="white"/>
      <path d="M4.45456 8.98418L8.7589 14.7403L20.974 7.72127L18.0276 0.97403L4.45456 8.98418Z" fill="#FFDE52"/>
      <path opacity="0.4" d="M8.23055 14.1896C8.45769 12.6226 9.18232 11.1687 10.2988 10.0399C12.2664 7.86338 18.7714 2.42754 18.7714 2.42754L18.1408 0.97403L4.45456 9.10527L8.23055 14.1896Z" fill="white"/>
      <path opacity="0.5" d="M8.30911 24.394L32.2203 13.6389L34.1896 14.8134L9.03585 29.6078L8.30911 24.394Z" fill="white"/>
      <path opacity="0.1" d="M13.2205 30.9055C13.0794 32.0944 12.6051 33.213 11.8573 34.1194C11.1095 35.0257 10.1218 35.6796 9.01941 35.9979C7.91697 36.3163 6.74911 36.2848 5.66364 35.9078C4.57818 35.5308 3.62384 34.8251 2.92123 33.8799C2.21861 32.9346 1.79945 31.7925 1.71652 30.5977C1.6336 29.4028 1.89058 28.2089 2.4553 27.1671C3.02002 26.1254 3.8671 25.2822 4.88911 24.7447C5.91111 24.2072 7.06222 23.9998 8.19702 24.1477C8.95053 24.2457 9.67834 24.4979 10.3387 24.8906C10.999 25.2833 11.5788 25.8089 12.0453 26.4365C12.5118 27.0641 12.8557 27.7816 13.0574 28.5485C13.259 29.3154 13.3145 30.1162 13.2205 30.9055Z" fill="white"/>
      <path opacity="0.2" d="M12.1321 30.7021C12.0313 31.5667 11.6923 32.38 11.1582 33.0392C10.6241 33.6984 9.91884 34.1735 9.13141 34.405C8.34399 34.6366 7.50981 34.6143 6.73448 34.3402C5.95915 34.0661 5.27763 33.5525 4.77573 32.8651C4.27384 32.1777 3.97408 31.3471 3.91479 30.4781C3.8555 29.6091 4.03924 28.7411 4.44253 27.9834C4.84583 27.2257 5.4506 26.6124 6.18055 26.2215C6.9105 25.8305 7.73279 25.6795 8.54335 25.787C9.63025 25.9311 10.6189 26.5299 11.292 27.4516C11.965 28.3734 12.2672 29.5427 12.1321 30.7021Z" fill="white"/>
      <path opacity="0.7" d="M8.36606 27.0254C8.00726 28.3632 7.9876 30.1004 5.55585 30.0051C6.29027 30.3595 6.92496 30.8943 7.40173 31.5607C7.78594 32.32 7.98697 33.1602 7.98858 34.013C8.06408 33.207 8.31169 32.4274 8.71442 31.7274C8.927 31.45 9.19931 31.2246 9.51065 31.0691C9.82199 30.9136 10.1644 30.8314 10.5117 30.8292C9.79617 30.3054 9.11786 30.2855 8.76632 29.2987C8.62702 28.9247 8.52061 28.539 8.44809 28.1462C8.38068 27.7502 8.49358 27.1636 8.28099 26.8546L8.36606 27.0254Z" fill="white"/>
      <path d="M8.805 30.0975C8.63441 29.4632 8.44949 29.5361 8.29034 29.807C7.49171 29.344 7.60703 32.4689 8.53813 31.6829C8.68373 31.4637 8.78393 31.2054 8.83036 30.9295C8.87679 30.6536 8.86812 30.3685 8.805 30.0975Z" fill="white"/>
    </svg>

  )
}

export default memo(Coin)