/**
 * @description - Avatar items types that are existing in the GLMS
 *  platform.
 */
export enum EnumAvatarItemsCategories {
  tops = 'tops',
  bottoms = 'bottoms',
  accessories = 'accessories',
  shoes = 'shoes',
}

export enum EnumAvatarGenders {
  '1D1A55' = 'man',
  'F0BFDA' = 'woman',
  'F9AE00' = 'other'
}