import { DndProvider } from 'react-dnd';
import { Uploader } from 'src/components';
import { useGameEditMechanic } from '../../../../hooks';
import { checkIsMobile } from '../../../../helpers';
import { Col, Row } from 'react-bootstrap';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GameNotFound from '../../../not-found';
import { TJumbleWordsGame, Letter, DraggedItem } from './types';
import { TGameMechanicContent } from '../../../../@types';
import { useEffect, useState } from 'react';

export default function JambleWordsEditor() {
  const isMobile = checkIsMobile();
  const [mechanics, setMechanics] = useState<string>('');

  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  const parseAnswer = (answer: string): Partial<TJumbleWordsGame> => {
    // Generate the LatterObject[] array from the answer string
    const letters: Letter[] = answer?.split('').map((char, index) => ({
      letter: char,
      id: index.toString()
    }));

    // Create the DraggedItemObject from the letters
    const draggedItems: DraggedItem = {};
    letters.map(letterObj => {
      draggedItems[letterObj.id] = letterObj;
    });

    return {
      answer,
      letters,
      draggedItems,
    };
  }

  if (gameMechanic) {

    const {filePath, text, answer} = gameMechanic.content as TJumbleWordsGame
    const updateAnswers = (answerText: string) => {
      updateGameMechanic({
        ...gameMechanic,
        content: {
          ...gameMechanic.content,
          ...parseAnswer(answerText),
        } as TGameMechanicContent
      });
    }

    const updateFilePath = (filePath: string) => {
      updateGameMechanic({
        ...gameMechanic,
        content: {
          ...gameMechanic.content,
          filePath: filePath ?? null,
        } as TGameMechanicContent
      });
      setMechanics('')
    }

    useEffect(()=>{
      if(filePath){
        setMechanics('img')
      }else if(text){
        setMechanics('text')
      }
    },[])

    return (
      <div className='jumble-word-editor'>
        <Row>
          <Col md={6}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <div className='position-relative'>
                {!mechanics ? <>
                  <span title="click to add text" className='select-text' onClick={() => setMechanics('text')}>Aa𝙸</span>
                  <label onClick={() => setMechanics('')} className='col-12 mb-3 mb-md-0'>
                    <Uploader currentUrl={filePath} position='landscape'
                              onGameMechanicContentChange={(filePath)=> {
                                updateFilePath(filePath)
                                setMechanics('img')
                              }}  gameId={gameId} />
                  </label>
                </> : <>
                  {mechanics === 'text' ? <textarea value={text}
                                                    placeholder='type your text here...'
                                                    onChange={(e) =>  updateGameMechanic({
                                                      ...gameMechanic,
                                                      content: {
                                                        ...gameMechanic.content,
                                                        text: e.target.value,
                                                      } as TGameMechanicContent
                                                    })} /> :
                    <div className={'uploader uploaded position-relative'}><div
                      className='uploaded-img d-flex justify-content-center'
                    ><img className='img-fluid' src={filePath} /></div></div>}</>}
              </div>
            </DndProvider>
          </Col>
          <Col md={6}><textarea value={answer}
                                onChange={(e) => updateAnswers(e.target.value)} />
          </Col>
        </Row>
      </div>
    );
  }
  return <GameNotFound />;
}