import { AvatarType, PersonStylesType, selectAvatarItem } from 'src/redux/slices/user';
import {CSSProperties, useEffect, useState} from 'react';
import { getCssProps } from 'src/helpers';
import AzureAuth from "../../azure-component/AzureAuth";
import {getProfile, updateProfile} from "../../api/v0";

export interface MyCustomCSS extends CSSProperties {
  '--color': string;
  '--rotate': string;
  '--clip-path-degree': string;
}

export default function PersonStyles({ personStyles, avatar }: { personStyles: PersonStylesType, avatar: AvatarType }) {
  const [profile, setProfile] = useState<any | null>(null);
  const account = AzureAuth.getAccount();
  useEffect(() => {
    getProfile(account.localAccountId, (data) => setProfile(data?.[0] ?? null), console.log);
  }, []);

  const [currentStyles, setCurrentStyles ] = useState<AvatarType>(profile?.avatar ?? avatar);

  useEffect(() => {
    if (profile?.avatar && profile?.uid) {
      setCurrentStyles(profile.avatar);
    }
  }, [profile]);

  return (
    <>
      {Object.keys(personStyles).map(key => {
        const colorCount = personStyles[key as keyof PersonStylesType].length;
        const degree = 360 / colorCount;

        return (
          <div className='list-item ps-4 position-relative my-2' key={key}>
            <h4>{key}</h4>

            <div className='palette rounded-circle overflow-hidden position-relative'>
              {personStyles[key as keyof PersonStylesType].map((style, index) => {
                const cssProps = getCssProps(degree, colorCount > 8, index);
                return <div key={`${key}-${style}`}
                            onClick={() => {
                              setCurrentStyles({
                                ...currentStyles,
                                [key]: style
                              })
                              selectAvatarItem({[key]:style})
                            }}
                            className={`position-absolute item
                 ${avatar[key as keyof PersonStylesType] === style ? "active" : "disabled" }`} style={
                  {
                    '--color': `#${style}`,
                    '--rotate': `${cssProps.degree}deg`,
                    '--clip-path-degree': `${cssProps.clipPathDeg}%`
                  } as MyCustomCSS
                } />;
              })}
              <button type='button'
                      onClick={()=> {
                        updateProfile(profile.uid, { avatar: currentStyles });
                        selectAvatarItem(currentStyles);
                      }}
                      className='palette-btn rounded-circle position-absolute d-flex align-items-center justify-content-center border-0'>
                <strong>ok</strong></button>
            </div>
          </div>
        );
      })}
    </>
  );

}
