import ShopItem from '../shop/shop-item';
import { EnumAvatarItemsCategories } from 'src/@constants/avatar-constants';
import { WardrobeType } from 'src/redux/slices/user';
import {useEffect, useState} from "react";
import AzureAuth from "../../azure-component/AzureAuth";
import {getProfile} from "../../api/v0";

export default function Wardrobe({ avatarItems }: { avatarItems: WardrobeType }) {
  const [profile, setProfile] = useState<any | null>(null);
  const account = AzureAuth.getAccount();
  useEffect(() => {
    getProfile(account.localAccountId, (data) => setProfile(data?.[0] ?? null), console.log);
  }, []);

  const parseAvatarItems = () => {
    if (profile?.shop) {
      let shops: Record<string, any> = {};
      Object.keys(profile.shop).map((key: string) => {
        shops[key] = profile.shop[key].map((item: string) => ({
          url: item,
          price: 50,
        }))
      });
      return shops;
    }

    return avatarItems;
  }

  const finalItems = parseAvatarItems();

  return (
    <>
      {Object.keys(finalItems).map(key => {
        return (
          <div className='list-item ps-4  position-relative my-2' key={key}>
            <h4>{key}</h4>
            <ShopItem items={finalItems[key as keyof typeof EnumAvatarItemsCategories]}
                      listKey={key} key={Math.random()} />
          </div>
        );
      })}
    </>
  );
}