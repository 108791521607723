import React, { memo, useState } from 'react';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { ButtonMotion, Uploader } from 'src/components';
import { checkIsMobile } from 'src/helpers';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import GameNotFound from 'src/games/not-found';
import { TGameMechanicContent } from 'src/@types';
import { useGameEditMechanic } from 'src/hooks';
import { TGameFillInGame } from './types';
import TinyMceEditor from '../../../../components/tiny-mce-editor';
import parse, { Element } from 'html-react-parser';
import {uploadFile} from "../../../../api/v0";
import {TInformativeGame} from "../informative-game/types";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //
interface ITextSelectEvent extends React.MouseEvent<HTMLTextAreaElement> {
  target: HTMLTextAreaElement & EventTarget;
}

function FillInBlanksEditor() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();


  if (gameMechanic) {
    const isMobile = checkIsMobile();
    const { text, answers, filePath, audio } = gameMechanic.content as TGameFillInGame;
    const [isTextSaved, setIsTextSaved] = useState(false);

    const validateCurrentContent = () => {
      return true;
    };

    const onGameMechanicContentChange = (finalContent: TGameMechanicContent) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent as unknown as TGameMechanicContent
        });
      }
    };

    const updateImage = (filePath: string) => {
      onGameMechanicContentChange({
        text: text ?? null,
        answers: answers ?? null,
        filePath: filePath ?? null
      } as TGameMechanicContent);
    };

    const [selectedText, setSelectedText] = useState<{ start: number, end: number } | null>(null);

    const onTextWordsSelectHandler = (event: ITextSelectEvent) => {
      setSelectedText({ start: event.target.selectionStart, end: event.target.selectionEnd });
    };

    const handleOnSelectWordButtonClick = () => {
      if (selectedText) {
        let convertedText = text ?? '';
        const selection = convertedText.substring(selectedText.start, selectedText.end);
        const convertedAnswers = [...(answers ?? [])];
        convertedAnswers.push(selection);

        convertedText = convertedText.slice(0, selectedText.start) + '{{_}}' + convertedText.slice(selectedText.end);
        onGameMechanicContentChange({
          ...gameMechanic?.content as TGameFillInGame,
          text: convertedText,
          answers: convertedAnswers
        } as TGameFillInGame);

        setSelectedText(null);
      }
    };

    const handleTextChange = (newText: string) => {
      if (gameId && gameMechanic?.content) {
        onGameMechanicContentChange({
          ...gameMechanic.content,
          text: newText
        } as TGameFillInGame);
      }
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
      const { files } = e.target;
      if (files?.length) {
        let file = files[0];
        if (gameId) {
          uploadFile(
            `${gameId}/${type}/${file.name}`,
            file,
            (url: string) => {
              const finalContent = {
                ...gameMechanic?.content,
                [type]: url
              };
              onGameMechanicContentChange(finalContent as unknown as TInformativeGame);
            },
            (error: string) => console.log(error)
          );
        }
      }
    };

    const finalAnswers = [...(answers ?? [])];
    // @ts-ignore
    let populatedText = text.replace(/\{\{_\}\}/g, function() {
      // Shift removes the first element from the array and returns it,
      // effectively moving through the array as replacements are made
      return `<span style="font-weight:bold"> ${finalAnswers.shift()} </span>`;
    });

    return (
      <div className='fill-in-blanks-editor'>
        <Row>
          <Col md={4} className='mb-3 mb-md-0'>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Uploader gameId={gameId} currentUrl={filePath}
                        onGameMechanicContentChange={updateImage}
                        key={Math.random()}/>
            </DndProvider>
          </Col>
          <Col md={8}>
            {!isTextSaved ?
              <TinyMceEditor handleChange={handleTextChange}
                             value={answers?.length > 0 ? populatedText : text}/>
              :
              <textarea
                value={(answers?.length > 0 ? populatedText : text)?.replace('<p', '')?.replace('>', '').replace('</p>', '')}
                onSelect={onTextWordsSelectHandler} readOnly={true} />
            }
          </Col>
        </Row>
        <Row>
          <div className='inputs'>
            {audio && <audio controls>
                <source src={audio} type='audio/ogg'/>
                <source src={audio} type='audio/mpeg'/>
            </audio>}
            <label> add audio block <img src='/assets/images/select-icon.svg'
                                         alt='select-icon'
                                         style={{ width: '10px', height: '10px' }}/>
              <input type='file' placeholder='add text block'
                     accept='audio/*'
                     onChange={(e) => handleFileUpload(e, 'audio')}/> </label>
          </div>
        </Row>
        <Row>
          <Col>
            <div className='btn-wrapper'>
              {!isTextSaved ?
                <ButtonMotion
                  variant='success'
                  className={classnames({disabled: false})}
                  onClick={() => setIsTextSaved(true)}
                >
                  Save
                </ButtonMotion> :
                <>
                  <ButtonMotion
                    variant='success'
                    className={classnames({ disabled: false })}
                    onClick={() => setIsTextSaved(false)}
                  >
                    Edit
                  </ButtonMotion>
                  <ButtonMotion
                    variant='success'
                    className={classnames({ disabled: false })}
                    onClick={handleOnSelectWordButtonClick}
                  >
                    Hide selected
                  </ButtonMotion>
                </>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return <GameNotFound />;
}

export default memo(FillInBlanksEditor);