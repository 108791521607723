import ManAvatar from '../../assets/svg/avatars/man-avatar';
import { useSelector } from 'src/redux/store';
import { EnumAvatarGenders } from '../../@constants/avatar-constants';
import {useEffect, useMemo, useState} from 'react';
import Wardrobe from './wardrobe';
import PersonStyles from './person-styles';
import WomanAvatar from '../../assets/svg/avatars/woman-avatar';
import { getProfile } from "../../api/v0";
import { AvatarType, PersonStylesType, selectAvatarItem } from 'src/redux/slices/user';
import AzureAuth from "../../azure-component/AzureAuth";

export interface item {
  url: string,
  price: number,
  isShop?: boolean,
}

export const avatarItemss: Record<string, item[]> = {
  tops: [{ url: 'shirt', price: 50 }, { url: 'shirt-dark', price: 50 }],
  accessories: [{ url: 'glasses', price: 50 }],
  bottoms: [{ url: 'trousers', price: 50 }],
  shoes: [{ url: 'shoes-black', price: 50 }]
};

export default function Avatar() {
  const [profile, setProfile] = useState<any | null>(null);
  const account = AzureAuth.getAccount();

  useEffect(() => {
    getProfile(account.localAccountId, (data) => setProfile(data?.[0] ?? null), console.log);
  }, []);
  const [currentList, setCurrentList] = useState('style');
  const { avatar, avatarItems } = useSelector(state => state.user);
  const { gender } = avatar;
  useEffect(() => {
    if (profile?.avatar) {
      selectAvatarItem(profile.avatar);
    }
  }, [profile]);
  const genderAvatar = useMemo(() => {
    switch (true) {
      case EnumAvatarGenders[gender as keyof typeof EnumAvatarGenders] === 'man' :
        return <ManAvatar {...avatar} />;
      case EnumAvatarGenders[gender as keyof typeof EnumAvatarGenders] === 'woman':
        return <WomanAvatar {...avatar} />;
    }
  }, [gender, avatar]);

  return (
    <div className='avatar-container d-flex position-relative flex-shrink-0 flex-grow-1'>
      <div className='avatar-wrapper position-relative'>
        <div className='avatar-img-wrapper bg-white d-flex align-items-center justify-content-center'>{genderAvatar}</div>
      </div>

      <div className='avatar-items'>
        {currentList === "wardrobe"  ?
          <Wardrobe avatarItems={avatarItems.wardrobe} /> : <PersonStyles personStyles={avatarItems.personStyles} avatar={avatar}/> }
        <div className='item-tabs'>
          <div className={`item-tab ${currentList === "style" ? "active" : ""}`} onClick={()=> setCurrentList('style')}>Style</div>
          <div className={`item-tab ${currentList === "wardrobe" ? "active" : ""}`} onClick={()=> setCurrentList('wardrobe')}>Wardrobe</div>
        </div>
      </div>
    </div>
  );
}