import ShopItem from './shop-item';
import 'src/assets/styles/mixins.scss';
import 'src/assets/styles/shop.scss';
import Avatar, { avatarItemss } from '../avatar';
import {useEffect, useState} from "react";
import AzureAuth from "../../azure-component/AzureAuth";
import {getProfile} from "../../api/v0";

const Shop = () => {
  const [profile, setProfile] = useState<any | null>(null);
  const account = AzureAuth.getAccount();
  useEffect(() => {
    getProfile(account.localAccountId, (data) => setProfile(data?.[0] ?? null), console.log);
  }, []);

  const parseAvatarItems = (avatarItem: any, key: string) => {
    return avatarItem.map((itemData: any) => ({
      ...itemData,
      isShop: !profile?.shop?.[key]?.includes(itemData.url),
    }));
  }

  return (
    <div className='shop d-flex w-100 flex-column flex-md-row overflow-hidden'>
      <Avatar />
      <div className="shop-block flex-grow-1 overflow-hidden">
        <h3>Shop</h3>
        <div className='shop-items d-grid w-100'>
          {Object.keys(avatarItemss).map(key => {
            const item = parseAvatarItems(avatarItemss[key], key);
            return (
              <div className='shop-item-container' key={key}>
                <h4 className="text-center">{key}</h4>
                <ShopItem items={item} listKey={key}/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Shop;