import {
  getFirestore,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  addDoc,
  query,
  where,
  orderBy,
  startAt,
  limit,
  collection,
  Timestamp,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { EnumGamePublicationStatus } from "../@constants";

export const gamesFetcher = (
  miniWorldId: string,
  tenant_id: string,
  onSuccess: any,
  onFail: any
) => {
  try {
    const dbReference = collection(getFirestore(), 'games');
    let apiQuery = query(
      dbReference,
      where('mini_world_id', '==', miniWorldId),
      where('active', '==', true),
      where('status', '==', EnumGamePublicationStatus.published),
    );
    Promise.all([
      getDocs(
        query(
          apiQuery,
          where('type', '==', 'core')
        )
      ),
      getDocs(
        query(
          apiQuery,
          where('type', '==', 'mini'),
          // where('tenant_id', '==', tenant_id !== '255bebb6-afe8-4d45-acc9-dc7ddc9479fe' ? tenant_id?.toUpperCase() : '9E2EF51A-9BFD-4E7C-BD39-55E07D4716F5'),
        )
      ),
    ])
      .then(([coreGamesSnapshots, miniGamesSnapshots]) => {
        const gamesList: any[] = [];
        coreGamesSnapshots.forEach((gameSnapshot) => {
          const gameData = gameSnapshot.data();
          if (gameData) {
            gamesList.push(gameData);
          }
        });
        miniGamesSnapshots.forEach((gameSnapshot) => {
          const gameData = gameSnapshot.data();
          if (gameData) {
            gamesList.push(gameData);
          }
        });
        onSuccess(gamesList);
      })
      .catch((e) => console.error(e));
  } catch (e) {
    console.log(e);
  }
}

export const fetchGameDataById = (
  id: string,
  onSuccess: (data: Record<string, any>) => void,
) => {
  const dbReference = doc(getFirestore(), 'games', id);
  getDoc(dbReference)
    .then((gameSnapshot) => {
      if (gameSnapshot.exists()) {
        onSuccess(gameSnapshot.data());
      }
    })
    .catch((error) => console.log(error))
}

export const createGame = (
  gameData: Record<string, any>,
  onSuccess: (id: string) => void,
) => {
  const dbReference = doc(collection(getFirestore(), "games"));
  setDoc(dbReference, { ...gameData, id: dbReference.id, active: true, status: EnumGamePublicationStatus.created })
    .then(() => onSuccess(dbReference.id))
    .catch((error) => console.error(error));
}

export const saveGame = (
  id: string,
  gameData: Record<string, any>,
  onSuccess: (id: string) => void,
) => {
  const dbReference = doc(collection(getFirestore(), "games"), id);
  updateDoc(dbReference, {
    ...gameData,
    content: [...gameData.content],
    pages: gameData.content?.length ?? 0,
    updated: Timestamp.now(),
    id: dbReference.id,
  })
    .then(() => onSuccess(id))
    .catch((error) => console.error(error));
}

export const updateGameService = (
  id: string,
  gameData: Record<string, any>,
  onSuccess: (id: string) => void,
) => {
  const dbReference = doc(collection(getFirestore(), "games"), id);
  updateDoc(dbReference, {
    ...gameData,
    updated: Timestamp.now(),
    id: dbReference.id,
  })
    .then(() => onSuccess(id))
    .catch((error) => console.error(error));
}

export const updateGameStatus = (
  id: string,
  gameStatus: EnumGamePublicationStatus,
  onSuccess: (id: string) => void,
  onFail: (error: Error | string) => void,
) => {
  const dbReference = doc(collection(getFirestore(), "games"), id);
  updateDoc(dbReference, {
    status: gameStatus,
    updated: Timestamp.now(),
  })
    .then(() => onSuccess(id))
    .catch((error) => onFail(error.message ?? error));
}

export const storePlayedGamePointsV0 = (
  data: {
    gameId: string,
    gameName: string,
    userId: string,
    userEmail: string,
    finalPoints: number,
  },
  onSuccess: () => void,
) => {
  const dbReference = doc(collection(getFirestore(), "played"));
  setDoc(dbReference, { ...data, id: dbReference.id, active: true, created: Timestamp.now(), updated: Timestamp.now() })
    .then(() => onSuccess())
    .catch((error) => console.error(error));
  const notificationReference = doc(collection(getFirestore(), "notifications"));
  setDoc(notificationReference, {
    message: `You completed game ${data.gameName}, you’ve received ${data.finalPoints} coins in your wallet`,
    seen: false,
    id: notificationReference.id,
    playedId: dbReference.id,
    active: true,
    created: Timestamp.now(),
    updated: Timestamp.now(),
    userId: data.userId,
    userEmail: data.userEmail,
  })
    .then(() => console.log('updated'))
    .catch((error) => console.error(error));
}

export const uploadFile = (
  path: string,
  file: File,
  onSuccess: (url: string) => void,
  onFail: (error: string) => void
) => {
  const storage = getStorage();
  const storageRef = ref(storage, path);

  uploadBytes(storageRef, file).then((snapshot) => {
    getDownloadURL(snapshot.ref).then(onSuccess).catch((error) => onFail(error.message));
  }).catch((error) => onFail(error.message));
}

export const createExpense = (
  userId: string,
  expenseData: Record<string, any>,
  onSuccess: VoidFunction,
) => {
  const dbReference = doc(collection(getFirestore(), "expenses"));

  setDoc(dbReference, { ...expenseData, userId, id: dbReference.id, active: true, created: Timestamp.now(), updated: Timestamp.now() })
    .then(() => onSuccess())
    .catch((error) => console.error(error));
}

export const getExpenses = (
  userId: string,
  onSuccess: (data: Record<string, any>[]) => void
) => {
  const dbReference = collection(getFirestore(), "expenses");
  const apiQuery = query(
    dbReference,
    where('userId', '==', userId),
    where('active', '==', true),
  );

  getDocs(apiQuery)
    .then((gamesSnapshots) => {
      const gamesList: any[] = [];
      gamesSnapshots.forEach((gameSnapshot) => {
        const gameData = gameSnapshot.data();
        if (gameData) {
          gamesList.push(gameData);
        }
      });
      onSuccess(gamesList);
    })
    .catch((e) => console.log(e));
}

export const getScores = (
  userId: string,
  onSuccess: (data: Record<string, any>[]) => void
) => {
  const dbReference = collection(getFirestore(), "played");
  const apiQuery = query(
    dbReference,
    where('userId', '==', userId),
    where('active', '==', true),
  );
  getDocs(apiQuery)
    .then((gamesSnapshots) => {
      const gamesList: any[] = [];
      gamesSnapshots.forEach((gameSnapshot) => {
        const gameData = gameSnapshot.data();
        if (gameData) {
          gamesList.push(gameData);
        }
      });
      onSuccess(gamesList);
    })
    .catch((e) => console.log(e));
}

export const getNotifications = (userId: string, onSuccess: (messages: Record<string, any>[]) => void, onFail: (error: string) => void) => {
  const dbReference = collection(getFirestore(), "notifications");
  try {
    const apiQuery = query(
      dbReference,
      where('userId', '==', userId),
      where('active', '==', true),
      where('seen', '==', false),
    );
    getDocs(apiQuery)
      .then((gamesSnapshots) => {
        const gamesList: any[] = [];
        gamesSnapshots.forEach((gameSnapshot) => {
          const gameData = gameSnapshot.data();
          if (gameData) {
            gamesList.push({
              message: gameData.message,
              id: gameData.id,
              type: 'info',
              headline: `Completed Game`,
            });
          }
        });
        onSuccess(gamesList);
      })
      .catch((e) => console.log(e));
  } catch (error) {
    onFail(error.message);
  }
}

export const markAsDoneNotification = (notificationId: string, onSuccess: () => void) => {
  const dbReference = doc(collection(getFirestore(), "notifications"), notificationId);
  updateDoc(dbReference, {
    seen: true,
    updated: Timestamp.now(),
  })
    .then(() => onSuccess())
    .catch((error) => console.error(error));
}

export function updateProfile (uid: string, profile: Record<string, any>) {
  const dbReference = doc(collection(getFirestore(), 'coins'), uid);
  updateDoc(dbReference, { ...profile, updated: Timestamp.now() }).catch((error) => {
    setDoc(dbReference, { ...profile, updated: Timestamp.now(), created: Timestamp.now() })
  }).then(() => window.location.reload());
}

export function getProfile (
  userId: string,
  onSuccess: (profiles: Record<string, any>[]) => void,
  onFail: (error: Error | string) => void,
) {
  try {
    const dbReference = collection(getFirestore(), 'coins');
    const apiQuery = query(
      dbReference,
      where('active', '==', true),
      where('user_uid', '==', userId),
    );
    getDocs(apiQuery)
      .then((coinsSnapshots) => {
        const coinsList: any[] = [];
        coinsSnapshots.forEach((coinSnapshot) => {
          const coinData = coinSnapshot.data();
          if (coinData) {
            coinsList.push(coinData);
          }
        });
        onSuccess(coinsList);
      })
      .catch((e) => {console.log(e.message)});
  } catch (e) {
    console.log(e);
  }
}

export const getAllGames = (onSuccess: (games: Record<string, any>[]) => void, sorting?: Record<string, string | null>) => {
  try {
    const dbReference = collection(getFirestore(), 'games');
    let apiQuery = query(
      dbReference,
      where('active', '==', true),
      where('mini_world_id', 'in', ['1', '2', '3', '4']),
    );
    if ((sorting?.value === 'asc' || sorting?.value === 'desc') && sorting.key) {
      apiQuery = query(apiQuery, orderBy(sorting.key, sorting.value));
    } else {
      apiQuery = query(apiQuery, orderBy('created_at', 'desc'));
    }
    getDocs(apiQuery)
      .then((gamesSnapshots) => {
        const gamesList: any[] = [];
        gamesSnapshots.forEach((gameSnapshot) => {
          const gameData = gameSnapshot.data();
          if (gameData) {
            gamesList.push(gameData);
          }
        });
        onSuccess(gamesList);
      })
      .catch((e) => console.log(e));
  } catch (e) {
    console.log(e);
  }
}

export const getPlayedGames = (onSuccess: (games: Record<string, any>) => void) => {
  const dbReference = collection(getFirestore(), 'played');
  const apiQuery = query(
    dbReference,
    where('active', '==', true),
    orderBy('updated', 'desc')
  );

  getDocs(apiQuery)
    .then((gamesSnapshots) => {
      const gamesList: Record<string, any> = {};
      gamesSnapshots.forEach((gameSnapshot) => {
        const gameData = gameSnapshot.data();
        if (gameData) {
          if (!gamesList[gameData.userId]) {
            gamesList[gameData.userId] = {
              points: Number(gameData.finalPoints),
              updated: gameData.updated.toDate().toDateString(),
              user: gameData?.userEmail ?? gameData.userId,
              numberGames: 1,
            }
          } else {
            gamesList[gameData.userId] = {
              ...gamesList[gameData.userId],
              points: gamesList[gameData.userId].points + Number(gameData.finalPoints),
              numberGames: gamesList[gameData.userId].numberGames + 1
            }
          }
        }
      });
      onSuccess(gamesList);
    })
    .catch((e) => console.log(e));
}
