import React, { memo, useState, useMemo, useEffect } from 'react';
import { useGameEditMechanic } from 'src/hooks';
import { TDragDropTextGame } from "./types";
import GameNotFound from '../../../not-found';
import {checkIsMobile, handleChange} from 'src/helpers';
import {ButtonMotion, Uploader} from 'src/components';
import { TGameMechanicContent } from "src/@types";
import classnames from "classnames";
import {TouchBackend} from "react-dnd-touch-backend";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {Col, Row} from "react-bootstrap";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function DragDropTextGame() {
  const isMobile = checkIsMobile();
  const {
    gameMechanic,
    gameId,
    updateGameMechanic,
  } = useGameEditMechanic();
  const [description, setDescription] = useState<string>('');
  const [descriptionSaved, setDescriptionSaved] = useState<Boolean>(false);
  const [descriptionArr, setDescriptionArr] = useState<string[]>([]);
  const [currentSelection, setCurrentSelection] = useState<number[]>([]);
  const [missingWords, setMissingWords] = useState<number[]>([]);
  const [selectedFilePath, setSelectedFilePath] = useState<string | null>(null);

  useEffect(() => {
    if (gameMechanic?.content) {
      setDescription((gameMechanic.content as TDragDropTextGame).sentence ?? '');
    }
  }, [gameMechanic]);

  if (gameMechanic) {
    const onGameMechanicContentChange = (finalContent: TDragDropTextGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };

    const selectWords = ()=> {
      const sentenceList = (gameMechanic.content as TDragDropTextGame).sentence?.split(' ') ?? [];
      onGameMechanicContentChange({
        ...gameMechanic.content as TDragDropTextGame,
        missing_words: [...missingWords, ...currentSelection].map((indexNumber) => ({
          word: sentenceList?.[indexNumber] ?? '',
          index: indexNumber
        }))
      });
      setMissingWords(prev => [...prev, ...currentSelection]);
      setCurrentSelection([]);
    }

    const toggleSelection = (index: number) => {
      setCurrentSelection(prev => {
        if (prev.includes(index)) {
          return prev.filter(n => n !== index);
        } else {
          return [...prev, index];
        }
      });
    }

    const saveDescription = () => {
      onGameMechanicContentChange({
        ...gameMechanic.content as TDragDropTextGame,
        sentence: description,
        missing_words: []
      });
      setDescriptionSaved(true);
      setDescriptionArr(description.split(' '));
    };

    const actionButtons = useMemo(() => {
      if (!descriptionSaved) return <ButtonMotion variant={'scramble'} disabled={description.length === 0}
                                                  onClick={saveDescription}>Save</ButtonMotion>;
      return (
        <ButtonMotion
          variant={'scramble'}
          onClick={selectWords}
        >
          Select Word
        </ButtonMotion>
      );
    }, [descriptionSaved, currentSelection, description]);

    return (
      <div className='highlight-word-editor'>
        <Row>
          <Col md={4} className='mb-3 mb-md-0'>
            {/*<div style={{ width: '420px', height: '420px' }}>*/}
              <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <Uploader
                  gameId={gameId}
                  currentUrl={selectedFilePath ?? (gameMechanic.content as TDragDropTextGame)?.filePath ?? null}
                  onGameMechanicContentChange={(filePath) => {
                    onGameMechanicContentChange({
                      ...gameMechanic.content as TDragDropTextGame,
                      filePath
                    });
                    setSelectedFilePath(filePath);
                  }}
                  key={Math.random()}
                />
              </DndProvider>
            {/*</div>*/}
          </Col>
          <Col md={8} className='mb-3 mb-md-0'>
            {descriptionSaved ? <div className='sentence-to-scramble text-area-style'><p className="d-flex flex-wrap">{descriptionArr.map((word, i) =>
                <span key={`word-${i}`} onClick={() => toggleSelection(i)}
                      className={classnames({
                        selected: currentSelection.includes(i),
                        highlighted: missingWords.includes(i)
                      })}>{word}</span>)}</p></div> :
              <textarea value={description} onChange={handleChange(setDescription)}
                        placeholder='Enter here the correct sentence, then select the words desired and click on “select word” button to create a new selectable word.' />}
            {actionButtons}
          </Col>
        </Row>
      </div>
    );
  }
  return <GameNotFound />;
}

export default memo(DragDropTextGame);
