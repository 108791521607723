import { AvatarType } from '../../../redux/slices/user';
import { useMemo } from 'react';


export default function WomanAvatar({ ...avatar }: AvatarType) {
  const { tops, bottoms, accessories, shoes, skin , head } = avatar;
  const currentTop = useMemo(() => {
    switch (tops) {
      case 'shirt-dark':
        return <g id='2'>
          <path id='Vector_48' fill='#3F6F69'
                d='M24.42 68.066s-5.511 3.453-8.7 13.494C12.51 91.6 7 113.578 7 113.578s11.987 6.307 27.4 3.153c0 0 5.098-22.775 6.14-28.724 1.064-5.968-2.814-21.178-16.12-19.92v-.02Z' />
          <path id='Vector_49' fill='#3F6F69'
                d='M96.58 67.168s5.511 3.453 8.7 13.494c3.209 10.04 8.72 32.017 8.72 32.017s-11.987 6.308-27.4 3.154c0 0-5.078-22.775-6.14-28.744-1.064-5.968 2.834-21.179 16.12-19.921Z' />
          <path id='Vector_50' fill='#4D7E79'
                d='M36.92 61.858s4.605 6.368 18.954 7.466C70.224 70.422 81.66 61 81.66 61l14.605 6.248s-1.653 36.01-3.602 42.876c-1.968 6.847.472 45.671 1.102 49.424.63 3.773-4.488 5.569-4.94 7.426-.473 1.836-29.25 6.547-32.124 7.026-2.874.479-23.758-6.507-24.998-7.026-1.24-.519-.61-7.865-.61-9.442s.452-13.514.295-17.745c-.158-4.232-2.618-35.312-3.543-41.74-.925-6.427-3.405-29.98-3.405-29.98l12.48-6.209Z' />
        </g>;
      case 'shirt':
      default :
        return <g id='shirt'>
          <g id='Clip path group'>
            <mask id='mask0_75_10' width='34' height='48' x='7' y='68' maskUnits='userSpaceOnUse'
                  style={{ maskType: 'luminance' }}>
              <g id='clippath'>
                <path id='Vector_48' fill='#fff'
                      d='M18.945 69.352c-.698.872-11.87 38.779-11.87 38.779l28.901 7.103s4.828-25.216 4.716-31.213c-.111-5.989-7.934-13.583-13.096-14.67-5.163-1.086-8.66 0-8.66 0h.009Z' />
              </g>
            </mask>
            <g mask='url(#mask0_75_10)'>
              <g id='Group'>
                <path id='Vector_49' fill='#F5EDE5'
                      d='M18.945 69.352c-.698.872-11.87 38.779-11.87 38.779l28.901 7.103s4.828-25.216 4.716-31.213c-.111-5.989-7.934-13.583-13.096-14.67-5.163-1.086-8.66 0-8.66 0h.009Z' />
                <path id='Vector_50' fill='#9FD3CB'
                      d='M34.135 70.206c.465-.409-3.172-1.764-5.144-1.81-1.628-.047-4.986 1.615-6.12 2.2l-.029-.214c-.083 0-.79.046-1.674.102 1.07-.51 3.219-1.495 4.586-1.931 1.879-.613 4.986-2.934 4.418-3.203-.483-.223-4.558.102-6.66.668 1.702-1.875 3.563-5.124 2.912-5.115-.735 0-6.68 3.435-7.963 5.013-1.153 1.43-1.209 4.262-1.209 4.79-.837.047-1.637.094-2.381.14.818-1.151 2.883-4.113 3.85-5.802 1.22-2.127 2.27-6.815 1.573-6.583-.698.232-5.274 5.348-5.99 7.242-.568 1.495-.103 4.122.13 5.162-1.47.093-2.698.186-3.489.26.791-1.087 2.726-3.937 3.126-6.5.511-3.24-.41-9.618-1.172-8.986-.763.631-4.056 9.2-4.056 11.92 0 1.904.856 3.11 1.368 3.659a1.75 1.75 0 0 0-.159.027c-4.455 1.133-7.85 5.274-7.99 5.45l.651.53s3.368-4.104 7.544-5.172c.13-.028.484-.074.995-.12-.297.807-1.041 3.193-.409 5.32.772 2.608 6.381 9.887 7.283 10.277.903.39-.018-5.988-1.441-8.95-1.21-2.516-4.205-5.756-5.08-6.666.54-.046 1.21-.093 1.954-.14.372.836 1.954 4.3 3.042 5.71 1.228 1.616 7.07 5.209 7.804 5.246.735.028-1.6-4.169-3.376-5.868-1.544-1.476-6.121-4.372-7.274-5.106 1.106-.074 2.344-.148 3.534-.213.233.5 1.712 3.509 3.247 4.558 1.674 1.151 8.352 2.748 9.06 2.544.66-.186-2.41-3.082-4.623-4.299 2.083.446 6.4.474 6.873.205.55-.307-2.725-2.396-4.641-2.86-1.005-.241-2.79-.483-4.27-.65l-.027-.195c.78.093 2.483.316 3.683.557 1.6.325 7.079-.78 7.544-1.197ZM21.4 67.866c-.456.984-.726 2.135-.837 2.655-1.07.056-2.14.121-3.163.177.54-.362 2.409-1.616 4-2.832Zm1.99 5.766c-2.111-.855-5.125-1.839-5.934-2.099 1.2-.074 2.344-.13 3.218-.185.112 0 .195-.01.298-.019.549.622 1.516 1.634 2.418 2.303Z' />
              </g>
            </g>
          </g>
          <g id='Clip path group_2'>
            <mask id='mask1_75_10' width='34' height='47' x='82' y='67' maskUnits='userSpaceOnUse'
                  style={{ maskType: 'luminance' }}>
              <g id='clippath-1'>
                <path id='Vector_51' fill='#fff'
                      d='M103.842 68.098c.697.873 11.869 38.78 11.869 38.78l-28.9 7.102s-4.828-25.215-4.717-31.213c.112-5.998 7.935-13.583 13.097-14.669 5.163-1.077 8.66 0 8.66 0h-.009Z' />
              </g>
            </mask>
            <g mask='url(#mask1_75_10)'>
              <g id='Group_2'>
                <path id='Vector_52' fill='#F5EDE5'
                      d='M103.842 68.098c.697.873 11.869 38.78 11.869 38.78l-28.9 7.102s-4.828-25.215-4.717-31.213c.112-5.998 7.935-13.583 13.097-14.669 5.163-1.077 8.66 0 8.66 0h-.009Z' />
                <path id='Vector_53' fill='#9FD3CB'
                      d='M96.196 70.15c1.2-.242 2.902-.464 3.683-.557l-.028.195c-1.478.167-3.264.399-4.27.65-1.915.464-5.19 2.553-4.64 2.86.474.269 4.79.24 6.873-.205-2.213 1.225-5.283 4.113-4.623 4.299.707.204 7.386-1.402 9.06-2.554 1.535-1.049 3.014-4.066 3.247-4.558 1.19.065 2.427.148 3.534.213-1.153.725-5.73 3.63-7.274 5.107-1.776 1.69-4.111 5.895-3.376 5.867.735-.028 6.576-3.63 7.804-5.245 1.079-1.411 2.66-4.884 3.042-5.71a89.39 89.39 0 0 1 1.953.14c-.874.91-3.87 4.15-5.079 6.665-1.414 2.962-2.344 9.34-1.442 8.95.903-.39 6.512-7.668 7.284-10.277.632-2.126-.112-4.503-.41-5.32.512.046.865.083.996.12 4.185 1.059 7.515 5.125 7.543 5.172l.652-.53c-.14-.176-3.535-4.317-7.991-5.45a1.734 1.734 0 0 0-.158-.027c.512-.557 1.377-1.755 1.377-3.658 0-2.72-3.302-11.29-4.056-11.92-.762-.632-1.683 5.746-1.172 8.986.41 2.563 2.344 5.422 3.135 6.5-.791-.075-2.019-.168-3.488-.26.223-1.05.697-3.677.13-5.172-.716-1.894-5.293-7-5.99-7.242-.698-.241.353 4.457 1.572 6.583.967 1.69 3.032 4.642 3.851 5.802a386.72 386.72 0 0 0-2.382-.139c0-.53-.055-3.36-1.209-4.79-1.274-1.579-7.218-5.005-7.962-5.014-.651 0 1.21 3.24 2.911 5.115-2.111-.566-6.176-.89-6.66-.668-.567.26 2.54 2.59 4.419 3.203 1.367.446 3.506 1.43 4.585 1.931a71.034 71.034 0 0 1-1.674-.102l-.028.214c-1.135-.585-4.483-2.238-6.12-2.2-1.972.055-5.619 1.401-5.144 1.81.465.418 5.944 1.513 7.543 1.197l-.018.019Zm9.181-.706c-1.024-.055-2.084-.12-3.163-.176-.112-.52-.381-1.662-.837-2.655 1.591 1.216 3.46 2.47 4 2.831Zm-3.563.632c.102 0 .186.009.298.018.874.047 2.018.112 3.218.186-.809.26-3.814 1.244-5.934 2.098.902-.66 1.869-1.68 2.418-2.302Z' />
              </g>
            </g>
          </g>
          <g id='Clip path group_3'>
            <mask id='mask2_75_10' width='86' height='117' x='18' y='58' maskUnits='userSpaceOnUse'
                  style={{ maskType: 'luminance' }}>
              <g id='clippath-2'>
                <path id='Vector_54' fill='#fff'
                      d='M43.51 58.935s-18.342 6.694-20.686 7.947c-2.344 1.253-3.888 2.479-3.888 2.479s4.623 45.381 4.623 49.559c0 4.178 2.362 51.657 2.502 51.239.14-.418 26.566 4.317 37.3 3.899 10.734-.417 33.216-2.785 33.216-2.785s-1.255-56.726-.558-60.904c.698-4.178 7.59-42.308 7.59-42.308L77.285 58.09l-20.12 28.938-13.654-28.093Z' />
              </g>
            </mask>
            <g mask='url(#mask2_75_10)'>
              <g id='Group_3'>
                <path id='Vector_55' fill='#FFF7F0'
                      d='M43.51 58.935s-18.342 6.694-20.686 7.947c-2.344 1.253-3.888 2.479-3.888 2.479s4.623 45.381 4.623 49.559c0 4.178 2.362 51.657 2.502 51.239.14-.418 26.566 4.317 37.3 3.899 10.734-.417 33.216-2.785 33.216-2.785s-1.255-56.726-.558-60.904c.698-4.178 7.59-42.308 7.59-42.308L77.285 58.09l-20.12 28.938-13.654-28.093Z' />
                <path id='Vector_56' fill='#9FD3CB'
                      d='M52.533 94.13s-.093-.018-.158-.027c.521-.557 1.377-1.755 1.377-3.658 0-2.72-3.302-11.29-4.056-11.92-.762-.632-1.683 5.746-1.171 8.986.409 2.563 2.343 5.422 3.134 6.5a94.782 94.782 0 0 0-3.255-.242c.148-.956.483-3.64-.103-5.18-.716-1.895-5.293-7.001-5.99-7.242-.698-.242.353 4.456 1.572 6.582.995 1.736 3.358 4.772 4.176 5.821-.837-.055-1.748-.111-2.706-.167 0-.529-.056-3.36-1.21-4.79-1.274-1.579-7.218-5.005-7.953-5.014-.65 0 1.21 3.24 2.912 5.116-2.112-.567-6.176-.892-6.66-.669-.568.26 2.54 2.59 4.418 3.194 1.367.446 3.507 1.43 4.586 1.931a70.19 70.19 0 0 1-1.674-.102l-.028.214c-1.135-.585-4.493-2.247-6.12-2.2-1.973.055-5.62 1.401-5.145 1.81.465.417 5.944 1.513 7.544 1.197 1.2-.241 2.902-.464 3.683-.557l-.028.195c-1.478.167-3.264.4-4.269.65-1.916.464-5.19 2.553-4.642 2.86.475.269 4.79.24 6.874-.205-2.213 1.226-5.283 4.113-4.622 4.299.706.204 7.385-1.402 9.06-2.544 1.534-1.05 3.013-4.067 3.246-4.559 1.19.065 2.427.149 3.534.214-1.162.724-5.73 3.63-7.274 5.106-1.776 1.69-4.111 5.896-3.376 5.868.735-.028 6.576-3.63 7.804-5.246 1.079-1.41 2.66-4.883 3.032-5.71.735.047 1.405.093 1.954.14-.875.91-3.87 4.15-5.079 6.666-1.414 2.962-2.344 9.34-1.442 8.95.902-.39 6.511-7.669 7.283-10.278.633-2.126-.111-4.503-.409-5.32.512.047.865.084.995.121 4.186 1.058 7.516 5.125 7.544 5.171l.651-.529c-.14-.176-3.534-4.317-7.99-5.45l-.019.019Zm-13.348 2.387c.903-.66 1.87-1.68 2.419-2.302.102 0 .186.009.298.018.874.046 2.018.111 3.218.186-.81.26-3.814 1.244-5.934 2.098Zm2.828-3.12c-.111-.52-.381-1.661-.837-2.655 1.59 1.216 3.46 2.47 4 2.832-1.023-.056-2.084-.12-3.163-.177Z' />
                <path id='Vector_57' fill='#9FD3CB'
                      d='M87.164 79.74c2.028.103 8.558-2.014 9.05-2.553.466-.51-3.664-1.364-6.195-1.253 2.01-.706 5.703-2.943 5.972-3.417.307-.547-3.581-.612-5.46-.009-.986.316-2.632 1.05-3.972 1.68l-.13-.157c.716-.325 2.279-1.03 3.432-1.449 1.535-.566 5.619-4.363 5.805-4.957.186-.595-3.628.157-5.34 1.142-1.413.817-3.404 3.983-4.055 5.069l-.13-.167c-.065.055-.642.455-1.377.956.651-.993 1.963-2.953 2.893-4.039 1.284-1.504 2.707-5.106 2.083-5.032-.53.065-3.832 2.46-5.33 4.048.466-2.488.345-6.22-.204-5.877-.623.39-3.888 6.416-4.158 8.43-.233 1.82 1.2 4.262 1.479 4.717-.837.585-1.628 1.132-2.353 1.643.167-1.17.697-5.153.632-7.232-.074-2.451-1.646-6.991-2.111-6.425-.465.566-1.693 7.307-1.312 9.303.316 1.68 2.047 3.862 2.577 4.502a90.111 90.111 0 0 0-2.595 1.876c.102-1.337.25-4.782-.745-7.177-1.255-3.026-5.395-7.984-5.71-7.046-.317.928 1.367 9.961 2.79 12.273.995 1.625 2.353 2.2 3.088 2.405-.047.046-.093.083-.121.111-3.21 3.296-3.925 8.597-3.953 8.82l.828.112c0-.056.716-5.255 3.72-8.347.093-.093.372-.316.782-.622.176.845.79 3.268 2.437 4.744 2.027 1.82 10.622 5.097 11.59 4.949.976-.149-3.163-5.097-5.916-6.88-2.344-1.513-6.605-2.71-7.823-3.036.437-.325.986-.715 1.59-1.142.754.51 3.926 2.637 5.582 3.278 1.897.733 8.762.752 9.404.39.641-.362-3.554-2.72-5.954-3.231-2.083-.446-7.506-.53-8.873-.548.902-.64 1.925-1.356 2.902-2.033.465.297 3.302 2.098 5.162 2.19l.019-.009Zm-2.372-4.122c.084-.065.158-.111.242-.167.79.242 2.148.604 3.265.697-2.251.37-5.33 1.104-6.158 1.309.986-.688 1.925-1.337 2.642-1.839h.009Zm-1.21-3.342c.13 1.077.494 2.2.68 2.692-.884.613-1.758 1.217-2.596 1.801.27-.584 1.21-2.636 1.926-4.502l-.01.009Z' />
                <path id='Vector_58' fill='#9FD3CB'
                      d='M88.261 129.067c-1.823-.91-6.51-.789-8.101-.724l.083-.251c-.102-.037-.92-.362-1.953-.77 1.488-.009 4.437 0 6.223.213 2.456.297 7.209-.705 6.706-1.299-.427-.511-5.218-2.284-7.915-2.749 2.92-1.234 6.743-3.936 6-4.27-.838-.381-9.395.38-11.684 1.495-2.065 1.011-3.627 4.196-3.906 4.799-1.098-.436-2.15-.844-3.117-1.225 1.266-.65 5.637-2.925 7.767-4.429 2.503-1.773 6.177-6.545 5.256-6.638-.912-.102-8.809 3.287-10.632 5.06-1.544 1.504-2.446 4.884-2.688 5.896-1.674-.65-3.07-1.18-3.99-1.505 1.47-.817 5.171-3.035 6.994-5.728 2.298-3.407 4.614-11.132 3.423-10.816-1.19.316-9.469 8.3-10.901 11.392-1.005 2.163-.67 3.973-.382 4.874-.083-.019-.148-.037-.195-.046-5.655-1.059-11.702 1.856-11.953 1.977l.465.938c.056-.028 5.99-2.888 11.293-1.894.158.028.586.176 1.19.39-.763.761-2.865 3.082-3.274 5.821-.502 3.361 2.01 14.576 2.837 15.495.828.919 3.144-6.814 3.098-10.918-.038-3.491-1.73-8.746-2.242-10.24.642.232 1.423.538 2.288.863-.018 1.142-.046 5.905.428 8.077.549 2.479 5.274 9.637 6.093 10.064.818.418.381-5.58-.735-8.43-.977-2.488-4.632-8.188-5.563-9.618a730.42 730.42 0 0 1 4.13 1.615c0 .687.093 4.884 1.275 6.889 1.293 2.182 8.027 7.52 8.93 7.66.855.139-1.108-4.763-2.977-7.316 2.13 1.606 7.013 3.908 7.692 3.853.782-.056-1.832-4.15-3.758-5.692-1.013-.807-2.92-2.014-4.502-2.98l.075-.241c.837.52 2.65 1.671 3.888 2.571 1.646 1.207 8.446 2.841 9.2 2.619.753-.223-2.67-3.668-4.884-4.772l.019-.01Zm-8.334-4.595c-1.042.882-1.953 2.033-2.353 2.571-1.247-.492-2.493-.984-3.684-1.457.8-.121 3.59-.567 6.028-1.105l.01-.009Zm-6.409 2.079c1.395.557 2.726 1.077 3.749 1.486.12.046.232.093.344.139.298.993.856 2.655 1.535 3.881-1.944-2.089-4.837-4.782-5.618-5.506h-.01Z' />
                <path id='Vector_59' fill='#9FD3CB'
                      d='M107.73 169.314c-1.823-.91-6.511-.789-8.102-.724l.084-.251c-.102-.037-.921-.362-1.954-.77 1.489 0 4.437 0 6.223.213 2.456.297 7.209-.705 6.707-1.299-.428-.511-5.218-2.284-7.916-2.749 2.921-1.234 6.744-3.936 6-4.27-.837-.381-9.395.38-11.683 1.494-2.065 1.012-3.628 4.197-3.907 4.8-1.107-.436-2.167-.854-3.144-1.234.977-.493 5.563-2.841 7.795-4.41 2.502-1.774 6.176-6.546 5.255-6.639-.911-.102-8.808 3.287-10.631 5.06-1.619 1.569-2.428 5.144-2.586 5.933-1.721-.669-3.154-1.216-4.093-1.541 1.47-.817 5.172-3.036 6.985-5.729 2.298-3.407 4.614-11.131 3.423-10.816-1.19.316-9.469 8.3-10.901 11.392-1.005 2.163-.67 3.974-.382 4.874-.083-.018-.148-.037-.195-.046-5.655-1.059-11.701 1.857-11.953 1.977l.466.938c.055-.028 5.99-2.887 11.292-1.894.158.028.586.176 1.19.39-.762.761-2.864 3.082-3.274 5.821-.502 3.361 2.01 14.576 2.837 15.495.828.92 3.144-6.805 3.098-10.918-.037-3.491-1.73-8.746-2.242-10.24.642.232 1.423.538 2.288.863-.018 1.142-.046 5.905.428 8.077.549 2.479 5.274 9.637 6.093 10.064.818.418.381-5.579-.735-8.43-.977-2.488-4.632-8.188-5.563-9.618a730.42 730.42 0 0 1 4.13 1.615c0 .687.093 4.884 1.275 6.889 1.293 2.182 8.027 7.52 8.93 7.66.855.139-1.107-4.763-2.977-7.316 2.13 1.606 7.013 3.908 7.692 3.853.782-.056-1.823-4.15-3.757-5.691-1.014-.808-2.921-2.015-4.502-2.981l.074-.241c.837.52 2.651 1.671 3.888 2.572 1.646 1.207 8.446 2.841 9.199 2.618.754-.223-2.669-3.667-4.883-4.772l.028-.019Zm-8.334-4.595c-1.042.882-1.954 2.033-2.354 2.571-1.246-.492-2.493-.984-3.683-1.457.8-.121 3.59-.567 6.027-1.105l.01-.009Zm-6.41 2.079c1.396.557 2.726 1.077 3.75 1.486.12.046.232.092.343.139.298.993.856 2.655 1.535 3.881-1.944-2.089-4.846-4.782-5.618-5.506h-.01Z' />
                <path id='Vector_60' fill='#9FD3CB'
                      d='M49.51 160.309c2.95-1.709 10.167-10.659 10.372-11.875.205-1.216-7.293 1.792-10.483 4.391-2.716 2.21-5.777 6.806-6.632 8.133a111.29 111.29 0 0 1-.744-2.33c.902-.696 4.66-3.649 6.064-5.375 1.6-1.969 4.26-10.129 4.084-11.03-.177-.901-4.614 3.175-6.149 5.83-1.34 2.312-3.525 8.718-4.065 10.343-.418-1.318-.874-2.804-1.311-4.224.54-.437 3.767-3.12 4.595-5.292.902-2.368.884-10.946.428-11.745-.428-.752-3.042 3.835-3.879 6.88-.074-2.665-1.312-7.91-1.777-8.412-.53-.575-2.111 4.011-2.111 6.481 0 1.29.242 3.537.474 5.375l-.232.093c-.112-.975-.354-3.11-.41-4.633-.083-2.042-3.041-8.365-3.683-8.81-.642-.446-1.21 4.372-.688 6.786.428 1.987 3.441 5.58 4.483 6.778l-.251.093c.037.102.288.937.614 2.005-.93-1.151-2.763-3.463-3.712-4.995-1.302-2.098-5.05-5.18-5.2-4.419-.13.65 1.47 5.496 2.782 7.891-2.79-1.513-7.293-2.813-7.088-2.024.223.892 6.158 7.094 8.455 8.18 2.084.984 5.553.213 6.195.056.382 1.253.754 2.441 1.089 3.518-1.126-.566-5.833-2.887-8.41-3.63-2.948-.854-8.975-.733-8.482.037.493.78 8.074 4.819 10.594 5.134 2.205.279 5.544-1.012 6.363-1.346.502 1.606.93 2.943 1.237 3.816-1.553-.641-5.6-2.145-8.846-1.875-4.102.334-11.6 3.342-10.604 4.075.986.734 12.408 2.21 15.72 1.402 2.325-.566 3.535-1.959 4.055-2.748.028.074.066.139.084.186 2.698 5.078 8.753 7.975 9.004 8.096l.447-.947c-.056-.028-6-2.869-8.53-7.632-.074-.149-.233-.566-.437-1.17 1.079.121 4.195.316 6.595-1.077l-.01.01Zm-14.566-10.046c1.34.26 2.818.251 3.479.232l1.153 3.779c-.595-.548-2.679-2.451-4.632-4.02v.009Zm4.456-.148a7.832 7.832 0 0 0-.112-.353c.595-.854 1.544-2.321 2.083-3.621-.418 2.822-.725 6.759-.809 7.817-.437-1.439-.856-2.804-1.172-3.853l.01.01Z' />
              </g>
            </g>
          </g>
          <path id='Vector_61' fill='#F5EDE5'
                d='M44.757 55.212c.4-1.022-13.087 7.325-13.087 7.325l10.12 5.088s-3.386 6.136-3.311 6.554c.074.418 7.125 3.909 10.39 5.998 3.264 2.089 8.306 6.86 8.306 6.86s-3.004-6.48-5.516-13.173c-2.511-6.694-7.376-17.399-6.883-18.652h-.019Z' />
          <path id='Vector_62' fill='#F5EDE5'
                d='M72.57 52.315c-.4-1.021 16.491 10.064 16.491 10.064l-12.148 4.364s4.195 7.25 4.121 7.668c-.074.418-10.027 5.57-13.283 7.67-3.265 2.088-10.576 4.957-10.576 4.957s5.925-9.934 8.437-16.619c2.511-6.694 7.45-16.841 6.967-18.095l-.01-.009Z' />
        </g>;
    }
  }, [tops]);
  const currentBottom = useMemo(() => {
    switch (tops) {

      case 'trousers':
      default :
        return <g id='trousers'>
          <path id='Vector_63' fill='#F7C897'
                d='m63.621 219.077 7.814 40.609 31.216-.761s-1.144-72.287-9.813-72.547c-36.733-1.104-29.217 32.699-29.217 32.699Z' />
          <path id='Vector_64' fill='#F7C897'
                d='M31.186 196.313c-6.279 2.135.326 54.581.967 63.559l30.9-1.012.559-39.773s-.28-33.711-32.426-22.774Z' />
          <path id='Vector_65' fill='#F7C897'
                d='M31.354 166.009s-2.828 30.842-2.614 40.665c.214 9.832 18.157 15.671 35.272 15.059 30.286-1.087 35.681-15.199 33.272-28.8-2.418-13.601-6.483-27.778-6.483-27.778s-31.84 9.182-59.448.845v.009Z' />
          <path id='Vector_66' fill='#DAB282'
                d='M31.716 262.583s-1.628-6.09 0-6.406c1.637-.325 31.31 0 31.31 0s1.228 5.106-.958 5.933c-2.186.826-24.473 2.079-30.342.482l-.01-.009Z' />
          <path id='Vector_67' fill='#DAB282'
                d='M71.072 262.583s-1.758-6.09 0-6.406c1.758-.325 33.672 0 33.672 0s1.321 5.106-1.032 5.933c-2.354.826-26.324 2.079-32.63.482l-.01-.009Z' />
          <path id='Vector_68' fill='#fff'
                d='M65.751 171.255a2.252 2.252 0 1 0-4.502 0 2.259 2.259 0 0 0 2.251 2.256 2.259 2.259 0 0 0 2.251-2.256Z' />
          <path id='Vector_69' fill='#DAB282'
                d='M62.989 219.207c-.019-.083-1.833-9.005-2.214-15.588-.381-6.554-1.312-34.453-1.321-34.732l1.284-.046c.009.278.94 28.168 1.32 34.704.382 6.499 2.177 15.319 2.196 15.402l-1.265.26Z' />
          <path id='Vector_70' fill='#DAB282'
                d='M56.403 176.426c-.67 0-1.256-.019-1.73-.065-7.125-.641-23.692-4.392-23.84-4.429l.14-1.624c.139.037 16.77 4.14 23.812 4.772 7.004.631 37.197-3.928 37.495-3.965l.195 1.272c-1.162.177-26.817 4.048-36.072 4.048v-.009Z' />
        </g>;
    }
  }, [bottoms]);
  const currentShoes = useMemo(() => {
    switch (tops) {

      case 'shoes':
      default :
        return  <g id='shoes'>
          <path id='Vector_71' fill='#EDD66B'
                d='M90.717 313.386s.326 9.563-.158 15.616c-.632 7.938-3.32 5.106-.26 13.796.66 1.866 9.952 6.508 15.189 5.617 5.246-.891 10.176-6.517 10.028-8.003-.41-3.881-3.898-6.619-6.344-10.231-2.986-4.391-3.712-13.638-5.023-16.99 0 0-7.758-1.541-13.432.204v-.009Z' />
          <path id='Vector_72' fill='#EEEFF1'
                d='M88.847 335.482s-.492 7.576.93 10.751c1.638 3.668 9.181 7.53 18.892 5.469 6.726-1.43 7.591-3.194 8.167-6.36.949-5.227-4.502-11.132-4.502-11.132s3.712 3.881 2.484 6.434c-1.228 2.554-4.995 4.234-9.665 4.893-4.297.594-10.24-.975-12.222-2.794-1.925-1.774-4.344-3.501-4.093-7.261h.01Z' />
          <path id='Vector_73' fill='#EEEFF1'
                d='M94.14 334.006c-.484 1.3 1.535 2.052 2.019.752.483-1.3-1.535-2.052-2.019-.752Z' />
          <path id='Vector_74' fill='#EEEFF1'
                d='M94.205 328.798c-.483 1.3 1.535 2.052 2.019.752.483-1.3-1.535-2.052-2.019-.752Z' />
          <path id='Vector_75' fill='#EEEFF1'
                d='M94.038 323.645c-.484 1.3 1.544 2.052 2.018.743.484-1.3-1.534-2.052-2.018-.743Z' />
          <path id='Vector_76' fill='#EEEFF1'
                d='M93.536 318.427c-.484 1.3 1.534 2.052 2.018.752.484-1.299-1.544-2.051-2.018-.752Z' />
          <path id='Vector_77' fill='#EEEFF1'
                d='M105.395 333.449c-.083 1.383 2.065 1.523 2.158.139.084-1.383-2.065-1.522-2.158-.139Z' />
          <path id='Vector_78' fill='#EEEFF1'
                d='M103.963 328.445c-.093 1.383 2.065 1.523 2.148.139.084-1.383-2.064-1.522-2.148-.139Z' />
          <path id='Vector_79' fill='#EEEFF1'
                d='M102.316 323.552c-.093 1.384 2.065 1.523 2.149.139.093-1.383-2.065-1.522-2.149-.139Z' />
          <path id='Vector_80' fill='#EEEFF1'
                d='M100.326 318.706c-.093 1.383 2.065 1.522 2.149.139.093-1.383-2.065-1.522-2.149-.139Z' />
          <path id='Vector_81' fill='#373333'
                d='M114.874 338.203c-.196-.882-1.563-.511-1.358.371 1.116 4.902-8.418 6.555-11.683 6.276-3.926-.334-7.786-2.767-10.93-5.013-.735-.53-1.442.687-.707 1.216 4.837 3.444 9.916 5.96 15.906 4.818 3.758-.715 9.879-2.738 8.762-7.668h.01Z' />
          <path id='Vector_82' fill='#EEEFF1'
                d='M90.531 339.707s21.915-1.764 23.04-1.931c1.126-.158.717 3.537-2.334 5.087-3.051 1.541-8.809 3.055-13.18 1.402-4.382-1.643-7.517-4.558-7.517-4.558h-.009Z' />
          <path id='Vector_83' fill='#EDD66B'
                d='M38.311 320.572s1.172 3.045-1.088 4.484c-5.042 3.203-13.478 7.845-18.771 9.693-6.056 2.117-9.58 3.212-10.427 4.809-.847 1.606 3.237 3.64 11.125 3.695 9.227.065 22.37-1.086 26.91-1.086 4.539 0 7.413.167 8.176-.251.763-.417-1.098-21.01-1.098-21.01-9.525 2.071-14.836-.343-14.836-.343l.01.009Z' />
          <path id='Vector_84' fill='#373333'
                d='M15.96 339.354c-2.41-.307-4.82-.353-7.247-.372-.781 0-.781 1.198 0 1.207 2.428.019 4.828.065 7.246.363.772.092.763-1.115 0-1.207v.009Z' />
          <path id='Vector_85' fill='#EEEFF1'
                d='M8.025 339.549s9.72.678 16.343.325c6.623-.353 28.854-2.043 30.137-1.968 0 0 .056 3.722 0 4.781-.055 1.058-19.989 1.755-27.105 1.968-7.116.214-18.361.26-18.631-.566-1.367-4.057-.735-4.54-.735-4.54h-.01Z' />
          <path id='Vector_86' fill='#EEEFF1'
                d='M22.628 333.096c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_87' fill='#EEEFF1'
                d='M27.772 331.267c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_88' fill='#EEEFF1'
                d='M32.767 329.225c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_89' fill='#EEEFF1'
                d='M37.697 326.839c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_90' fill='#373333' d='m54.492 340.075-30.372 1.641.049.909 30.372-1.642-.05-.908Z' />
          <path id='Vector_91' fill='#CBCACA' d='M24.33 339.911h-.623v4.828h.624v-4.828Z' />
          <path id='Vector_92' fill='#EEEFF1'
                d='M13.996 336.299s-3.925.873-5.72 2.878c0 0 7.479.288 8.055.288 0 0-.437-2.042-2.335-3.166Z' />
        </g>
    }
  }, [shoes]);

  const currentHead = useMemo(()=>{
    switch (head){
      case 'E77D2E': return <g id="Head 2" clipPath="url(#clip0_232_504)">
        <path id="Vector_19" fill={`#${skin}`} d="M36.4 54.22s.5 11.48.58 12.31c.08.83 11.48 4.08 17.72-.5V51.06l-18.3 3.16Z"/>
        <path id="Vector_20" fill={`#${skin}`} d="M49.63 14.79s-20.46 3.16-20.88 3.91c-.42.75-.67 27.62 0 29.7.67 2.08 8.66 8.98 10.23 9.23 1.58.25 8.15.83 9.23 0s9.32-8.57 10.15-9.9c.83-1.33 1.33-18.24 1.16-19.68-.17-1.45-8.82-12.01-9.9-13.26h.01Z"/>
        <path id="Vector_21" fill="#4B2922" d="m47.05 34 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.14-7.65.16h-.01Z"/>
        <path id="Vector_22" fill="#4B2922" d="m30.42 34.32 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_23" fill="#4B2922" d="M35.62 33.82h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_24" fill="#4B2922" d="M52.23 33.82h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_25" fill={`#${skin}`} d="M42.35 36.93c-.92.08-3 3.79-3.02 4.91-.02 1.12 2.39 3.08 2.85 3.02.46-.06 2.98-2.39 2.99-2.95.01-.56-2.3-5.02-2.81-4.98h-.01Z"/>
        <path id="Vector_26" fill="#C97042" d="m46.61 27.37.48 2.25s4.42-.93 8.82.14l.03-1.87s-3.69-1.68-9.33-.52Z"/>
        <path id="Vector_27" fill="#C97042" d="m29.08 28.17.18 1.87c4.31-1.18 8.8-.36 8.8-.36l.31-2.26c-5.72-1.01-9.28.76-9.28.76l-.01-.01Z"/>
        <path id="Vector_28" fill="#862C2D" d="M39.46 47.21c3.41 1.07 7.41.03 7.52-.07l.76 1.6c-1.51 1.33-5.32 3.05-8.92.1l.63-1.63h.01Z"/>
        <path id="Vector_29" fill="#F6B09E" d="M59.52 32.04s4.32-1.94 5.33-1.18c1.01.76.44 10.57-.44 11.99-.88 1.41-6.07 2.01-6.07 2.01s.77-6.03 1.18-12.82Z"/>
        <path id="Vector_30" fill="#C97042" d="M52.22 17.46s-5.61 9.91-23.83 12.64c0 0 4.12 12.44 2.03 26.4 0 0-12.6-14.84-14.29-19.93-1.03-3.1 4.21-20.7 4.88-22.28.67-1.58 6.49-9.56 7.74-10.73S48.63-.69 51.87.14c3.24.83 11.81 10.31 12.56 12.39s9.45 20.58 7.7 24.46c-1.75 3.88-16.19 19.5-16.19 19.5S53.8 42.81 56.86 28c.29-1.38-4.64-10.55-4.64-10.55v.01Z"/>
      </g>
      case '4b2922' : return  <g id="Head 1">
        <path id="Vector_19" fill={`#${skin}`} d="M30.71 35.08s-4.32-1.94-5.33-1.18c-1.01.76-.44 10.57.44 11.99.88 1.41 6.07 2.01 6.07 2.01s-.77-6.03-1.18-12.82Z"/>
        <path id="Vector_20" fill={`#${skin}`} d="M36.13 55.34s.5 13.4.58 14.24c.08.83 11.48 4.08 17.72-.5V54.11l-18.3 1.24v-.01Z"/>
        <path id="Vector_21" fill={`#${skin}`} d="M59.27 34.26s4.32-1.94 5.33-1.18c1.01.76.44 10.57-.44 11.99-.88 1.41-6.07 2.01-6.07 2.01s.77-6.03 1.18-12.82Z"/>
        <path id="Vector_22" fill={`#${skin}`} d="M57.91 16.84s-30.02-.77-30.44-.02c-.42.75 2.46 31.71 3.12 33.79.67 2.08 9.38 9.74 10.96 9.99s6.77.43 7.86-.4c1.08-.83 9.33-8.71 10.16-10.04.83-1.33 1.72-18.57 1.55-20.01-.17-1.45-2.13-12.05-3.21-13.3v-.01Z"/>
        <path id="Vector_23" fill="#4B2922" d="m47.55 37.05 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_24" fill="#4B2922" d="m30.93 37.37 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.14-7.65.16h-.01Z"/>
        <path id="Vector_25" fill="#4B2922" d="M36.12 36.87h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_26" fill="#4B2922" d="M52.73 36.87h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_27" fill={`#${skin}`} d="M43.06 39.99c-1.14.06-3.69 3.32-3.71 4.32-.02 1 2.79 2.57 3.92 2.57.57 0 3.31-1.96 3.32-2.46.01-.5-2.89-4.46-3.53-4.42v-.01Z"/>
        <path id="Vector_28" fill="#4B2922" d="m47.58 30.48.48 2.25s4.42-.93 8.82.14l.03-1.87s-3.69-1.68-9.33-.52Z"/>
        <path id="Vector_29" fill="#4B2922" d="m30.04 31.28.18 1.87c4.3-1.18 8.8-.36 8.8-.36l.31-2.26c-5.72-1.01-9.28.76-9.28.76l-.01-.01Z"/>
        <path id="Vector_30" fill="#862C2D" d="M39.32 49.38c3.41 1.07 7.95.1 8.06 0v2.4c-1.51 1.33-4.47 3.04-8.06.09v-2.5.01Z"/>
        <path id="Vector_31" fill="#4B2922" d="M45.89 17.26s-20.58 1.38-16.57 16.47c0 0 3.94 5.76.56 13.04 0 0-11.73-.59-13.74-9.58-.71-3.19 2.53-17.68 4.14-22.43.74-2.18 10.63-12.48 12-13.5 2.92-2.17 26.7-1.19 28.86.38 2.71 1.96 9.58 12.29 10.33 14.38.75 2.08 6.02 18.08 2.35 24.38-2.14 3.68-13.49 4.81-13.49 4.81s-3.58-4.96-.99-13.72c3.52-11.88-13.46-14.21-13.46-14.21l.01-.02Z"/>
      </g>
      case 'ac7f69' : return    <g id="Head 3">
        <path id="Vector_19" fill={`#${skin}`} d="M37.45 56.22s.5 11.48.58 12.31c.08.83 11.48 4.08 17.72-.5V53.06l-18.3 3.16Z"/>
        <path id="Vector_20" fill={`#${skin}`} d="M50.68 16.79S30.22 19.95 29.8 20.7c-.42.75-.67 27.62 0 29.7.67 2.08 8.66 8.98 10.23 9.23 1.58.25 8.15.83 9.23 0s9.32-8.57 10.15-9.9c.83-1.33 1.33-18.24 1.16-19.68-.17-1.44-8.82-12.01-9.9-13.26h.01Z"/>
        <path id="Vector_21" fill="#4B2922" d="m48.1 36 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_22" fill="#4B2922" d="m31.48 36.32 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_23" fill="#4B2922" d="M36.68 35.82h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_24" fill="#4B2922" d="M53.28 35.82h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_25" fill={`#${skin}`} d="M43.41 38.93c-.92.08-3 3.79-3.02 4.91-.02 1.12 2.39 3.08 2.85 3.02.46-.06 2.98-2.39 2.99-2.95.01-.56-2.3-5.02-2.81-4.98h-.01Z"/>
        <path id="Vector_26" fill="#AC7F69" d="m47.67 29.37.48 2.25s4.42-.93 8.82.14l.03-1.87s-3.69-1.68-9.33-.52Z"/>
        <path id="Vector_27" fill="#AC7F69" d="m30.14 30.17.18 1.87c4.31-1.18 8.8-.36 8.8-.36l.31-2.26c-5.72-1.01-9.28.76-9.28.76l-.01-.01Z"/>
        <path id="Vector_28" fill="#EE8585" d="M40.51 49.21c3.41 1.07 7.41.03 7.52-.07l.76 1.6c-1.51 1.33-5.32 3.05-8.92.1l.63-1.63h.01Z"/>
        <path id="Vector_29" fill="#AC7F69" d="M51.26 20.52s-12.94 7.41-21.82 11.57c0 0 2.03 17.71-2.31 21.79 0 0-5.57-10.55-7.57-13.13-2-2.59 1.83-22.89 2.5-24.47.67-1.58 6.49-9.56 7.74-10.73 1.25-1.16 19.88-4.24 23.13-3.41 3.24.83 11.81 10.31 12.56 12.39s.33 17.44-1.41 21.32c-1.75 3.88-3.86 5.39-3.86 5.39s-1.63-9.99-2.3-11.24c-.67-1.25-6.65-9.48-6.65-9.48h-.01Z"/>
        <path id="Vector_30" fill={`#${skin}`} d="M60.57 34.04s4.32-1.94 5.33-1.18c1.01.76.44 10.57-.44 11.99-.88 1.41-6.07 2.01-6.07 2.01s.77-6.03 1.18-12.82Z"/>
      </g>
      case  'FFF8C8': return   <g id="Head 4">
        <path id="Vector_19" fill={`#${skin}`} d="M36.44 56.12s.5 11.48.58 12.31c.08.83 11.48 4.08 17.72-.5V52.96l-18.3 3.16Z"/>
        <path id="Vector_20" fill={`#${skin}`} d="M49.67 16.69s-20.46 3.16-20.88 3.91c-.42.75 1.01 27.79 1.68 29.86.67 2.08 6.98 8.81 8.56 9.06s8.15.83 9.23 0 9.32-8.57 10.15-9.9c.83-1.33 1.33-18.24 1.16-19.68-.17-1.44-8.82-12.01-9.9-13.26v.01Z"/>
        <path id="Vector_21" fill="#4B2922" d="m47.09 35.9 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_22" fill="#4B2922" d="m30.47 36.22 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_23" fill="#4B2922" d="M35.66 35.72h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_24" fill="#4B2922" d="M52.27 35.72h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_25" fill={`#${skin}`} d="M42.39 38.82c-.92.08-3 3.79-3.02 4.91-.02 1.12 2.39 3.08 2.85 3.02.46-.06 2.98-2.39 2.99-2.95.01-.56-2.3-5.02-2.81-4.98h-.01Z"/>
        <path id="Vector_26" fill="#FFF0DF" d="m46.66 29.26.48 2.25s4.42-.93 8.82.14l.03-1.87s-3.69-1.68-9.33-.52Z"/>
        <path id="Vector_27" fill="#FFF0DF" d="m29.12 30.06.18 1.87c4.31-1.18 8.8-.36 8.8-.36l.31-2.26c-5.72-1.01-9.28.76-9.28.76l-.01-.01Z"/>
        <path id="Vector_28" fill="#EE8585" d="M39.5 49.11c3.41 1.07 7.41.03 7.52-.07l.76 1.6c-1.51 1.33-5.32 3.05-8.92.1l.63-1.63h.01Z"/>
        <path id="Vector_29" fill="#F6B09E" d="M59.56 33.93s4.32-1.94 5.33-1.18c1.01.76.44 10.57-.44 11.99-.88 1.41-6.07 2.01-6.07 2.01s.77-6.03 1.18-12.82Z"/>
        <path id="Vector_30" fill="#FFF0DF" d="M41.41 28.63s-9.49-4.61-12.29 1.43c0 0 2.47 20.9 1.34 25.73 0 0-10.47-6.17-10.47-9.04 0-3.27 3.01-28.49 3.67-30.07.67-1.58 7.35-10.97 8.59-12.14 1.27-1.16 16.77-2.05 20.01-1.21 3.24.83 11.46 9.02 12.21 11.1s7.17 26.33 5.43 30.22c-1.75 3.88-13.91 12.02-13.91 12.02s1.18-25.37.92-26.76c-1.06-5.58-15.5-1.27-15.5-1.27v-.01Z"/>
      </g>
      case '514463' : return  <g id="Head 5" clipPath="url(#clip0_232_504)">
        <path id="Vector_19" fill="#453954" d="M56.64 7.81c.98-3.18-.8-6.56-3.98-7.54-3.18-.98-6.56.8-7.54 3.98-.98 3.18 3.3 9.72 3.3 9.72s7.23-2.98 8.21-6.16h.01Z"/>
        <path id="Vector_20" fill={`#${skin}`} d="M36.84 59.61s.5 11.48.58 12.31c.08.83 11.48 4.08 17.72-.5V56.45l-18.3 3.16Z"/>
        <path id="Vector_21" fill="#F6B09E" d="M30.39 38.86s-4.32-1.94-5.33-1.18c-1.01.76-.44 10.57.44 11.99.88 1.41 6.07 2.01 6.07 2.01s-.77-6.03-1.18-12.82Z"/>
        <path id="Vector_22" fill="#F5A27A" d="M60.19 38.86s4.32-1.94 5.33-1.18c1.01.76.44 10.57-.44 11.99-.88 1.41-6.07 2.01-6.07 2.01s.77-6.03 1.18-12.82Z"/>
        <path id="Vector_23" fill={`#${skin}`} d="M49.08 19.05s-17.88 5.44-18.3 6.19c-.42.75-.67 27.62 0 29.7.67 2.08 8.66 8.98 10.23 9.23 1.58.25 8.15.83 9.23 0s9.32-8.57 10.15-9.9c.83-1.33 1.33-18.24 1.16-19.68-.17-1.44-11.4-14.29-12.48-15.54h.01Z"/>
        <path id="Vector_24" fill="#4B2922" d="m48.23 40.83 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.14-7.65.15l-.01.01Z"/>
        <path id="Vector_25" fill="#4B2922" d="m31.6 41.15 1.37 1.35c.1-.1 2.44-2.42 4.99-.09l1.3-1.42c-3.11-2.85-6.37-1.15-7.65.15l-.01.01Z"/>
        <path id="Vector_26" fill="#4B2922" d="M36.8 40.65h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_27" fill="#4B2922" d="M53.4 40.65h-2.7v2.29h2.7v-2.29Z"/>
        <path id="Vector_28" fill={`#${skin}`} d="M44.03 43.75c-.92.08-3 3.79-3.02 4.91-.02 1.12 2.68 2.93 3.14 2.87.46-.06 2.68-2.24 2.69-2.81.01-.57-2.3-5.02-2.81-4.98v.01Z"/>
        <path id="Vector_29" fill="#514463" d="m48.25 34.26.48 2.25s4.42-.93 8.82.14l.03-1.87s-3.69-1.68-9.33-.52Z"/>
        <path id="Vector_30" fill="#514463" d="m30.72 35.06.18 1.87c4.3-1.18 8.8-.36 8.8-.36l.31-2.26c-5.72-1.01-9.28.76-9.28.76l-.01-.01Z"/>
        <path id="Vector_31" fill="#EE8585" d="M40.8 54.04c3.41 1.07 7.41.03 7.52-.07l.76 1.6c-1.51 1.33-5.32 3.05-8.92.1l.63-1.63h.01Z"/>
        <path id="Vector_32" fill="#514463" d="M43.93 21.62s-13.83 3.79-13.56 18c.35 18.6 6.73 56.25 4.99 61.77 0 0-11.15-6.43-13.18-15.33-.72-3.16 1.01-43.4 1.17-45.08.84-9.07 4.98-23.12 9.83-26.83 3.23-2.48 6.66-5.3 12.29-5.08 5.89.23 13.17 2.33 15.21 3.83 5.66 4.17 11.16 20.51 10.99 24.46-.21 5.01 1 44.63-.66 48.54-4.95 11.67-15.13 15.5-15.13 15.5s1.11-57.49 1.03-58.89C55.88 23.4 43.93 21.63 43.93 21.63v-.01Z"/>
      </g>
    }

  },[head, skin])
  return (
    <svg fill="none" viewBox="0 0 142 349">
      <g id="Female Option 1">
        <g id="Body Option 1">
          <path id="Vector" fill={`#${skin}`} d="M37.3 66.63s-18.06 6.04-19.12 6.77c-1.06.72 2.58 31.57 3.2 34.96.63 3.38 3.38 24.56 3.63 26.19.25 1.63 20.77 9.77 37.26 0 0 0 5.21-28.22 5.84-31.59.63-3.37 5.68-30.15 5.68-30.15l-18.71-7.3-17.79 1.13.01-.01Z"/>
          <path id="Vector_2" fill={`#${skin}`} d="M25.02 134.54s-5.87 20.04-6.84 25.22c-.97 5.18 22.49 15.49 27.24 15.37 6.71-.17 16.13-5.32 21.92-9.01 5.79-3.69 5-2.33 4.83-4.5-.17-2.17-8.98-27.47-10.03-28.87-1.05-1.39-27.27-13.42-37.13 1.78l.01.01Z"/>
          <path id="Vector_3" fill={`#${skin}`} d="M18.19 159.76s-2.18 16.71-2.68 25.89c-.5 9.19 9.52 55.46 10.02 60.64s4.01 13.7 10.19 13.03c6.18-.67 7.02-4.34 7.02-12.36s1.67-64.34 1.84-72.58c.17-8.24-19.69-28.65-26.38-14.62h-.01Z"/>
          <path id="Vector_4" fill={`#${skin}`} d="M26.4 250.42s.44 19.26 2.71 29.78c2.26 10.52 9.28 36.25 9.61 39.26.33 3.01 1.17 5.35 4.01 5.68 2.84.33 4.51-.33 4.34-4.84-.17-4.51-4.34-60.64-4.34-66.99 0-6.35-1.17-13.7-6.68-14.2s-10.06 1.92-9.65 11.31Z"/>
          <path id="Vector_5" fill={`#${skin}`} d="M69.9 153.96s4.73 17.33 6.26 26.4c1.53 9.07-3.21 56.18-3.12 61.38.09 5.2-2.44 14.06-8.66 14.1-6.22.03-7.46-3.52-8.37-11.49-.9-7.97-9.02-63.55-10.12-71.72-1.1-8.17 15.77-31.85 24-18.66l.01-.01Z"/>
          <path id="Vector_6" fill={`#${skin}`} d="M72.63 245.95s1.73 19.18.67 29.89-5.13 37.07-5.13 40.09c0 3.03-.56 5.44-3.34 6.1-2.78.65-4.52.18-4.86-4.32-.34-4.5-2.53-60.74-3.24-67.05-.71-6.31-.38-13.74 5.04-14.86 5.42-1.12 10.21.78 10.86 10.16v-.01Z"/>
          <path id="Vector_7" fill="#A096AD" d="M71.75 159.82c-2.21-7.9-7.07-20.2-7.82-22.09.18-.21.29-.48.28-.77-.03-.68-.55-1.25-1.25-1.25h-.08c-.44 0-.85.26-1.08.63-.19-.31-.53-.54-1.01-.54-.53 0-.89.28-1.07.64-.19-.32-.53-.56-1.02-.56-.71 0-1.1.48-1.19 1.02-.13-.47-.51-.86-1.15-.86-1.01 0-1.38.98-1.14 1.71h-.13c.2-.72-.18-1.63-1.15-1.63-.76 0-1.16.56-1.2 1.16-.13-.55-.64-1.01-1.22-.99-.55.03-1.02.37-1.19.88-.13-.48-.51-.88-1.15-.88-.74 0-1.13.52-1.2 1.09-.14-.46-.52-.84-1.14-.84-.69 0-1.08.45-1.18.97-.13-.49-.51-.89-1.16-.89-.92 0-1.31.81-1.19 1.51h-.14c.13-.7-.26-1.51-1.18-1.51-.6 0-.97.35-1.13.78-.15-.43-.53-.78-1.13-.78-.99 0-1.37.94-1.15 1.66h-.11c.17-.71-.21-1.58-1.17-1.58-.77 0-1.17.57-1.2 1.17-.13-.56-.6-1-1.22-1h-.08c-.55 0-1.08.41-1.22.95-.15-.43-.52-.78-1.12-.78-.91 0-1.3.79-1.19 1.48h-.07v-.14c0-.66-.58-1.28-1.25-1.25-.66.03-1.22.53-1.25 1.2-.11-.51-.5-.95-1.17-.95-.83 0-1.23.67-1.21 1.31h-.1c.07-.67-.33-1.39-1.2-1.39-.87 0-1.3.78-1.19 1.47h-.03c-5.21 11.37-6.81 30.92-6.81 30.92 4.66-.35 27.59 5.45 27.59 5.45h1.67c3.68-5.34 27.16-7.54 27.16-7.54s-.87-4.98-1.65-7.77l-.01-.01Z"/>
          <path id="Vector_8" fill={`#${skin}`} d="M38.93 320.82s-10.89 8.33-16.57 10.33-9.43 1-10.73 2.84c-1.3 1.84 3.38 4.01 10.06 3.51 6.68-.5 15.7-2.84 19.04-3.34s7.52 0 8.52-.83c1-.84-2.08-11.33-2.17-13.03-.09-1.7-2.17-2.84-4.34-2.84s-3.27 1.39-3.81 3.37v-.01Z"/>
          <path id="Vector_9" fill={`#${skin}`} d="M60.25 319.59s-.02 12.57 0 14.4c.02 1.84 4.7 4.18 9.04 4.34 4.34.17 9.52-.67 9.52-2s-5.18-6.37-7.85-11.12c-2.67-4.75-3.06-4.29-2.84-7.76.21-3.47-7.86-3.41-7.87 2.13v.01Z"/>
          <path id="Vector_10" fill={`#${skin}`} d="M15.24 75.05c3.44-3.49 7.31-.77 10.29 3.71 3.89 5.85-6.43 54.65-6.4 58.17.03 3.53-8.58 7.44-12.18 1.84-2.25-3.51 2.9-58.27 8.28-63.72h.01Z"/>
          <path id="Vector_11" fill={`#${skin}`} d="M12.63 197.21s.4 7.61 1.29 9.37c.89 1.77 0 1.84-.91 2.17-.91.33-4.06-6.56-4.06-6.56l3.68-4.99v.01Z"/>
          <path id="Vector_12" fill={`#${skin}`} d="M9.14 217.8c-.34.59-1.36.88-2.13.49-.77-.38-6.96-9.13-7-10.87-.02-.67.28-2.26.69-4.03v-.3c.16-1.88 1.84-7.06 2.68-8.46.84-1.4 3.54-2.42 4.73-2.39 1.06.03 3.21.49 4.14 1.9 1.07 1.63.51 4.94.51 4.94s-.67.12-1.54.35c-2.28 3.35-6.5 9.38-6.22 10.22.39 1.15 4.47 7.55 4.14 8.14v.01Z"/>
          <path id="Vector_13" fill="#A096AD" d="m62 90.31.69-21.78-1.59-.68s-.23 21.46-.48 22.09c0 .02-.03.05-.05.1-.19-.14-.43-.22-.72-.22-1.34 0-1.56 1.73-.67 2.31-.01.02-.02.03-.03.05-.2-.14-.44-.23-.71-.23h-.04c-.66 0-1.28.58-1.25 1.25.02.36.17.68.41.91-.47-.24-1.06-.2-1.47.2l-.04.04c-.46.46-.5 1.31 0 1.77.07.06.14.12.22.16 0 .01-.01.02-.02.03a1.19 1.19 0 0 0-.78-.29h-.04c-.66 0-1.28.58-1.25 1.25.02.45.26.86.63 1.08-.01.02-.02.03-.04.05-.2-.18-.46-.29-.8-.29-1.36 0-1.57 1.77-.64 2.33l-.01.01c-.2-.18-.46-.3-.81-.3-1.23 0-1.52 1.46-.87 2.15-.15-.07-.32-.11-.51-.11-1.21 0-1.51 1.41-.91 2.11-.16-.09-.36-.15-.59-.15-1.19 0-1.5 1.36-.93 2.08-.17-.1-.37-.16-.61-.16-1.23 0-1.52 1.46-.87 2.15-.15-.07-.31-.1-.51-.1-1.38 0-1.58 1.82-.6 2.36 0 .01-.01.02-.02.03-.19-.13-.42-.22-.71-.22-1.25 0-1.53 1.49-.85 2.17h-3.34c.28-.74-.09-1.75-1.12-1.75-.17 0-.33.03-.46.09.95-.55.74-2.34-.62-2.34-.28 0-.52.08-.7.21-.02-.03-.04-.06-.05-.1.99-.52.81-2.37-.58-2.37-.26 0-.48.07-.66.19-.02-.03-.04-.07-.06-.1.39-.21.67-.6.67-1.09v-.04c0-.66-.58-1.28-1.25-1.25-.25.01-.49.09-.69.22 0 0-.01-.02-.02-.03.99-.53.8-2.36-.59-2.36-.31 0-.56.1-.75.25-.01-.02-.02-.03-.03-.05.84-.61.61-2.29-.71-2.29-.3 0-.55.09-.74.23a.176.176 0 0 0-.04-.05c.97-.54.77-2.35-.61-2.35-.24 0-.44.06-.61.16.62-.7.33-2.12-.89-2.12-.32 0-.57.1-.76.25l-.03-.04c.35-.23.6-.61.58-1.05-.03-.68-.55-1.25-1.25-1.25h-.04c-.23 0-.45.07-.65.19.66-.69.38-2.16-.86-2.16-.12 0-.23.02-.33.04.24-.23.39-.54.38-.88-.03-.68-.55-1.25-1.25-1.25h-.04c-.19 0-.38.06-.56.14-.24-2.78-.51-18.82-.51-18.82l-1.42.5.6 19.1s-5.05 10.15-5.01 12.07c.02 1.05 1.75 11.1 2.76 11.89.68.53 14.78-.2 17.96-.28h3.72s0 .08.03.08c.75.25 21.17 1.02 21.92.44 1.01-.78 2.2-10.51 2.21-11.56.03-1.93-6.12-12.64-6.12-12.64l-.01.02Z"/>
          <path id="Vector_14" fill={`#${skin}`} d="M2.91 150.47c-.87 14.4.48 43.73 1.63 44.99 2.46 2.68 5.39.48 6.32-1.81 2.26-5.51 10.17-52.01 7.55-57.35-2.25-4.59-5.31-8.73-8.22-9.45-3.57-.89-6.38 8.72-7.28 23.62Z"/>
          <path id="Vector_15" fill={`#${skin}`} d="M76.166 73.589c-3.44-3.49-7.31-.77-10.29 3.71-3.89 5.85 6.43 54.65 6.4 58.17-.03 3.53 8.58 7.44 12.18 1.84 2.25-3.51-2.9-58.27-8.28-63.72h-.01Z"/>
          <path id="Vector_16" fill={`#${skin}`} d="M78.776 195.749s-.4 7.61-1.29 9.37c-.89 1.77 0 1.84.91 2.17.91.33 4.06-6.56 4.06-6.56l-3.68-4.99v.01Z"/>
          <path id="Vector_17" fill={`#${skin}`} d="M82.266 216.339c.34.59 1.36.88 2.13.49.77-.38 6.96-9.13 7-10.87.02-.67-.28-2.26-.69-4.03v-.3c-.16-1.88-1.84-7.06-2.68-8.46-.84-1.4-3.54-2.42-4.73-2.39-1.06.03-3.21.49-4.14 1.9-1.07 1.63-.51 4.94-.51 4.94s.67.12 1.54.35c2.28 3.35 6.5 9.38 6.22 10.22-.39 1.15-4.47 7.55-4.14 8.14v.01Z"/>
          <path id="Vector_18" fill={`#${skin}`} d="M88.496 149.009c.87 14.4-.48 43.73-1.63 44.99-2.46 2.68-5.39.48-6.32-1.81-2.26-5.51-10.17-52.01-7.55-57.35 2.25-4.59 5.31-8.73 8.22-9.45 3.57-.89 6.38 8.72 7.28 23.62Z"/>
        </g>
        <g id="Heads Option 1">
          {currentHead}
        </g>
      </g>
    </svg>


  );
}