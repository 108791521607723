import { Col } from 'react-bootstrap';
import React, { memo, useCallback } from 'react';
import classnames from 'classnames';

interface CardProps {
  card?: {
    image?: string,
    word?: string,
    id?:string
  } | undefined,
  isOpened: boolean,
  isFlipped: boolean,
  index:number
}

function MovieCard({ card, isFlipped, isOpened, index }: CardProps) {
  const openedClass = useCallback((str:string) => {
    let strArr = str.split(' ');
    return strArr.length === 1 ? 'yellow' : 'brown';
  }, [])
  return (
    <Col>
      <div
        className={classnames('card', 'memory-game', `card-${index}`, {
          'is-flipped': isFlipped || isOpened,
         // [openedClass(card?.word || "")] : isFlipped || isOpened
        })}
      >
        <div className='card-face card-front-face'></div>
        {card?.id && <div className={`card-face card-back-face`}>
          {
            card.word
              ? <p>{card.word}</p>
              : <img src={card.image} alt='pokeball' style={{ width: '90%', padding: '10px' }} />
          }
        </div>
        }
      </div>
    </Col>
  );
}

export default memo(MovieCard);