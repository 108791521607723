import 'src/assets/styles/word-search.scss';
import Grid from './grid';
import { useGameMechanic } from '../../../../hooks';
import GameNotFound from '../../../not-found';
import { Iword, TWordSearchGame } from './types';
import  { getRandomWords } from './ws-generator';
import { useState } from 'react';


const randomWords = getRandomWords().map((item: string, i: number) => { //TODO remove after implemented
  return { text: item, index: i, found: false };
});

function WordSearch() {
  const {
    gameMechanic,
    changeGameMechanicContent,
    changeCorrectAnswersPoints,
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const [content, setContent] = useState(gameMechanic.content);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    wordList = randomWords
  } = content as TWordSearchGame;


  const updateContent = (selectedWordsList: Iword[]) => {
    let isCorrect = true;

    changeGameMechanicContent({
      ...gameMechanic.content as TWordSearchGame,wordList:selectedWordsList?.map((selectedWord) => {
      if (!selectedWord.found) {
        isCorrect = false;
      }
      return selectedWord
    })});

    if (isCorrect && selectedWordsList?.length) {
      changeCorrectAnswersPoints(100);
    }
  };

  return (
    <div className='word-search'>
      <div className={isSubmitted ? 'no-events' : ''}>
      <Grid wordList={wordList} changeContent={updateContent} />
      </div>
    </div>);
}


export default WordSearch;