import { AvatarType } from '../../../redux/slices/user';
import { useMemo } from 'react';

export default function ManAvatar(avatar: AvatarType) {
  const { tops, bottoms, accessories, shoes, skin, head } = avatar;
  const currentTop = useMemo(() => {
    switch (tops) {
      case 'shirt-dark':
        return <g id='2'>
          <path id='Vector_48' fill='#3F6F69'
                d='M24.42 68.066s-5.511 3.453-8.7 13.494C12.51 91.6 7 113.578 7 113.578s11.987 6.307 27.4 3.153c0 0 5.098-22.775 6.14-28.724 1.064-5.968-2.814-21.178-16.12-19.92v-.02Z' />
          <path id='Vector_49' fill='#3F6F69'
                d='M96.58 67.168s5.511 3.453 8.7 13.494c3.209 10.04 8.72 32.017 8.72 32.017s-11.987 6.308-27.4 3.154c0 0-5.078-22.775-6.14-28.744-1.064-5.968 2.834-21.179 16.12-19.921Z' />
          <path id='Vector_50' fill='#4D7E79'
                d='M36.92 61.858s4.605 6.368 18.954 7.466C70.224 70.422 81.66 61 81.66 61l14.605 6.248s-1.653 36.01-3.602 42.876c-1.968 6.847.472 45.671 1.102 49.424.63 3.773-4.488 5.569-4.94 7.426-.473 1.836-29.25 6.547-32.124 7.026-2.874.479-23.758-6.507-24.998-7.026-1.24-.519-.61-7.865-.61-9.442s.452-13.514.295-17.745c-.158-4.232-2.618-35.312-3.543-41.74-.925-6.427-3.405-29.98-3.405-29.98l12.48-6.209Z' />
        </g>;
      case 'shirt':
        return <g id='shirt'>
          <g id='Clip path group'>
            <mask id='mask0_75_10' width='34' height='48' x='7' y='68' maskUnits='userSpaceOnUse'
                  style={{ maskType: 'luminance' }}>
              <g id='clippath'>
                <path id='Vector_48' fill='#fff'
                      d='M18.945 69.352c-.698.872-11.87 38.779-11.87 38.779l28.901 7.103s4.828-25.216 4.716-31.213c-.111-5.989-7.934-13.583-13.096-14.67-5.163-1.086-8.66 0-8.66 0h.009Z' />
              </g>
            </mask>
            <g mask='url(#mask0_75_10)'>
              <g id='Group'>
                <path id='Vector_49' fill='#F5EDE5'
                      d='M18.945 69.352c-.698.872-11.87 38.779-11.87 38.779l28.901 7.103s4.828-25.216 4.716-31.213c-.111-5.989-7.934-13.583-13.096-14.67-5.163-1.086-8.66 0-8.66 0h.009Z' />
                <path id='Vector_50' fill='#9FD3CB'
                      d='M34.135 70.206c.465-.409-3.172-1.764-5.144-1.81-1.628-.047-4.986 1.615-6.12 2.2l-.029-.214c-.083 0-.79.046-1.674.102 1.07-.51 3.219-1.495 4.586-1.931 1.879-.613 4.986-2.934 4.418-3.203-.483-.223-4.558.102-6.66.668 1.702-1.875 3.563-5.124 2.912-5.115-.735 0-6.68 3.435-7.963 5.013-1.153 1.43-1.209 4.262-1.209 4.79-.837.047-1.637.094-2.381.14.818-1.151 2.883-4.113 3.85-5.802 1.22-2.127 2.27-6.815 1.573-6.583-.698.232-5.274 5.348-5.99 7.242-.568 1.495-.103 4.122.13 5.162-1.47.093-2.698.186-3.489.26.791-1.087 2.726-3.937 3.126-6.5.511-3.24-.41-9.618-1.172-8.986-.763.631-4.056 9.2-4.056 11.92 0 1.904.856 3.11 1.368 3.659a1.75 1.75 0 0 0-.159.027c-4.455 1.133-7.85 5.274-7.99 5.45l.651.53s3.368-4.104 7.544-5.172c.13-.028.484-.074.995-.12-.297.807-1.041 3.193-.409 5.32.772 2.608 6.381 9.887 7.283 10.277.903.39-.018-5.988-1.441-8.95-1.21-2.516-4.205-5.756-5.08-6.666.54-.046 1.21-.093 1.954-.14.372.836 1.954 4.3 3.042 5.71 1.228 1.616 7.07 5.209 7.804 5.246.735.028-1.6-4.169-3.376-5.868-1.544-1.476-6.121-4.372-7.274-5.106 1.106-.074 2.344-.148 3.534-.213.233.5 1.712 3.509 3.247 4.558 1.674 1.151 8.352 2.748 9.06 2.544.66-.186-2.41-3.082-4.623-4.299 2.083.446 6.4.474 6.873.205.55-.307-2.725-2.396-4.641-2.86-1.005-.241-2.79-.483-4.27-.65l-.027-.195c.78.093 2.483.316 3.683.557 1.6.325 7.079-.78 7.544-1.197ZM21.4 67.866c-.456.984-.726 2.135-.837 2.655-1.07.056-2.14.121-3.163.177.54-.362 2.409-1.616 4-2.832Zm1.99 5.766c-2.111-.855-5.125-1.839-5.934-2.099 1.2-.074 2.344-.13 3.218-.185.112 0 .195-.01.298-.019.549.622 1.516 1.634 2.418 2.303Z' />
              </g>
            </g>
          </g>
          <g id='Clip path group_2'>
            <mask id='mask1_75_10' width='34' height='47' x='82' y='67' maskUnits='userSpaceOnUse'
                  style={{ maskType: 'luminance' }}>
              <g id='clippath-1'>
                <path id='Vector_51' fill='#fff'
                      d='M103.842 68.098c.697.873 11.869 38.78 11.869 38.78l-28.9 7.102s-4.828-25.215-4.717-31.213c.112-5.998 7.935-13.583 13.097-14.669 5.163-1.077 8.66 0 8.66 0h-.009Z' />
              </g>
            </mask>
            <g mask='url(#mask1_75_10)'>
              <g id='Group_2'>
                <path id='Vector_52' fill='#F5EDE5'
                      d='M103.842 68.098c.697.873 11.869 38.78 11.869 38.78l-28.9 7.102s-4.828-25.215-4.717-31.213c.112-5.998 7.935-13.583 13.097-14.669 5.163-1.077 8.66 0 8.66 0h-.009Z' />
                <path id='Vector_53' fill='#9FD3CB'
                      d='M96.196 70.15c1.2-.242 2.902-.464 3.683-.557l-.028.195c-1.478.167-3.264.399-4.27.65-1.915.464-5.19 2.553-4.64 2.86.474.269 4.79.24 6.873-.205-2.213 1.225-5.283 4.113-4.623 4.299.707.204 7.386-1.402 9.06-2.554 1.535-1.049 3.014-4.066 3.247-4.558 1.19.065 2.427.148 3.534.213-1.153.725-5.73 3.63-7.274 5.107-1.776 1.69-4.111 5.895-3.376 5.867.735-.028 6.576-3.63 7.804-5.245 1.079-1.411 2.66-4.884 3.042-5.71a89.39 89.39 0 0 1 1.953.14c-.874.91-3.87 4.15-5.079 6.665-1.414 2.962-2.344 9.34-1.442 8.95.903-.39 6.512-7.668 7.284-10.277.632-2.126-.112-4.503-.41-5.32.512.046.865.083.996.12 4.185 1.059 7.515 5.125 7.543 5.172l.652-.53c-.14-.176-3.535-4.317-7.991-5.45a1.734 1.734 0 0 0-.158-.027c.512-.557 1.377-1.755 1.377-3.658 0-2.72-3.302-11.29-4.056-11.92-.762-.632-1.683 5.746-1.172 8.986.41 2.563 2.344 5.422 3.135 6.5-.791-.075-2.019-.168-3.488-.26.223-1.05.697-3.677.13-5.172-.716-1.894-5.293-7-5.99-7.242-.698-.241.353 4.457 1.572 6.583.967 1.69 3.032 4.642 3.851 5.802a386.72 386.72 0 0 0-2.382-.139c0-.53-.055-3.36-1.209-4.79-1.274-1.579-7.218-5.005-7.962-5.014-.651 0 1.21 3.24 2.911 5.115-2.111-.566-6.176-.89-6.66-.668-.567.26 2.54 2.59 4.419 3.203 1.367.446 3.506 1.43 4.585 1.931a71.034 71.034 0 0 1-1.674-.102l-.028.214c-1.135-.585-4.483-2.238-6.12-2.2-1.972.055-5.619 1.401-5.144 1.81.465.418 5.944 1.513 7.543 1.197l-.018.019Zm9.181-.706c-1.024-.055-2.084-.12-3.163-.176-.112-.52-.381-1.662-.837-2.655 1.591 1.216 3.46 2.47 4 2.831Zm-3.563.632c.102 0 .186.009.298.018.874.047 2.018.112 3.218.186-.809.26-3.814 1.244-5.934 2.098.902-.66 1.869-1.68 2.418-2.302Z' />
              </g>
            </g>
          </g>
          <g id='Clip path group_3'>
            <mask id='mask2_75_10' width='86' height='117' x='18' y='58' maskUnits='userSpaceOnUse'
                  style={{ maskType: 'luminance' }}>
              <g id='clippath-2'>
                <path id='Vector_54' fill='#fff'
                      d='M43.51 58.935s-18.342 6.694-20.686 7.947c-2.344 1.253-3.888 2.479-3.888 2.479s4.623 45.381 4.623 49.559c0 4.178 2.362 51.657 2.502 51.239.14-.418 26.566 4.317 37.3 3.899 10.734-.417 33.216-2.785 33.216-2.785s-1.255-56.726-.558-60.904c.698-4.178 7.59-42.308 7.59-42.308L77.285 58.09l-20.12 28.938-13.654-28.093Z' />
              </g>
            </mask>
            <g mask='url(#mask2_75_10)'>
              <g id='Group_3'>
                <path id='Vector_55' fill='#FFF7F0'
                      d='M43.51 58.935s-18.342 6.694-20.686 7.947c-2.344 1.253-3.888 2.479-3.888 2.479s4.623 45.381 4.623 49.559c0 4.178 2.362 51.657 2.502 51.239.14-.418 26.566 4.317 37.3 3.899 10.734-.417 33.216-2.785 33.216-2.785s-1.255-56.726-.558-60.904c.698-4.178 7.59-42.308 7.59-42.308L77.285 58.09l-20.12 28.938-13.654-28.093Z' />
                <path id='Vector_56' fill='#9FD3CB'
                      d='M52.533 94.13s-.093-.018-.158-.027c.521-.557 1.377-1.755 1.377-3.658 0-2.72-3.302-11.29-4.056-11.92-.762-.632-1.683 5.746-1.171 8.986.409 2.563 2.343 5.422 3.134 6.5a94.782 94.782 0 0 0-3.255-.242c.148-.956.483-3.64-.103-5.18-.716-1.895-5.293-7.001-5.99-7.242-.698-.242.353 4.456 1.572 6.582.995 1.736 3.358 4.772 4.176 5.821-.837-.055-1.748-.111-2.706-.167 0-.529-.056-3.36-1.21-4.79-1.274-1.579-7.218-5.005-7.953-5.014-.65 0 1.21 3.24 2.912 5.116-2.112-.567-6.176-.892-6.66-.669-.568.26 2.54 2.59 4.418 3.194 1.367.446 3.507 1.43 4.586 1.931a70.19 70.19 0 0 1-1.674-.102l-.028.214c-1.135-.585-4.493-2.247-6.12-2.2-1.973.055-5.62 1.401-5.145 1.81.465.417 5.944 1.513 7.544 1.197 1.2-.241 2.902-.464 3.683-.557l-.028.195c-1.478.167-3.264.4-4.269.65-1.916.464-5.19 2.553-4.642 2.86.475.269 4.79.24 6.874-.205-2.213 1.226-5.283 4.113-4.622 4.299.706.204 7.385-1.402 9.06-2.544 1.534-1.05 3.013-4.067 3.246-4.559 1.19.065 2.427.149 3.534.214-1.162.724-5.73 3.63-7.274 5.106-1.776 1.69-4.111 5.896-3.376 5.868.735-.028 6.576-3.63 7.804-5.246 1.079-1.41 2.66-4.883 3.032-5.71.735.047 1.405.093 1.954.14-.875.91-3.87 4.15-5.079 6.666-1.414 2.962-2.344 9.34-1.442 8.95.902-.39 6.511-7.669 7.283-10.278.633-2.126-.111-4.503-.409-5.32.512.047.865.084.995.121 4.186 1.058 7.516 5.125 7.544 5.171l.651-.529c-.14-.176-3.534-4.317-7.99-5.45l-.019.019Zm-13.348 2.387c.903-.66 1.87-1.68 2.419-2.302.102 0 .186.009.298.018.874.046 2.018.111 3.218.186-.81.26-3.814 1.244-5.934 2.098Zm2.828-3.12c-.111-.52-.381-1.661-.837-2.655 1.59 1.216 3.46 2.47 4 2.832-1.023-.056-2.084-.12-3.163-.177Z' />
                <path id='Vector_57' fill='#9FD3CB'
                      d='M87.164 79.74c2.028.103 8.558-2.014 9.05-2.553.466-.51-3.664-1.364-6.195-1.253 2.01-.706 5.703-2.943 5.972-3.417.307-.547-3.581-.612-5.46-.009-.986.316-2.632 1.05-3.972 1.68l-.13-.157c.716-.325 2.279-1.03 3.432-1.449 1.535-.566 5.619-4.363 5.805-4.957.186-.595-3.628.157-5.34 1.142-1.413.817-3.404 3.983-4.055 5.069l-.13-.167c-.065.055-.642.455-1.377.956.651-.993 1.963-2.953 2.893-4.039 1.284-1.504 2.707-5.106 2.083-5.032-.53.065-3.832 2.46-5.33 4.048.466-2.488.345-6.22-.204-5.877-.623.39-3.888 6.416-4.158 8.43-.233 1.82 1.2 4.262 1.479 4.717-.837.585-1.628 1.132-2.353 1.643.167-1.17.697-5.153.632-7.232-.074-2.451-1.646-6.991-2.111-6.425-.465.566-1.693 7.307-1.312 9.303.316 1.68 2.047 3.862 2.577 4.502a90.111 90.111 0 0 0-2.595 1.876c.102-1.337.25-4.782-.745-7.177-1.255-3.026-5.395-7.984-5.71-7.046-.317.928 1.367 9.961 2.79 12.273.995 1.625 2.353 2.2 3.088 2.405-.047.046-.093.083-.121.111-3.21 3.296-3.925 8.597-3.953 8.82l.828.112c0-.056.716-5.255 3.72-8.347.093-.093.372-.316.782-.622.176.845.79 3.268 2.437 4.744 2.027 1.82 10.622 5.097 11.59 4.949.976-.149-3.163-5.097-5.916-6.88-2.344-1.513-6.605-2.71-7.823-3.036.437-.325.986-.715 1.59-1.142.754.51 3.926 2.637 5.582 3.278 1.897.733 8.762.752 9.404.39.641-.362-3.554-2.72-5.954-3.231-2.083-.446-7.506-.53-8.873-.548.902-.64 1.925-1.356 2.902-2.033.465.297 3.302 2.098 5.162 2.19l.019-.009Zm-2.372-4.122c.084-.065.158-.111.242-.167.79.242 2.148.604 3.265.697-2.251.37-5.33 1.104-6.158 1.309.986-.688 1.925-1.337 2.642-1.839h.009Zm-1.21-3.342c.13 1.077.494 2.2.68 2.692-.884.613-1.758 1.217-2.596 1.801.27-.584 1.21-2.636 1.926-4.502l-.01.009Z' />
                <path id='Vector_58' fill='#9FD3CB'
                      d='M88.261 129.067c-1.823-.91-6.51-.789-8.101-.724l.083-.251c-.102-.037-.92-.362-1.953-.77 1.488-.009 4.437 0 6.223.213 2.456.297 7.209-.705 6.706-1.299-.427-.511-5.218-2.284-7.915-2.749 2.92-1.234 6.743-3.936 6-4.27-.838-.381-9.395.38-11.684 1.495-2.065 1.011-3.627 4.196-3.906 4.799-1.098-.436-2.15-.844-3.117-1.225 1.266-.65 5.637-2.925 7.767-4.429 2.503-1.773 6.177-6.545 5.256-6.638-.912-.102-8.809 3.287-10.632 5.06-1.544 1.504-2.446 4.884-2.688 5.896-1.674-.65-3.07-1.18-3.99-1.505 1.47-.817 5.171-3.035 6.994-5.728 2.298-3.407 4.614-11.132 3.423-10.816-1.19.316-9.469 8.3-10.901 11.392-1.005 2.163-.67 3.973-.382 4.874-.083-.019-.148-.037-.195-.046-5.655-1.059-11.702 1.856-11.953 1.977l.465.938c.056-.028 5.99-2.888 11.293-1.894.158.028.586.176 1.19.39-.763.761-2.865 3.082-3.274 5.821-.502 3.361 2.01 14.576 2.837 15.495.828.919 3.144-6.814 3.098-10.918-.038-3.491-1.73-8.746-2.242-10.24.642.232 1.423.538 2.288.863-.018 1.142-.046 5.905.428 8.077.549 2.479 5.274 9.637 6.093 10.064.818.418.381-5.58-.735-8.43-.977-2.488-4.632-8.188-5.563-9.618a730.42 730.42 0 0 1 4.13 1.615c0 .687.093 4.884 1.275 6.889 1.293 2.182 8.027 7.52 8.93 7.66.855.139-1.108-4.763-2.977-7.316 2.13 1.606 7.013 3.908 7.692 3.853.782-.056-1.832-4.15-3.758-5.692-1.013-.807-2.92-2.014-4.502-2.98l.075-.241c.837.52 2.65 1.671 3.888 2.571 1.646 1.207 8.446 2.841 9.2 2.619.753-.223-2.67-3.668-4.884-4.772l.019-.01Zm-8.334-4.595c-1.042.882-1.953 2.033-2.353 2.571-1.247-.492-2.493-.984-3.684-1.457.8-.121 3.59-.567 6.028-1.105l.01-.009Zm-6.409 2.079c1.395.557 2.726 1.077 3.749 1.486.12.046.232.093.344.139.298.993.856 2.655 1.535 3.881-1.944-2.089-4.837-4.782-5.618-5.506h-.01Z' />
                <path id='Vector_59' fill='#9FD3CB'
                      d='M107.73 169.314c-1.823-.91-6.511-.789-8.102-.724l.084-.251c-.102-.037-.921-.362-1.954-.77 1.489 0 4.437 0 6.223.213 2.456.297 7.209-.705 6.707-1.299-.428-.511-5.218-2.284-7.916-2.749 2.921-1.234 6.744-3.936 6-4.27-.837-.381-9.395.38-11.683 1.494-2.065 1.012-3.628 4.197-3.907 4.8-1.107-.436-2.167-.854-3.144-1.234.977-.493 5.563-2.841 7.795-4.41 2.502-1.774 6.176-6.546 5.255-6.639-.911-.102-8.808 3.287-10.631 5.06-1.619 1.569-2.428 5.144-2.586 5.933-1.721-.669-3.154-1.216-4.093-1.541 1.47-.817 5.172-3.036 6.985-5.729 2.298-3.407 4.614-11.131 3.423-10.816-1.19.316-9.469 8.3-10.901 11.392-1.005 2.163-.67 3.974-.382 4.874-.083-.018-.148-.037-.195-.046-5.655-1.059-11.701 1.857-11.953 1.977l.466.938c.055-.028 5.99-2.887 11.292-1.894.158.028.586.176 1.19.39-.762.761-2.864 3.082-3.274 5.821-.502 3.361 2.01 14.576 2.837 15.495.828.92 3.144-6.805 3.098-10.918-.037-3.491-1.73-8.746-2.242-10.24.642.232 1.423.538 2.288.863-.018 1.142-.046 5.905.428 8.077.549 2.479 5.274 9.637 6.093 10.064.818.418.381-5.579-.735-8.43-.977-2.488-4.632-8.188-5.563-9.618a730.42 730.42 0 0 1 4.13 1.615c0 .687.093 4.884 1.275 6.889 1.293 2.182 8.027 7.52 8.93 7.66.855.139-1.107-4.763-2.977-7.316 2.13 1.606 7.013 3.908 7.692 3.853.782-.056-1.823-4.15-3.757-5.691-1.014-.808-2.921-2.015-4.502-2.981l.074-.241c.837.52 2.651 1.671 3.888 2.572 1.646 1.207 8.446 2.841 9.199 2.618.754-.223-2.669-3.667-4.883-4.772l.028-.019Zm-8.334-4.595c-1.042.882-1.954 2.033-2.354 2.571-1.246-.492-2.493-.984-3.683-1.457.8-.121 3.59-.567 6.027-1.105l.01-.009Zm-6.41 2.079c1.396.557 2.726 1.077 3.75 1.486.12.046.232.092.343.139.298.993.856 2.655 1.535 3.881-1.944-2.089-4.846-4.782-5.618-5.506h-.01Z' />
                <path id='Vector_60' fill='#9FD3CB'
                      d='M49.51 160.309c2.95-1.709 10.167-10.659 10.372-11.875.205-1.216-7.293 1.792-10.483 4.391-2.716 2.21-5.777 6.806-6.632 8.133a111.29 111.29 0 0 1-.744-2.33c.902-.696 4.66-3.649 6.064-5.375 1.6-1.969 4.26-10.129 4.084-11.03-.177-.901-4.614 3.175-6.149 5.83-1.34 2.312-3.525 8.718-4.065 10.343-.418-1.318-.874-2.804-1.311-4.224.54-.437 3.767-3.12 4.595-5.292.902-2.368.884-10.946.428-11.745-.428-.752-3.042 3.835-3.879 6.88-.074-2.665-1.312-7.91-1.777-8.412-.53-.575-2.111 4.011-2.111 6.481 0 1.29.242 3.537.474 5.375l-.232.093c-.112-.975-.354-3.11-.41-4.633-.083-2.042-3.041-8.365-3.683-8.81-.642-.446-1.21 4.372-.688 6.786.428 1.987 3.441 5.58 4.483 6.778l-.251.093c.037.102.288.937.614 2.005-.93-1.151-2.763-3.463-3.712-4.995-1.302-2.098-5.05-5.18-5.2-4.419-.13.65 1.47 5.496 2.782 7.891-2.79-1.513-7.293-2.813-7.088-2.024.223.892 6.158 7.094 8.455 8.18 2.084.984 5.553.213 6.195.056.382 1.253.754 2.441 1.089 3.518-1.126-.566-5.833-2.887-8.41-3.63-2.948-.854-8.975-.733-8.482.037.493.78 8.074 4.819 10.594 5.134 2.205.279 5.544-1.012 6.363-1.346.502 1.606.93 2.943 1.237 3.816-1.553-.641-5.6-2.145-8.846-1.875-4.102.334-11.6 3.342-10.604 4.075.986.734 12.408 2.21 15.72 1.402 2.325-.566 3.535-1.959 4.055-2.748.028.074.066.139.084.186 2.698 5.078 8.753 7.975 9.004 8.096l.447-.947c-.056-.028-6-2.869-8.53-7.632-.074-.149-.233-.566-.437-1.17 1.079.121 4.195.316 6.595-1.077l-.01.01Zm-14.566-10.046c1.34.26 2.818.251 3.479.232l1.153 3.779c-.595-.548-2.679-2.451-4.632-4.02v.009Zm4.456-.148a7.832 7.832 0 0 0-.112-.353c.595-.854 1.544-2.321 2.083-3.621-.418 2.822-.725 6.759-.809 7.817-.437-1.439-.856-2.804-1.172-3.853l.01.01Z' />
              </g>
            </g>
          </g>
          <path id='Vector_61' fill='#F5EDE5'
                d='M44.757 55.212c.4-1.022-13.087 7.325-13.087 7.325l10.12 5.088s-3.386 6.136-3.311 6.554c.074.418 7.125 3.909 10.39 5.998 3.264 2.089 8.306 6.86 8.306 6.86s-3.004-6.48-5.516-13.173c-2.511-6.694-7.376-17.399-6.883-18.652h-.019Z' />
          <path id='Vector_62' fill='#F5EDE5'
                d='M72.57 52.315c-.4-1.021 16.491 10.064 16.491 10.064l-12.148 4.364s4.195 7.25 4.121 7.668c-.074.418-10.027 5.57-13.283 7.67-3.265 2.088-10.576 4.957-10.576 4.957s5.925-9.934 8.437-16.619c2.511-6.694 7.45-16.841 6.967-18.095l-.01-.009Z' />
        </g>;
      default: return null;
    }
  }, [tops]);
  const currentBottom = useMemo(() => {
    switch (bottoms) {
      case 'Jeans':
      case 'jeans':
        return <g id='Jeans'>
          <g id='JEANS INDIGO'>
            <path id='Vector_67' fill='#2A3447'
                  d='M32.867 141.574s-4.627 21.9-3.18 40.428c.669 8.567 7.298 8.68 28.345 8.853 21.046.173 30.891-5.399 31.943-8.608 1.052-3.209-2.32-21.917-3.544-26.928-1.226-5.011-3.24-13.751-3.24-13.751s-28.262 4.021-50.33 0l.006.006Z' />
            <path id='Vector_68' fill='#2A3447'
                  d='M29.914 175.405s-.927 3.961.878 28.312c1.805 24.351 2.738 46.662 4.1 50.951 1.364 4.283 17.802 16.256 23.26 3.866 1.14-2.589 2.534-67.684 2.534-67.684S48.7 161.822 29.914 175.405Z' />
            <path id='Vector_69' fill='#2A3447'
                  d='M34.755 251.202c-.12.501-.012 29.094.831 40.703a1533.67 1533.67 0 0 1 1.387 21.135l17.73 1.605s3.34-52.395 3.735-57.972c.998-14.306-20.198-19.758-23.683-5.471Z' />
            <path id='Vector_70' fill='#2A3447'
                  d='M88.552 172.046s1.626 3.735 3.975 28.05c2.356 24.315 5.237 46.465 4.585 50.933-.651 4.462-15.368 19.209-23.067 7.988-1.608-2.35-13.94-67.744-13.94-67.744s6.935-29.237 28.447-19.227Z' />
            <path id='Vector_71' fill='#2A3447'
                  d='M97.411 247.587c.18.483 3.27 28.909 3.736 40.542.46 11.632.992 21.159.992 21.159l-17.436 3.573s-9.193-51.696-10.204-57.191c-2.594-14.102 17.855-21.887 22.918-8.077l-.006-.006Z' />
            <path id='Vector_72' fill='#1E1E1E'
                  d='M35.969 309.926s.15 4.343.382 4.88c.233.537 18.59 1.563 18.817 1.05.228-.513.514-4.432.287-5.106-.22-.668-19.104-1.79-19.486-.83v.006Z' />
            <path id='Vector_73' fill='#1E1E1E'
                  d='M103 308.769s-.03 4.343-.245 4.886c-.215.543-18.542 2.088-18.781 1.587-.24-.507-.634-4.421-.43-5.095.209-.674 19.044-2.326 19.456-1.378Z' />
            <path id='Vector_74' fill='#1E1E1E'
                  d='M86.879 156.834c-4.172 0-12.523-.232-13.934-1.795-1.398-1.551-2.857-7.523-3.132-8.704l2.05-.411c.646 2.75 1.913 7.003 2.702 7.88.777.65 7.107 1.205 11.865 1.205l.449 1.825Z' />
            <path id='Vector_75' fill='#1E1E1E'
                  d='m31.342 157.306.383-2.124c4.758 0 10.79-.459 11.584-1.187.771-.931 2.038-5.554 2.684-8.537l2.05.442c-.275 1.282-1.733 7.761-3.132 9.449-1.41 1.7-9.397 1.951-13.569 1.951v.006Z' />
            <path id='Vector_76' fill='#1E1E1E'
                  d='M56.663 143.358s-.09 37.452 0 39.808c.09 2.362 1.369 7.69 1.369 7.69h1.733s-.915-4.63-.915-7.779c0-3.144.544-39.725.544-39.725h-2.737l.006.006Z' />
            <path id='Vector_77' fill='#373E45'
                  d='M60.076 147.606c0-1.062-.86-1.921-1.925-1.921a1.922 1.922 0 1 0 0 3.842 1.922 1.922 0 0 0 1.925-1.921Z' />
          </g>
        </g>
      case 'trousers':
        return <g id='trousers'>
          <path id='Vector_63' fill='#F7C897'
                d='m63.621 219.077 7.814 40.609 31.216-.761s-1.144-72.287-9.813-72.547c-36.733-1.104-29.217 32.699-29.217 32.699Z' />
          <path id='Vector_64' fill='#F7C897'
                d='M31.186 196.313c-6.279 2.135.326 54.581.967 63.559l30.9-1.012.559-39.773s-.28-33.711-32.426-22.774Z' />
          <path id='Vector_65' fill='#F7C897'
                d='M31.354 166.009s-2.828 30.842-2.614 40.665c.214 9.832 18.157 15.671 35.272 15.059 30.286-1.087 35.681-15.199 33.272-28.8-2.418-13.601-6.483-27.778-6.483-27.778s-31.84 9.182-59.448.845v.009Z' />
          <path id='Vector_66' fill='#DAB282'
                d='M31.716 262.583s-1.628-6.09 0-6.406c1.637-.325 31.31 0 31.31 0s1.228 5.106-.958 5.933c-2.186.826-24.473 2.079-30.342.482l-.01-.009Z' />
          <path id='Vector_67' fill='#DAB282'
                d='M71.072 262.583s-1.758-6.09 0-6.406c1.758-.325 33.672 0 33.672 0s1.321 5.106-1.032 5.933c-2.354.826-26.324 2.079-32.63.482l-.01-.009Z' />
          <path id='Vector_68' fill='#fff'
                d='M65.751 171.255a2.252 2.252 0 1 0-4.502 0 2.259 2.259 0 0 0 2.251 2.256 2.259 2.259 0 0 0 2.251-2.256Z' />
          <path id='Vector_69' fill='#DAB282'
                d='M62.989 219.207c-.019-.083-1.833-9.005-2.214-15.588-.381-6.554-1.312-34.453-1.321-34.732l1.284-.046c.009.278.94 28.168 1.32 34.704.382 6.499 2.177 15.319 2.196 15.402l-1.265.26Z' />
          <path id='Vector_70' fill='#DAB282'
                d='M56.403 176.426c-.67 0-1.256-.019-1.73-.065-7.125-.641-23.692-4.392-23.84-4.429l.14-1.624c.139.037 16.77 4.14 23.812 4.772 7.004.631 37.197-3.928 37.495-3.965l.195 1.272c-1.162.177-26.817 4.048-36.072 4.048v-.009Z' />
        </g>;
      default:
        return null;
    }
  }, [bottoms]);
  const currentShoes = useMemo(() => {
    switch (tops) {

      case 'shoes':
      default :
        return <g id='shoes'>
          <path id='Vector_71' fill='#EDD66B'
                d='M90.717 313.386s.326 9.563-.158 15.616c-.632 7.938-3.32 5.106-.26 13.796.66 1.866 9.952 6.508 15.189 5.617 5.246-.891 10.176-6.517 10.028-8.003-.41-3.881-3.898-6.619-6.344-10.231-2.986-4.391-3.712-13.638-5.023-16.99 0 0-7.758-1.541-13.432.204v-.009Z' />
          <path id='Vector_72' fill='#EEEFF1'
                d='M88.847 335.482s-.492 7.576.93 10.751c1.638 3.668 9.181 7.53 18.892 5.469 6.726-1.43 7.591-3.194 8.167-6.36.949-5.227-4.502-11.132-4.502-11.132s3.712 3.881 2.484 6.434c-1.228 2.554-4.995 4.234-9.665 4.893-4.297.594-10.24-.975-12.222-2.794-1.925-1.774-4.344-3.501-4.093-7.261h.01Z' />
          <path id='Vector_73' fill='#EEEFF1'
                d='M94.14 334.006c-.484 1.3 1.535 2.052 2.019.752.483-1.3-1.535-2.052-2.019-.752Z' />
          <path id='Vector_74' fill='#EEEFF1'
                d='M94.205 328.798c-.483 1.3 1.535 2.052 2.019.752.483-1.3-1.535-2.052-2.019-.752Z' />
          <path id='Vector_75' fill='#EEEFF1'
                d='M94.038 323.645c-.484 1.3 1.544 2.052 2.018.743.484-1.3-1.534-2.052-2.018-.743Z' />
          <path id='Vector_76' fill='#EEEFF1'
                d='M93.536 318.427c-.484 1.3 1.534 2.052 2.018.752.484-1.299-1.544-2.051-2.018-.752Z' />
          <path id='Vector_77' fill='#EEEFF1'
                d='M105.395 333.449c-.083 1.383 2.065 1.523 2.158.139.084-1.383-2.065-1.522-2.158-.139Z' />
          <path id='Vector_78' fill='#EEEFF1'
                d='M103.963 328.445c-.093 1.383 2.065 1.523 2.148.139.084-1.383-2.064-1.522-2.148-.139Z' />
          <path id='Vector_79' fill='#EEEFF1'
                d='M102.316 323.552c-.093 1.384 2.065 1.523 2.149.139.093-1.383-2.065-1.522-2.149-.139Z' />
          <path id='Vector_80' fill='#EEEFF1'
                d='M100.326 318.706c-.093 1.383 2.065 1.522 2.149.139.093-1.383-2.065-1.522-2.149-.139Z' />
          <path id='Vector_81' fill='#373333'
                d='M114.874 338.203c-.196-.882-1.563-.511-1.358.371 1.116 4.902-8.418 6.555-11.683 6.276-3.926-.334-7.786-2.767-10.93-5.013-.735-.53-1.442.687-.707 1.216 4.837 3.444 9.916 5.96 15.906 4.818 3.758-.715 9.879-2.738 8.762-7.668h.01Z' />
          <path id='Vector_82' fill='#EEEFF1'
                d='M90.531 339.707s21.915-1.764 23.04-1.931c1.126-.158.717 3.537-2.334 5.087-3.051 1.541-8.809 3.055-13.18 1.402-4.382-1.643-7.517-4.558-7.517-4.558h-.009Z' />
          <path id='Vector_83' fill='#EDD66B'
                d='M38.311 320.572s1.172 3.045-1.088 4.484c-5.042 3.203-13.478 7.845-18.771 9.693-6.056 2.117-9.58 3.212-10.427 4.809-.847 1.606 3.237 3.64 11.125 3.695 9.227.065 22.37-1.086 26.91-1.086 4.539 0 7.413.167 8.176-.251.763-.417-1.098-21.01-1.098-21.01-9.525 2.071-14.836-.343-14.836-.343l.01.009Z' />
          <path id='Vector_84' fill='#373333'
                d='M15.96 339.354c-2.41-.307-4.82-.353-7.247-.372-.781 0-.781 1.198 0 1.207 2.428.019 4.828.065 7.246.363.772.092.763-1.115 0-1.207v.009Z' />
          <path id='Vector_85' fill='#EEEFF1'
                d='M8.025 339.549s9.72.678 16.343.325c6.623-.353 28.854-2.043 30.137-1.968 0 0 .056 3.722 0 4.781-.055 1.058-19.989 1.755-27.105 1.968-7.116.214-18.361.26-18.631-.566-1.367-4.057-.735-4.54-.735-4.54h-.01Z' />
          <path id='Vector_86' fill='#EEEFF1'
                d='M22.628 333.096c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_87' fill='#EEEFF1'
                d='M27.772 331.267c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_88' fill='#EEEFF1'
                d='M32.767 329.225c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_89' fill='#EEEFF1'
                d='M37.697 326.839c-1.45 0-1.45 2.247 0 2.247 1.451 0 1.451-2.247 0-2.247Z' />
          <path id='Vector_90' fill='#373333' d='m54.492 340.075-30.372 1.641.049.909 30.372-1.642-.05-.908Z' />
          <path id='Vector_91' fill='#CBCACA' d='M24.33 339.911h-.623v4.828h.624v-4.828Z' />
          <path id='Vector_92' fill='#EEEFF1'
                d='M13.996 336.299s-3.925.873-5.72 2.878c0 0 7.479.288 8.055.288 0 0-.437-2.042-2.335-3.166Z' />
        </g>;
    }
  }, [shoes]);
  const currentHead = useMemo(() => {
    switch (head) {
      case 'E77D2E':
        return <g id='Head 5'>
          <path id='Vector_38'fill={`#${skin}`}
                d='M46.99 47.6c.09.26-.6 13.76-.6 13.76s12.89 5.43 22.86 0l-1.12-14.45-21.14.69Z' />

          <path id='Vector_39'fill={`#${skin}`}
                d='M44.46 29.61s-3.41-1.93-4.42-1.31c-1.01.63-1.32 9.88-.62 11.28.7 1.4 4.69 1.79 4.69 1.79s1.05-4.18.35-11.77v.01Z' />

          <path id='Vector_40' fill={`#${skin}`}
                d='M41.86 18.37c-1.33 6.3 1 24.48 1.54 25.73.63 1.47 6.99 10.75 9.73 10.72 1.81-.02 3.4.05 5.2.03 2.43-.03 11.79-9.34 12.15-10.44.4-1.21 3.98-19.18 2.15-24.89-.98-3.06-4.68-9.08-7.64-10.35-3.97-1.7-13.62-1.97-17.25.35-2.24 1.43-5.33 6.25-5.88 8.85Z' />
          <path id='Vector_41' fill='#D4A87F' d='m58.17 26.47.45 2.32s3.97-1 7.93.06v-1.93s-3.32-1.7-8.38-.45Z' />
          <path id='Vector_42' fill='#D4A87F'
                d='m42.4 27.46.17 1.93c3.87-1.26 7.91-.46 7.91-.46l.26-2.34c-5.15-.99-8.34.87-8.34.87Z' />

          <path id='Vector_43' fill='#2A3447'
                d='M64.13 32.24c-2.14-2.03-4.18-.09-4.27 0l-1.15-1.18c1.12-1.09 3.93-2.5 6.55 0l-1.13 1.19v-.01Z' />
          <path id='Vector_44' fill='#2A3447'
                d='M49.59 32.53c-2.25-1.91-4.18.15-4.26.24l-1.21-1.11c1.06-1.15 3.78-2.71 6.53-.38l-1.06 1.25Z' />
          <path id='Vector_45'fill={`#${skin}`}
                d='M54.02 33.57c-.98.09-3.12 3.76-3.12 4.85 0 1.09 2.63 2.95 3.12 2.88.49-.07 3.79-2.33 3.79-2.88 0-.55-3.24-4.91-3.79-4.85Z' />

          <path id='Vector_46' fill='#2A3447' d='M62.99 31.03h-2v1.54h2v-1.54Z' />
          <path id='Vector_47' fill='#2A3447' d='M48.38 31.47h-2v1.54h2v-1.54Z' />
          <path id='Vector_48'fill={`#${skin}`}
                d='M70.88 29.65s3.41-1.93 4.42-1.31c1.01.63 1.32 9.88.62 11.28-.7 1.4-5.93 1.71-5.93 1.71s.19-4.1.89-11.69v.01Z' />
          <path id='Vector_49' fill='#DB6768'
                d='M52.52 44.1c2.46 2.28 4.75.04 4.85-.06l1.32 1.32c-1.26 1.26-4.43 2.89-7.44.11l1.27-1.37Z' />
          <path id='Vector_50' fill='#B9937E'
                d='M37 4.94s.22 15.21 5.04 15.53c.63.04 19.23 1.2 25.41-3.21 2.17-1.55 2.4-.51 2.6.26.22.83 2 17.89 2.6 18.97.3.55 2.75-5.87 2.96-6.64.79-2.89 1.33-6.86 1.65-10.62.49-5.88-.91-13.1-5.1-15.23-4.19-2.12-12.82-2.31-15.38-1.69C46.36 4.8 42.56 6.48 37 4.94Z' />
        </g>;
      case '4b2922' :
        return <g id='Head 1'>
          <path id='Vector_38'fill={`#${skin}`}
                d='M46.49 47.96c.1.28-.19 14.87-.19 14.87s14.09 5.43 24.67-.76l-1.54-16.5-22.94 2.39Z' />
          <path id='Vector_39'fill={`#${skin}`}
                d='M73.57 28.85s2.86-1.84 3.59-1.09.08 10.72-.6 12.18c-.68 1.46-4.63 1.53-4.63 1.53s.36-4.5 1.64-12.61v-.01Z' />
          <path id='Vector_40'fill={`#${skin}`}
                d='M41.26 29.31s-3.99-1.41-4.95-.55c-.96.86.46 10.76 1.47 12.11 1.01 1.36 5.33 1.02 5.33 1.02s.32-4.65-1.85-12.58Z' />
          <path id='Vector_41' fill={`#${skin}`}
                d='M40.92 16.56c-1.44 6.81 1.08 26.43 1.66 27.78.68 1.58 7.55 11.61 10.51 11.58 1.95-.02 3.67.06 5.62.03 2.63-.03 12.73-10.08 13.12-11.28.43-1.31 4.3-20.71 2.33-26.87-1.06-3.31-5.06-9.81-8.25-11.18-4.28-1.84-14.7-2.13-18.63.38-2.42 1.55-5.76 6.75-6.35 9.56h-.01Z' />
          <path id='Vector_42' fill='#EE8585'
                d='M53.08 45.49c2.25 1.95 4.22-.1 4.3-.19l1.21 1.13c-1.08 1.15-3.85 2.69-6.59.3l1.09-1.25-.01.01Z' />
          <path id='Vector_43' fill='#6A4040' d='m58.7 25.5.48 2.51s4.29-1.08 8.56.06l.02-2.08s-3.6-1.83-9.06-.49Z' />
          <path id='Vector_44' fill='#6A4040'
                d='m41.67 26.58.18 2.08c4.17-1.36 8.54-.5 8.54-.5l.28-2.53c-5.57-1.07-9.01.94-9.01.94l.01.01Z' />
          <path id='Vector_45' fill='#2A3447'
                d='M65.09 33.04c-2.04-2.17-4.2-.33-4.3-.25l-1.09-1.25c1.19-1.03 4.1-2.29 6.59.37l-1.21 1.13h.01Z' />
          <path id='Vector_46' fill='#F6B093'
                d='M55.31 33.89c-1.26.11-4.01 4.3-4.01 5.56 0 1.26 3.38 3.38 4.01 3.3.63-.08 3.58-2.71 3.58-3.34 0-.63-2.87-5.58-3.58-5.52Z' />
          <path id='Vector_47' fill='#2A3447' d='M64.07 31.68h-2.02v1.55h2.02v-1.55Z' />
          <path id='Vector_48' fill='#2A3447'
                d='M49.27 33.04c-2.04-2.17-4.2-.33-4.3-.25l-1.09-1.25c1.19-1.03 4.1-2.29 6.59.37l-1.21 1.13h.01Z' />
          <path id='Vector_49' fill='#2A3447' d='M48.25 31.68h-2.02v1.55h2.02v-1.55Z' />
          <path id='Vector_50' fill='#4D332E'
                d='M40.58 29.96s.9-6.86.72-9.59c-.18-2.73 2.33-6.36 3.06-7.36s2.47-1.45 4.24 1.23c2.25 3.41 7.17 1.95 9.82.95 2.65-1 1.84-4.02 8.98-3.64 1.63.09 2.67 1.66 3.22 4.59.47 2.52.05 4.14.5 7.32.45 3.18 1.56 7.2 1.56 7.2s1.19-3.24 1.72-4.36.92-9.21 0-11.84c-.92-2.64-4.8-8.35-7.76-9.82-1.35-.66-14.47-1.12-17.2.24-4.32 2.16-7.6 8.39-8.41 9.75-.81 1.36-1.01 7.37-.87 10.37.14 2.99.42 4.96.42 4.96Z' />
        </g>;
      case 'ac7f69' :
        return <g id='Head 3'>
          <path id='Vector_38'fill={`#${skin}`}
                d='M47.12 47.46c.1.26-.19 14.28-.19 14.28s13.53 5.21 23.68-.73l-1.47-15.84-22.02 2.29Z' />
          <path id='Vector_39'fill={`#${skin}`}
                d='M43.75 27.78s-3.53-2.01-4.58-1.35c-1.05.65-1.37 10.25-.65 11.7.73 1.45 4.86 1.86 4.86 1.86s1.08-4.34.36-12.2l.01-.01Z' />
          <path id='Vector_40' fill={`#${skin}`}
                d='M42.03 17.36c-1.38 6.53 1.04 25.37 1.59 26.67.65 1.52 7.25 11.15 10.09 11.11 1.87-.02 3.52.05 5.39.03 2.52-.03 12.22-9.68 12.59-10.82.41-1.26 4.12-19.88 2.23-25.8C72.9 15.37 69.07 9.13 66 7.82c-4.1-1.76-14.11-2.04-17.88.36-2.32 1.48-5.53 6.48-6.1 9.18h.01Z' />
          <path id='Vector_41' fill='#4D332E' d='m58.93 25.75.47 2.41s4.12-1.04 8.22.06v-2s-3.44-1.76-8.69-.47Z' />
          <path id='Vector_42' fill='#4D332E'
                d='m42.59 26.78.18 2c4.01-1.3 8.2-.48 8.2-.48l.27-2.42c-5.34-1.03-8.65.9-8.65.9Z' />
          <path id='Vector_43' fill='#4D332E'
                d='M65.11 31.73c-2.22-2.11-4.34-.09-4.42 0l-1.19-1.22c1.16-1.13 4.07-2.59 6.78 0l-1.17 1.23v-.01Z' />
          <path id='Vector_44' fill='#4D332E'
                d='M50.04 32.03c-2.33-1.98-4.33.15-4.42.24l-1.25-1.15c1.09-1.19 3.92-2.81 6.77-.39l-1.1 1.3Z' />
          <path id='Vector_45' fill='#4D332E' d='M63.94 30.47h-2.08v1.59h2.08v-1.59Z' />
          <path id='Vector_46' fill='#4D332E' d='M48.8 30.94h-2.08v1.59h2.08v-1.59Z' />
          <path id='Vector_47' fill='#4D332E'
                d='M37 16.69s4.46 5.08 5.03 4.95c.56-.12 19.57.57 24.26-4.54 1.35-1.47 3.49 6.35 3.49 21.35 0 .79 2.36.07 3.17-.49 0 0 .04-7.79.23-8.49.72-2.64 1.62-2.58 1.62-2.58s-.04-5.82-1.14-9.09c-1.1-3.27-3.82-8.4-7.65-10.34-3.84-1.92-12.53-1.58-14.88-1.02C41.61 8.71 42.9 11.8 37 16.69Z' />
          <path id='Vector_48'fill={`#${skin}`}
                d='M72.32 27.78s3.53-2.01 4.58-1.35c1.05.65 1.37 10.25.64 11.7-.72 1.45-5.88 1.86-5.88 1.86s-.07-4.34.66-12.2v-.01Z' />
          <path id='Vector_49' fill='#EE8585'
                d='M53.08 44.03c2.55 2.36 4.93.04 5.03-.06l1.37 1.37c-1.3 1.3-4.59 3-7.71.11l1.32-1.42h-.01Z' />
          <path id='Vector_50'fill={`#${skin}`}
                d='M54.64 33.96c-1.02.1-3.23 3.89-3.23 5.03 0 1.14 2.73 3.06 3.23 2.99.51-.07 3.93-2.42 3.93-2.99 0-.57-3.36-5.09-3.93-5.03Z' />
        </g>;
      case  'FFF8C8':
        return <g id='Head 4'>
          <path id='Vector_38'fill={`#${skin}`}
                d='M48.158 45.919c.087.31-.884 16.375-.884 16.375s13.683 6.176 24.045-.126l-2.03-23.866-21.14 7.617h.009Z' />
          <path id='Vector_39'fill={`#${skin}`}
                d='M42.098 28.24s-3.146-1.557-4.33-.832c-1.186.735-.923 11.745.271 13.117 2.564 2.948 6.06.406 6.06.406s-1.942-5.916-2-12.692Z' />
          <path id='Vector_40' fill={`#${skin}`}
                d='M70.513 12.793s-9.721-2.707-21.19-1.015c-7.011 1.034-8.273 3.257-8.39 3.75-1.078 4.708.835 24.61 1.525 27.259.699 2.649 7.817 11.812 8.662 12.334.845.512 8.332.725 9.992.251 1.66-.473 10.381-9.84 11.294-11.802.913-1.962 1.865-15.08 1.845-18.095-.01-3.016-2.233-10.836-3.748-12.673l.01-.01Z' />
          <path id='Vector_41' fill='#4A3A50'
                d='m57.704 26.074.272 2.426s5.807-1.981 9.08-.647l.378-1.798s-4.03-3.19-9.73.02Z' />
          <path id='Vector_42' fill='#4A3A50'
                d='m50.294 25.958-.223 2.426s-5.458-1.846-8.526-.435l-.37-1.788s3.74-3.286 9.129-.203h-.01Z' />
          <path id='Vector_43' fill='#2A3447'
                d='M48.372 31.4c-2.321-2.416-4.574-.135-4.662-.038l-1.243-1.402c1.234-1.276 4.312-2.92 7.157.039L48.381 31.4h-.01Z' />
          <path id='Vector_44' fill='#2A3447' d='m45.002 29.989.087 1.817 2.185-.125-.087-1.808-2.185.116Z' />
          <path id='Vector_45' fill='#2A3447'
                d='M64.492 31.197c-2.321-2.416-4.574-.135-4.662-.038l-1.242-1.402c1.233-1.276 4.311-2.92 7.157.039L64.5 31.197h-.01Z' />
          <path id='Vector_46' fill='#2A3447' d='m61.122 29.786.087 1.817 2.185-.116-.087-1.817-2.185.116Z' />
          <path id='Vector_47'fill={`#${skin}`}
                d='M73.902 26.983s3.146-1.556 4.33-.832c1.186.735.923 11.745-.271 13.118-2.564 2.948-6.06.406-6.06.406s1.943-5.916 2-12.692Z' />
          <path id='Vector_48' fill='#4A3A50'
                d='M41.01 38.988s-.844 3.954-.368 8.497c.475 4.543 7.953 8.99 10.594 9.627 2.642.648 12.858.28 13.945-.048 1.088-.329 6.76-5.761 7.342-7.694.379-1.267 2.447-13.05 2.32-17.767-.067-2.465-2.32.416-2.32.416s-2.166 3.973-3.428 4.988c-1.263 1.015-9.332 1.527-12.207 2.29-1.942.513-4.37 2.417-4.37 2.417s-2.049-2.223-3.787-2.416c-1.738-.194-4.186-1.673-5.186-1.77-.99-.096-2.544-6.33-2.544-6.33v7.78l.01.01Z' />
          <path id='Vector_49' fill='#4A3A50'
                d='M69.823 16.273s-12.207-.87-13.488-.677c-1.282.193-13.936 8.4-15.169 9.038-1.233.648-3.904-5.075-3.904-5.075s.136-6.244 0-8.342c-.116-2.097 8.76-7.781 14.266-9.579 5.506-1.808 19.878.29 21.86 5.742 4.67 12.865 1.524 23.47 1.456 24.223-.068.754-2.37 1.15-2.37 1.15s-1.418-13.9-2.65-16.48Z' />
          <path id='Vector_50'fill={`#${skin}`}
                d='M52.635 33.73c-.952.097-3.079 4.07-3.079 5.18 0 1.113 2.486 2.881 2.962 2.804.476-.068 3.554-2.3 3.554-2.861 0-.56-2.913-5.181-3.447-5.123h.01Z' />
          <path id='Vector_51' fill='#DB6768'
                d='M49.925 44.121c3.234 2.648 8.158.039 8.284-.077l.845 1.75c-1.67 1.45-5.866 3.344-9.828.106l.7-1.789v.01Z' />
        </g>;
      case '514463' :
        return <g id='Head 2'>
          <path id='Vector_38'fill={`#${skin}`}
                d='M42.05 28.21s-3.83-1.36-4.75-.53c-.92.83.44 10.33 1.41 11.63.97 1.3 5.11.98 5.11.98s.31-4.46-1.78-12.08h.01Z' />
          <path id='Vector_39'fill={`#${skin}`}
                d='M47.07 46.12c.1.26-.19 14.28-.19 14.28s13.53 5.21 23.68-.73l-1.47-15.84-22.02 2.29Z' />
          <path id='Vector_40' fill={`#${skin}`}
                d='M41.73 15.97c-1.38 6.53 1.04 25.37 1.59 26.67.65 1.52 7.25 11.15 10.09 11.11 1.87-.02 3.52.05 5.39.03 2.52-.03 12.22-9.68 12.59-10.82.41-1.26 4.12-19.88 2.23-25.8-1.02-3.18-4.85-9.42-7.92-10.73-4.11-1.76-14.12-2.04-17.88.37-2.32 1.48-5.53 6.48-6.1 9.18l.01-.01Z' />
          <path id='Vector_41' fill='#4A3A50'
                d='M42.86 40.32s3.21 2.94 3.9 3.19c.7.25 5.65-1.9 5.97-2.09.32-.19 5.7-.11 5.95-.11s6.49 1.9 6.89 1.88c.4-.02 4.35-3.21 6.49-4.48.2-.12.28 3.78-1.73 6.14-2 2.37-8.42 8.21-9.18 8.6-.76.39-8.59.61-9.61.1-1.02-.51-9.1-8.8-8.69-13.24l.01.01Z' />
          <path id='Vector_42' fill='#EE8585'
                d='M53.4 43.75c2.16 1.87 4.05-.09 4.13-.18l1.16 1.09c-1.04 1.1-3.69 2.58-6.33.29l1.04-1.2Z' />
          <path id='Vector_43' fill='#4A3A50' d='m58.79 24.56.47 2.41s4.12-1.04 8.22.06v-2s-3.44-1.76-8.69-.47Z' />
          <path id='Vector_44' fill='#4A3A50'
                d='m42.45 25.59.18 2c4.01-1.3 8.2-.48 8.2-.48l.27-2.42c-5.34-1.03-8.65.91-8.65.91v-.01Z' />
          <path id='Vector_45'fill={`#${skin}`}
                d='M73.07 27.77s2.75-1.77 3.45-1.05c.7.72.08 10.29-.58 11.69-.66 1.4-4.44 1.47-4.44 1.47s.34-4.32 1.57-12.11Z' />
          <path id='Vector_46' fill='#4D332E'
                d='M64.93 31.8c-1.95-2.08-4.04-.32-4.12-.24l-1.04-1.2c1.14-.99 3.93-2.2 6.33.35l-1.16 1.09h-.01Z' />
          <path id='Vector_47'fill={`#${skin}`}
                d='M55.54 32.61c-1.21.1-3.85 4.13-3.85 5.34s3.24 3.24 3.85 3.17c.6-.08 3.43-2.6 3.43-3.21 0-.61-2.75-5.36-3.43-5.3Z' />
          <path id='Vector_48' fill='#4D332E' d='M63.95 30.49h-1.94v1.49h1.94v-1.49Z' />
          <path id='Vector_49' fill='#4D332E'
                d='M49.74 31.8c-1.96-2.08-4.04-.32-4.12-.24l-1.04-1.2c1.14-.99 3.93-2.2 6.33.35l-1.16 1.09h-.01Z' />
          <path id='Vector_50' fill='#4D332E' d='M48.76 30.49h-1.94v1.49h1.94v-1.49Z' />
          <path id='Vector_51' fill='#4A3A50'
                d='M73.89 15.1c-.37-2.57-5.81-11.58-11.23-11.08-5.43.5-19.98.8-21.62 5.19-1.65 4.39-1.23 10.81-.64 10.7-.1 3.99 1.03 8.92 1.05 9.23l.46-8.35c.31 5.61 5.36 7.32 5.36 7.32-.49-11.2 15.47-16.85 16.37-15.93.9.92 5.8 6.78 5.8 6.78s1.45 7.91 1.8 9.46c.35 1.55 1.25 2.52 1.25 2.52s3.29-2.83 1.42-15.85l-.02.01Z' />
        </g>;
    }

  }, [head, skin]);
  return (
    <svg fill='none' viewBox='0 0 142 349'>
      <g id='Male Option 3'>
        <g id='bag'>
          <path id='Vector' fill='#705A47'
                d='m96.36 53.652-2.532-.104c-.139-2.449-.806-9.398-2.167-12.15-.694-1.402-2.229-2.19-4.102-2.12-2.557.103-4.829 1.722-5.419 3.842-1.005 3.67.876 9.753 1.691 11.787l-2.228 1.246c-.139-.355-3.434-8.697-1.856-14.409 1.04-3.782 4.604-5.625 7.474-5.738 2.888-.112 5.272 1.281 6.538 3.834 1.994 4.016 2.549 12.817 2.601 13.803v.01Z' />
          <path id='Vector_2' fill='#8F765D'
                d='M68.597 56.655c1.43-2.864 31.023-5.677 33.286-4.63 2.263 1.056 18.243 31.536 20.983 36.97 2.74 5.444 11.254 45.202 10.084 51.208-.98 5.062-40.795 11.648-47.983 9.311-7.188-2.336-16.465-39.29-16.942-44.846-.685-8.091-2.177-42.526.563-48.022l.009.01Z' />
          <path id='Vector_3' fill='#A8886C'
                d='M121.366 107.775s6.694-2.38 13.049 3.73c4.066 3.903 8.072 28.749 7.457 29.814-.616 1.064-12.234 4.05-13.396 2.977-1.162-1.082-7.734-34.149-7.11-36.512v-.009Z' />
          <path id='Vector_4' fill='#A8886C'
                d='M112.08 102.098s4.396 14.167 6.919 26.179c2.532 12.021.685 12.003.685 12.003s-29.359 6.733-32.263 5.245c-2.905-1.489-7.665-33.604-7.284-37.412.382-3.808 32.047-7.616 31.943-6.023v.008Z' />
          <path id='Vector_5' fill='#705A47'
                d='M129.594 117.632c5.185-1.003 6.598-2.561 6.72-2.691l-.807-2.06c-.138.139-4.352 3.531-13.309 3.107l.677 2.12c2.878.13 4.751-.095 6.711-.476h.008Z' />
          <path id='Vector_6' fill='#705A47'
                d='M134.744 113.608c-.251-.311-6.164-7.78-9.138-17.922-2.818-9.615-12.581-21.809-12.677-21.93l2.159-1.731c.417.52 10.206 12.739 13.18 22.89 2.809 9.598 8.583 16.902 8.644 16.971l-2.168 1.731v-.009Z' />
          <path id='Vector_7' fill='#8F765D'
                d='M128.467 109.922s1.188 1.774 2.28 1.999c1.093.225 8.159-2.631 8.133-2.925-.034-.295-1.404-2.467-2.358-2.406-.954.06-7.951 2.613-8.046 3.323l-.009.009Z' />
          <path id='Vector_8' fill='#705A47'
                d='M138.325 126.529c-.017-.121-1.604-11.882-8.748-22.501-7.387-10.982-16.622-28.862-16.717-29.043l2.471-1.264c.087.173 9.252 17.923 16.552 28.767 7.526 11.181 9.139 23.184 9.2 23.695l-2.749.355-.009-.009Z' />
          <path id='Vector_9' fill='#8F765D'
                d='M136.825 121.414s.573 2.112 1.05 2.726c.476.615 3.398.494 3.381.199-.026-.294-.685-2.847-1.092-3.132-.408-.295-3.313-.424-3.33.207h-.009Z' />
          <path id='Vector_10' fill='#E5C7B0'
                d='M129.317 116.672c.008.407.459 10.636 1.066 10.541.616-.096 2.333.363 2.341-.45 0-.823-2.167-10.178-2.462-10.264-.295-.095-.945.181-.945.181v-.008Z' />
          <path id='Vector_11' fill='#705A47'
                d='M75.056 55.842s5.454 39.324 6.72 43.547c1.266 4.224 21.581 2.095 22.734-3.314 1.154-5.418-11.115-44.586-11.115-44.586s-13.318 1.817-18.338 4.353Z' />
          <path id='Vector_12' fill='#A8886C'
                d='M86.068 54.656s14.437-3.237 17.341 2.804c2.905 6.04 5.939 17.118 8.922 19.948 2.983 2.838 6.859.104 6.859.104s-8.567-22.467-14.211-25.418c-5.645-2.95-19.232-.727-20.359.511-1.127 1.238 1.457 2.051 1.457 2.051h-.009Z' />
          <path id='Vector_13' fill='#625040'
                d='M83.58 53.747s19.534-5.218 25.075 1.506c5.54 6.724 16.014 43.392 17.688 47.91 1.673 4.517 3.017 3.505 1.552-1.792-2.229-8.04-9.72-30.791-9.885-31.64-.164-.847-4.829-14.616-9.823-18.156-4.995-3.548-20.662-1.012-24.061.65-3.399 1.66-.546 1.522-.546 1.522Z' />
          <path id='Vector_14' fill='#8F765D' d='m116.924 65.794-4.879 1.97 1.187 2.928 4.879-1.97-1.187-2.928Z' />
          <path id='Vector_15' fill='#705A47' d='m109.709 106.14-25.695 4.99.495 2.54 25.695-4.99-.495-2.54Z' />
          <path id='Vector_16' fill='#E5C7B0'
                d='m122.193 83.341-.462 7.255a.537.537 0 0 0 .502.57l.26.016a.538.538 0 0 0 .571-.501l.462-7.255a.537.537 0 0 0-.502-.57l-.26-.016a.538.538 0 0 0-.571.501Z' />
          <path id='Vector_17' fill='#E5C7B0'
                d='M107.857 107.68c0 .303.338 7.893.798 7.824.451-.07 1.734.268 1.734-.338 0-.606-1.613-7.555-1.83-7.624-.216-.07-.702.138-.702.138Z' />
          <path id='Vector_18' fill='#705A47' d='m124.858 89.87-4.341.843.604 3.1 4.341-.841-.604-3.101Z' />
        </g>
        <g id='Body Option 3'>
          <path id='Vector_19' fill={`#${skin}`}
                d='M97.79 69.17s5.11 14.52 7.25 29.53c2.14 15.01 4.45 24.81 4.02 27.56-.42 2.75-4.35 14.15-14.81 6.81-1.81-1.27-10.03-34.15-10.51-37.25-.49-3.1-4.95-34.8 14.06-26.65h-.01Z' />
          <path id='Vector_20' fill={`#${skin}`}
                d='M109.06 126.25s3.6 16.61 4.09 27.09c.48 10.48.53 22.94.53 22.94s-4.57 7.61-9.12 1.17c-.73-1.03-9.77-36.05-10.47-37.72-.7-1.67-3.73-14.32 3.9-16.55 8.82-2.58 11.07 3.08 11.07 3.08v-.01Z' />
          <path id='Vector_21' fill={`#${skin}`}
                d='M22.05 70.41s-4.71 14.52-6.85 29.53c-2.14 15.01-4.45 24.81-4.02 27.56.42 2.75 4.35 14.15 14.81 6.81 1.81-1.27 10.03-34.15 10.51-37.25.49-3.1 4.54-34.8-14.46-26.65h.01Z' />
          <path id='Vector_22' fill={`#${skin}`}
                d='M11.18 127.5s-3.6 16.61-4.09 27.09c-.49 10.48-.53 22.94-.53 22.94s4.56 7.61 9.12 1.17c.73-1.03 9.77-36.05 10.47-37.72.7-1.67 3.73-14.32-3.9-16.55-8.82-2.58-11.07 3.08-11.07 3.08v-.01Z' />
          <path id='Vector_23' fill={`#${skin}`}
                d='M45.98 60.03S22.7 68.51 22.05 70.41c-.65 1.9 1.31 20.99 8.75 54.08.81 3.62 2.46 32.54 3.03 34.68 5.1 19.17 38.12 16.02 49.22 6.31 2.09-1.83-.4-23.86.21-27.55.62-3.69 5-17.18 6.23-25.7 1.5-10.46 7.7-43.38 7.7-43.38l-26.05-8.82s-9.47-.48-25.16 0Z' />
          <path id='Vector_24' fill={`#${skin}`}
                d='M32.9 180.05c-1.14 3.35 2.57 63.54 2.42 68.77-.15 5.22.43 10.88 4.57 12.88 4.15 2 12.02-.71 13.86-7.78 1.84-7.07 6.34-60.24 6.46-63.01.44-10.29-25.01-17.66-27.32-10.86h.01Z' />
          <path id='Vector_25' fill={`#${skin}`}
                d='M35.22 250.36s1.08 14.33 2.08 21.74c.61 4.54 1.84 48.85 1.84 48.85s3.44 8.4 10.93 1.11c0 0 5.59-33.73 6.03-37.69.75-6.76-2.19-28.29-2.35-30.45-.15-2.15-12.48-14.35-18.53-3.56Z' />
          <path id='Vector_26' fill={`#${skin}`}
                d='M60.8 190.79c.33 3.52 12.05 58.21 12.9 63.37.84 5.16 5.21 7.95 9.82 7.79 6.99-.25 9.21-4.02 9.19-9.92-.03-7.3-5.66-70.58-5.91-73.33-1.03-11.53-27.44-3.31-26 12.1v-.01Z' />
          <path id='Vector_27' fill={`#${skin}`}
                d='M74.42 250.89c-3.04 6.74 4.82 7.68 5.94 26.04.28 4.58 10.31 38.65 12.34 44.91.69 2.12 6.02 3.84 8.65-2.21 0 0-.85-30.76-.81-34.75.11-9.98-7.2-33.91-7.84-35.97-1.93-6.23-14.84-5.65-18.29 1.98h.01Z' />
          <path id='Vector_28' fill={`#${skin}`}
                d='M84.27 159.05s1.58 12.13 2.44 18.93c.67 5.32-18.92 13.99-31.46 12.67-5.9-.62-22.58-1.61-22.57-12.68.01-18.4 1.45-28.91 11.68-32.84 8.1-3.11 34.98 1.69 37.11 3.75 2.13 2.07 2.8 10.16 2.8 10.16v.01Z' />
          <path id='Vector_29' fill={`#${skin}`}
                d='M39.13 320.95s-14.34 7.96-18.12 9.35c-3.78 1.39-12.33 2.87-12.81 3.91-.48 1.04.65 3.95 6.42 4.85 5.77.9 20.61-1.62 22.77-1.83 2.17-.22 14.9-.15 14.83-1.13-.07-.98-1.81-14.06-2.52-15.15-.7-1.09-6.88-2.76-10.58 0h.01Z' />
          <path id='Vector_30' fill={`#${skin}`}
                d='M92.73 321.33s1 11.15.84 14.23c-.16 3.07-2.26 8.48 4.19 10.74 4.94 1.73 10.34.28 12.65-1.34 2.31-1.61.29-3.81-.19-4.57-4.08-6.44-8.08-17.02-8.85-20.76-.77-3.73-8.63-4.47-8.63 1.7h-.01Z' />
          <path id='Vector_31' fill='#3A3B43'
                d='M33.83 159.18s39.75.68 50.34-1.16l2.64 20.68s-23.13 3.18-26 12.1H59.4s-19.83-12.47-26.71-11.61c0 0-1.06-11.63 1.15-20l-.01-.01Z' />
          <path id='Vector_32' fill={`#${skin}`}
                d='M12.47 182.18s-1.99 7.68-2.27 9.39c-.15.93-1.28 7.61-2.64 10.14-1.14 2.11-2.79 2.2-2.79 2.2.05-.22-.77-13.73-.33-14.91.44-1.19 8.03-6.81 8.03-6.81v-.01Z' />
          <path id='Vector_33' fill={`#${skin}`}
                d='M16.47 175.45c.37 1.6-3.32 16.92-4.02 19.34-.43 1.49-2.45.96-2.56.26-.34-2.13.05-9.14.12-9.98.08-.84 5.8-12.52 6.46-9.62Z' />
          <path id='Vector_34' fill={`#${skin}`}
                d='M16.12 176.63c1.6-3.87-3.99-8.5-9.58-1.66-1.56 1.91-5.79 12.6-6.44 14.12-.66 1.52 2.19 13.49 2.61 14.38.42.89 2.2.56 2.41.23.4-.62-.01-11.47.18-13.61.09-.95 9.53-10.35 10.82-13.46Z' />
          <path id='Vector_35' fill={`#${skin}`}
                d='M107.3 182.18s1.99 7.68 2.27 9.39c.15.93 1.28 7.61 2.64 10.14 1.14 2.11 2.79 2.2 2.79 2.2-.05-.22.77-13.73.33-14.91-.44-1.19-8.03-6.81-8.03-6.81v-.01Z' />
          <path id='Vector_36' fill={`#${skin}`}
                d='M103.31 175.45c-.37 1.6 3.32 16.92 4.02 19.34.43 1.49 2.45.96 2.56.26.34-2.13-.05-9.14-.12-9.98-.08-.84-5.8-12.52-6.46-9.62Z' />
          <path id='Vector_37' fill={`#${skin}`}
                d='M103.66 176.63c-1.6-3.87 3.99-8.5 9.58-1.66 1.56 1.91 5.79 12.6 6.44 14.12.66 1.52-2.19 13.49-2.61 14.38-.42.89-2.2.56-2.42.23-.4-.62.01-11.47-.18-13.61-.09-.95-9.53-10.35-10.82-13.46h.01Z' />
        </g>
        <g id='Heads Option 3'>
          {currentHead}
        </g>
        <g id='Shoes'>
          <g id='RUNNING'>
            <g id='Group 3'>
              <path id='Vector_51' fill='#EBEBEB'
                    d='M54.058 338.602s.446-3.221-1.033-6.037c-1.478-2.817-12.82 5.392-23.904 7.281-11.084 1.89-21.734-1.819-21.734-1.819s-.763 2.388 1.303 3.697c2.065 1.303 9.212 1.608 13.8 1.737 4.589.135 31.568-4.864 31.568-4.864v.005Z' />
              <path id='Vector_52' fill='#222629'
                    d='M35.778 317.473c-.425.166-11.853 11.216-17.456 13.508-5.603 2.297-8.839 1.34-11.207 3.145-2.374 1.805-1.722 4.245-1.722 4.245s13.575 2.967 23.385 2.228c9.804-.744 12.283-4.668 15.407-5.304 3.125-.636 7.652 0 8.187-1.06.535-1.06-.541-13.153-1.618-15.279-1.076-2.119-3.34 2.332-5.277 3.821-1.938 1.484-3.986-7.533-9.7-5.304Z' />
              <path id='Vector_53' fill='#464F54'
                    d='M4.483 336.153s1.273-2.018 3.561.898c2.289 2.916 3.938 3.726 9.125 4.454 5.186.727 8.255.98 14.234 0 5.973-.98 16.189-2.13 18.471-2.694a30.43 30.43 0 0 1 4.248-.727v1.525s-7.833 1.549-15.437 2.541c-7.61.986-32.201 4.325-34.554-3.139-.434-1.379.352-2.852.352-2.852v-.006Z' />
              <path id='Vector_54' fill='#464F54'
                    d='M44.986 323.801s2.306 1.93 3.204 2.582c.898.651 3.321-.352 3.321-.352s.411-7.13-2.053-6.69c-1.315.235-4.471 4.466-4.471 4.466v-.006Z' />
              <path id='Vector_55' fill='#191C1F'
                    d='M26.99 331.042s7.54-7.018 10.375-8.244c2.834-1.227 4.465-5.95 4.465-6.766 0-.816-2.934-3.585-8.596-2.934-5.662.652-1.66 6.578-2.16 8.227-.492 1.649-2.98 3.426-5.52 5.504-2.547 2.077-3.28 3.098-3.034 3.28.246.182 4.47.927 4.47.927v.006Z' />
              <path id='Vector_56' fill='#464F54'
                    d='M17.955 332.209s4.318-.51 5.703.622c1.385 1.133 6.378 4.513 9.206 5.563 2.834 1.05 4.301.528 4.301.528s-4.06-.393-6.583-3.82c-2.53-3.421-6.08-6.472-7.07-6.66-.998-.188-5.563 3.761-5.563 3.761l.006.006Z' />
              <path id='Vector_57' fill='#EBEBEB'
                    d='M32.911 326.471c-1.232-.669-2.535-1.18-3.914-1.444-.974-.188-2.311-.522-3.168.123-.663.499-.012 1.632.657 1.127.716-.54 2.253 0 3.034.211.962.258 1.86.64 2.728 1.115.74.399 1.397-.727.657-1.126l.006-.006Z' />
              <path id='Vector_58' fill='#EBEBEB'
                    d='M37.37 322.798c-1.29-.716-2.657-1.192-4.124-1.362-1.045-.123-2.43-.334-3.128.616-.499.675.634 1.326 1.127.658.293-.405 2.47.099 2.869.193.921.211 1.778.569 2.6 1.027.733.405 1.39-.722.657-1.127v-.005Z' />
              <path id='Vector_59' fill='#464F54'
                    d='M52.448 323.672s-7.8 4.869-9.214 6.405c-1.414 1.537-4.737 7.554-5.136 7.98-.399.426 15.706-2.853 15.706-2.853s.298-6.481-1.356-11.532Z' />
            </g>
            <g id='Group 2'>
              <path id='Vector_60' fill='#222629'
                    d='M87.007 315.069s.865 9.708.66 15.49c-.198 5.775-1.708 10.092 1.656 15.115 1.686 2.505 19.707 2.939 21.788-.707 3.049-5.348-6.816-15.822-8.091-20.101-1.275-4.28-2.352-10.232-3.789-10.696-1.436-.464-9.908-2.04-12.231.906l.007-.007Z' />
              <path id='Vector_61' fill='#EBEBEB'
                    d='M87 338.025s1.923 5.673 8.543 5.448c6.627-.224 15.376 1.776 15.361-5.448 0 0 .954 2.591.763 3.63-.19 1.039-2.055 4.991-7.515 4.57-5.46-.414-13.952-.976-14.884-1.86-.932-.892-2.268-6.34-2.268-6.34Z' />
              <path id='Vector_62' fill='#464F54'
                    d='M87.127 338.033s-.769 3.628 1.477 8.076c2.246 4.449 19.732 2.808 22.455 1.345 2.716-1.463 2.425-4.692.605-8.63 0 0-.605 6.694-3.566 4.684 0 0-6.133-8.254-12.28.252-1.417 1.965-7.505 1.721-8.684-5.735l-.007.008Z' />
              <path id='Vector_63' fill='#191C1F'
                    d='M92.922 328.415s-1.372-12.128-4.395-15.277c-3.023-3.157-1.65-5.67 4.943-6.364 6.6-.695 8.335-.366 8.612 2.951.162 1.973-2.336 6.671-1.596 11.172.741 4.501 1.504 6.634 1.504 6.634l-9.068.892v-.008Z' />
              <path id='Vector_64' fill='#464F54'
                    d='M87 332.531s2.067-1.662 4.193-2.588c2.126-.925 9.214-1.654 11.467-1.064 3.485.919 4.917 2.252 4.917 2.252l-1.358-2.062s-2.507-3.397-8.229-3.39c-4.313 0-9.393 1.655-10.81 4.949l-.18 1.91v-.007Z' />
              <path id='Vector_65' fill='#EBEBEB'
                    d='M101.798 321.533c-1.853-1.593-4.116-1.461-6.32-1.139-2.197.322-4.204.71-5.598 2.79-.54.809.664 1.584 1.197.792 1.81-2.699 7.722-3.07 10.021-1.089.708.61 1.401-.751.7-1.354Z' />
              <path id='Vector_66' fill='#EBEBEB'
                    d='M101.728 315.725c-2.256-1.428-5.084-1.05-7.59-.689-2.087.303-4.061.782-5.552 2.412-.676.748.403 1.874 1.08 1.126 2.232-2.454 8.549-3.21 11.288-1.479.846.538 1.611-.849.774-1.378v.008Z' />
            </g>
          </g>
        </g>
        <g id='Bottoms'>
          {/*<g id='Jeans'>*/}
          {/*  <g id='JEANS INDIGO'>*/}
          {/*    <path id='Vector_67' fill='#2A3447'*/}
          {/*          d='M32.867 141.574s-4.627 21.9-3.18 40.428c.669 8.567 7.298 8.68 28.345 8.853 21.046.173 30.891-5.399 31.943-8.608 1.052-3.209-2.32-21.917-3.544-26.928-1.226-5.011-3.24-13.751-3.24-13.751s-28.262 4.021-50.33 0l.006.006Z' />*/}
          {/*    <path id='Vector_68' fill='#2A3447'*/}
          {/*          d='M29.914 175.405s-.927 3.961.878 28.312c1.805 24.351 2.738 46.662 4.1 50.951 1.364 4.283 17.802 16.256 23.26 3.866 1.14-2.589 2.534-67.684 2.534-67.684S48.7 161.822 29.914 175.405Z' />*/}
          {/*    <path id='Vector_69' fill='#2A3447'*/}
          {/*          d='M34.755 251.202c-.12.501-.012 29.094.831 40.703a1533.67 1533.67 0 0 1 1.387 21.135l17.73 1.605s3.34-52.395 3.735-57.972c.998-14.306-20.198-19.758-23.683-5.471Z' />*/}
          {/*    <path id='Vector_70' fill='#2A3447'*/}
          {/*          d='M88.552 172.046s1.626 3.735 3.975 28.05c2.356 24.315 5.237 46.465 4.585 50.933-.651 4.462-15.368 19.209-23.067 7.988-1.608-2.35-13.94-67.744-13.94-67.744s6.935-29.237 28.447-19.227Z' />*/}
          {/*    <path id='Vector_71' fill='#2A3447'*/}
          {/*          d='M97.411 247.587c.18.483 3.27 28.909 3.736 40.542.46 11.632.992 21.159.992 21.159l-17.436 3.573s-9.193-51.696-10.204-57.191c-2.594-14.102 17.855-21.887 22.918-8.077l-.006-.006Z' />*/}
          {/*    <path id='Vector_72' fill='#1E1E1E'*/}
          {/*          d='M35.969 309.926s.15 4.343.382 4.88c.233.537 18.59 1.563 18.817 1.05.228-.513.514-4.432.287-5.106-.22-.668-19.104-1.79-19.486-.83v.006Z' />*/}
          {/*    <path id='Vector_73' fill='#1E1E1E'*/}
          {/*          d='M103 308.769s-.03 4.343-.245 4.886c-.215.543-18.542 2.088-18.781 1.587-.24-.507-.634-4.421-.43-5.095.209-.674 19.044-2.326 19.456-1.378Z' />*/}
          {/*    <path id='Vector_74' fill='#1E1E1E'*/}
          {/*          d='M86.879 156.834c-4.172 0-12.523-.232-13.934-1.795-1.398-1.551-2.857-7.523-3.132-8.704l2.05-.411c.646 2.75 1.913 7.003 2.702 7.88.777.65 7.107 1.205 11.865 1.205l.449 1.825Z' />*/}
          {/*    <path id='Vector_75' fill='#1E1E1E'*/}
          {/*          d='m31.342 157.306.383-2.124c4.758 0 10.79-.459 11.584-1.187.771-.931 2.038-5.554 2.684-8.537l2.05.442c-.275 1.282-1.733 7.761-3.132 9.449-1.41 1.7-9.397 1.951-13.569 1.951v.006Z' />*/}
          {/*    <path id='Vector_76' fill='#1E1E1E'*/}
          {/*          d='M56.663 143.358s-.09 37.452 0 39.808c.09 2.362 1.369 7.69 1.369 7.69h1.733s-.915-4.63-.915-7.779c0-3.144.544-39.725.544-39.725h-2.737l.006.006Z' />*/}
          {/*    <path id='Vector_77' fill='#373E45'*/}
          {/*          d='M60.076 147.606c0-1.062-.86-1.921-1.925-1.921a1.922 1.922 0 1 0 0 3.842 1.922 1.922 0 0 0 1.925-1.921Z' />*/}
          {/*  </g>*/}
          {/*</g>*/}
          {currentBottom}
        </g>
        <g id='Tops'>
          {/*<g id='Tropical Shirt'>*/}
          {/*  <g id='Clip path group'>*/}
          {/*    <mask id='mask0_232_261' width='34' height='46' x='7' y='71' maskUnits='userSpaceOnUse'*/}
          {/*          style={{ maskType: 'luminance' }}>*/}
          {/*      <g id='clippath'>*/}
          {/*        <path id='Vector_78' fill='#fff'*/}
          {/*              d='M19.35 71.708c-.68.848-11.57 37.878-11.57 37.878l28.18 6.932s4.71-24.63 4.6-30.487c-.103-5.856-7.728-13.274-12.768-14.33-5.04-1.056-8.442 0-8.442 0v.007Z' />*/}
          {/*      </g>*/}
          {/*    </mask>*/}
          {/*    <g mask='url(#mask0_232_261)'>*/}
          {/*      <g id='Group'>*/}
          {/*        <path id='Vector_79' fill='#F5EDE6'*/}
          {/*              d='M19.35 71.708c-.68.848-11.57 37.878-11.57 37.878l28.18 6.932s4.71-24.63 4.6-30.487c-.103-5.856-7.728-13.274-12.768-14.33-5.04-1.056-8.442 0-8.442 0v.007Z' />*/}
          {/*        <path id='Vector_80' fill='#95E8D6'*/}
          {/*              d='M34.166 72.537c.454-.402-3.09-1.723-5.02-1.769-1.588-.038-4.86 1.575-5.967 2.151l-.026-.207c-.084.006-.764.045-1.632.097 1.049-.492 3.135-1.458 4.47-1.892 1.833-.596 4.858-2.87 4.308-3.122-.467-.22-4.438.097-6.498.654 1.658-1.833 3.472-5.001 2.837-4.995-.719.007-6.51 3.356-7.76 4.898-1.128 1.393-1.18 4.165-1.18 4.677-.816.046-1.593.097-2.325.143.796-1.127 2.811-4.017 3.75-5.662 1.193-2.073 2.21-6.66 1.53-6.427-.68.233-5.138 5.222-5.844 7.075-.557 1.457-.097 4.023.123 5.046-1.432.09-2.63.175-3.401.253.77-1.056 2.656-3.848 3.051-6.355.506-3.168-.401-9.394-1.14-8.779-.738.616-3.958 8.986-3.958 11.642 0 1.86.835 3.032 1.334 3.576-.064.013-.116.02-.155.032-4.328 1.089-7.638 5.138-7.774 5.306l.635.512c.032-.039 3.278-4.01 7.36-5.047.122-.032.472-.07.964-.116-.291.79-1.01 3.122-.395 5.195.758 2.546 6.22 9.66 7.1 10.035.881.376-.019-5.85-1.399-8.745-1.172-2.462-4.1-5.624-4.95-6.518a98.17 98.17 0 0 1 1.905-.136c.363.81 1.911 4.198 2.961 5.578 1.198 1.574 6.893 5.092 7.612 5.118.72.032-1.561-4.075-3.291-5.727-1.51-1.438-5.966-4.275-7.094-4.982 1.076-.07 2.287-.142 3.447-.213.233.486 1.671 3.427 3.168 4.457 1.632 1.12 8.143 2.688 8.83 2.487.647-.181-2.352-3.005-4.51-4.204 2.035.434 6.24.46 6.706.2.53-.297-2.656-2.338-4.529-2.791-.984-.24-2.72-.467-4.159-.629l-.026-.194c.758.09 2.423.31 3.59.544 1.56.311 6.899-.764 7.359-1.166h-.007ZM21.754 70.25c-.447.965-.713 2.086-.817 2.591-1.043.059-2.085.117-3.077.175.525-.35 2.352-1.574 3.894-2.766Zm1.943 5.63c-2.06-.836-4.994-1.795-5.785-2.054 1.173-.071 2.28-.13 3.136-.181.103 0 .194-.013.291-.013.538.602 1.477 1.6 2.358 2.248Z' />*/}
          {/*      </g>*/}
          {/*    </g>*/}
          {/*  </g>*/}
          {/*  <g id='Clip path group_2'>*/}
          {/*    <mask id='mask1_232_261' width='34' height='46' x='80' y='70' maskUnits='userSpaceOnUse'*/}
          {/*          style={{ maskType: 'luminance' }}>*/}
          {/*      <g id='clippath-1'>*/}
          {/*        <path id='Vector_81' fill='#fff'*/}
          {/*              d='M102.124 70.483c.68.849 11.57 37.879 11.57 37.879l-28.18 6.932s-4.71-24.63-4.6-30.487c.11-5.856 7.728-13.274 12.769-14.33 5.04-1.056 8.441 0 8.441 0v.006Z' />*/}
          {/*      </g>*/}
          {/*    </mask>*/}
          {/*    <g mask='url(#mask1_232_261)'>*/}
          {/*      <g id='Group_2'>*/}
          {/*        <path id='Vector_82' fill='#F5EDE6'*/}
          {/*              d='M102.124 70.483c.68.849 11.57 37.879 11.57 37.879l-28.18 6.932s-4.71-24.63-4.6-30.487c.11-5.856 7.728-13.274 12.769-14.33 5.04-1.056 8.441 0 8.441 0v.006Z' />*/}
          {/*        <path id='Vector_83' fill='#95E8D6'*/}
          {/*              d='M94.667 72.485c1.173-.233 2.831-.453 3.59-.544l-.027.194c-1.438.162-3.18.389-4.159.635-1.872.46-5.06 2.494-4.528 2.792.466.26 4.67.234 6.705-.2-2.157 1.198-5.157 4.016-4.509 4.204.687.194 7.197-1.367 8.83-2.488 1.497-1.03 2.935-3.97 3.168-4.457 1.16.065 2.371.143 3.446.214-1.127.713-5.59 3.544-7.093 4.982-1.73 1.652-4.01 5.759-3.291 5.727.719-.026 6.413-3.544 7.612-5.118 1.056-1.38 2.597-4.768 2.96-5.578.719.045 1.374.097 1.905.136-.849.894-3.777 4.055-4.95 6.517-1.379 2.89-2.286 9.122-1.399 8.746.881-.376 6.349-7.489 7.1-10.035.616-2.073-.11-4.399-.395-5.196.499.046.842.085.965.117 4.082 1.037 7.327 5.008 7.36 5.047l.635-.512c-.136-.169-3.447-4.224-7.794-5.325-.039-.013-.09-.02-.155-.033.505-.544 1.334-1.716 1.334-3.576 0-2.656-3.213-11.026-3.958-11.641-.738-.616-1.645 5.616-1.14 8.778.395 2.507 2.28 5.293 3.051 6.355-.771-.078-1.963-.162-3.401-.253.22-1.023.68-3.589.123-5.046-.7-1.853-5.163-6.841-5.843-7.075-.681-.233.343 4.354 1.529 6.427.945 1.645 2.96 4.541 3.751 5.662-.733-.045-1.51-.09-2.326-.142 0-.512-.052-3.285-1.179-4.678-1.244-1.542-7.042-4.891-7.761-4.897-.635-.007 1.179 3.16 2.837 4.994-2.053-.557-6.025-.874-6.491-.654-.55.26 2.475 2.527 4.308 3.123 1.335.434 3.42 1.392 4.47 1.891-.868-.052-1.548-.09-1.632-.097l-.026.207c-1.102-.57-4.373-2.19-5.967-2.15-1.924.051-5.474 1.366-5.02 1.768.453.402 5.797 1.484 7.359 1.166l.006.013Zm8.953-.693c-.997-.058-2.034-.117-3.077-.175-.104-.505-.369-1.626-.816-2.591a82.706 82.706 0 0 0 3.893 2.766Zm-3.479.622c.098 0 .182.013.292.013.855.045 1.963.11 3.135.181-.79.253-3.725 1.212-5.785 2.054.881-.648 1.82-1.64 2.358-2.248Z' />*/}
          {/*      </g>*/}
          {/*    </g>*/}
          {/*  </g>*/}
          {/*  <g id='Clip path group_3'>*/}
          {/*    <mask id='mask2_232_261' width='83' height='114' x='19' y='60' maskUnits='userSpaceOnUse'*/}
          {/*          style={{ maskType: 'luminance' }}>*/}
          {/*      <g id='clippath-2'>*/}
          {/*        <path id='Vector_84' fill='#fff'*/}
          {/*              d='M43.307 61.53s-17.88 6.537-20.167 7.761c-2.287 1.225-3.783 2.417-3.783 2.417s4.509 44.324 4.509 48.406c0 4.081 2.3 50.459 2.436 50.051.136-.408 25.9 4.218 36.369 3.809 10.469-.408 32.385-2.72 32.385-2.72s-1.224-55.409-.544-59.497c.68-4.082 7.398-41.326 7.398-41.326l-25.66-9.736L56.632 88.96 43.307 61.53Z' />*/}
          {/*      </g>*/}
          {/*    </mask>*/}
          {/*    <g mask='url(#mask2_232_261)'>*/}
          {/*      <g id='Group_3'>*/}
          {/*        <path id='Vector_85' fill='#FFF7F0'*/}
          {/*              d='M43.307 61.53s-17.88 6.537-20.167 7.761c-2.287 1.225-3.783 2.417-3.783 2.417s4.509 44.324 4.509 48.406c0 4.081 2.3 50.459 2.436 50.051.136-.408 25.9 4.218 36.369 3.809 10.469-.408 32.385-2.72 32.385-2.72s-1.224-55.409-.544-59.497c.68-4.082 7.398-41.326 7.398-41.326l-25.66-9.736L56.632 88.96 43.307 61.53Z' />*/}
          {/*        <path id='Vector_86' fill='#95E8D6'*/}
          {/*              d='M52.105 95.904c-.04-.013-.091-.02-.156-.032.506-.544 1.341-1.717 1.341-3.576 0-2.656-3.213-11.026-3.958-11.642-.739-.615-1.645 5.617-1.14 8.785.395 2.507 2.28 5.293 3.051 6.355-.726-.071-1.84-.149-3.174-.233.142-.933.473-3.557-.104-5.06-.7-1.852-5.163-6.84-5.843-7.074-.68-.233.343 4.353 1.529 6.426.971 1.698 3.271 4.665 4.074 5.682-.816-.052-1.71-.104-2.643-.162 0-.512-.052-3.285-1.179-4.677-1.244-1.542-7.042-4.891-7.76-4.898-.636-.006 1.178 3.162 2.837 4.995-2.054-.557-6.025-.875-6.498-.654-.55.259 2.475 2.526 4.308 3.122 1.328.434 3.42 1.393 4.464 1.892-.869-.052-1.549-.09-1.633-.097l-.026.207c-1.101-.57-4.373-2.19-5.966-2.15-1.924.05-5.475 1.366-5.021 1.768.453.401 5.798 1.483 7.36 1.166 1.172-.233 2.83-.454 3.588-.544l-.026.194c-1.438.162-3.18.389-4.159.628-1.872.46-5.06 2.495-4.528 2.793.46.259 4.67.233 6.705-.201-2.157 1.198-5.157 4.016-4.509 4.204.693.194 7.197-1.367 8.83-2.488 1.496-1.03 2.935-3.97 3.168-4.457 1.16.065 2.37.143 3.446.214-1.127.713-5.59 3.544-7.093 4.982-1.73 1.652-4.01 5.759-3.291 5.727.712-.026 6.413-3.544 7.612-5.118 1.05-1.38 2.597-4.768 2.96-5.578.72.045 1.374.097 1.905.136-.849.894-3.777 4.056-4.95 6.517-1.38 2.89-2.286 9.122-1.399 8.746.881-.376 6.349-7.489 7.1-10.035.616-2.073-.11-4.399-.395-5.195.499.045.842.084.965.116 4.082 1.037 7.327 5.008 7.36 5.047l.635-.512c-.136-.175-3.447-4.224-7.794-5.325l.007.006ZM39.096 98.23c.881-.648 1.82-1.639 2.358-2.248.097 0 .182.013.285.013.855.045 1.963.11 3.136.181-.79.253-3.725 1.218-5.785 2.054h.006Zm2.754-3.045c-.104-.505-.37-1.626-.817-2.591a82.284 82.284 0 0 0 3.894 2.766c-.998-.058-2.035-.117-3.084-.175h.006Z' />*/}
          {/*        <path id='Vector_87' fill='#95E8D6'*/}
          {/*              d='M85.87 81.846c1.976.104 8.344-1.97 8.823-2.5.454-.5-3.576-1.328-6.038-1.225 1.963-.693 5.559-2.876 5.818-3.336.298-.531-3.485-.602-5.325-.013-.96.311-2.566 1.024-3.874 1.639l-.123-.149c.7-.317 2.228-1.004 3.342-1.419 1.497-.55 5.481-4.263 5.663-4.846.174-.583-3.538.15-5.203 1.115-1.373.797-3.317 3.887-3.958 4.95l-.13-.163c-.064.052-.628.44-1.34.94.634-.972 1.91-2.883 2.818-3.946 1.25-1.47 2.643-4.988 2.034-4.917-.512.058-3.732 2.41-5.19 3.959.454-2.43.338-6.084-.2-5.74-.61.382-3.796 6.264-4.05 8.234-.232 1.775 1.174 4.165 1.446 4.606a558.14 558.14 0 0 0-2.294 1.606c.162-1.14.68-5.033.622-7.061-.071-2.39-1.6-6.828-2.06-6.278-.46.551-1.652 7.14-1.276 9.083.31 1.646 1.995 3.777 2.513 4.399a125.651 125.651 0 0 0-2.533 1.827c.104-1.302.247-4.665-.725-7.01-1.231-2.96-5.254-7.793-5.565-6.886-.311.907 1.328 9.73 2.72 11.991.973 1.587 2.3 2.15 3.013 2.352-.052.039-.09.077-.116.103-3.123 3.22-3.829 8.396-3.855 8.617l.81.103c.006-.052.693-5.13 3.628-8.15.09-.09.362-.304.764-.602.169.83.771 3.187 2.378 4.632 1.976 1.775 10.352 4.975 11.304 4.84.953-.143-3.077-4.976-5.772-6.719-2.287-1.483-6.44-2.643-7.625-2.96.428-.311.959-.694 1.549-1.115.731.5 3.822 2.579 5.441 3.207 1.847.713 8.539.732 9.167.382.629-.35-3.466-2.656-5.805-3.161-2.034-.434-7.32-.518-8.654-.531a274.79 274.79 0 0 1 2.824-1.983c.454.292 3.22 2.048 5.034 2.138v-.013Zm-2.313-4.03c.084-.058.155-.11.24-.161.777.233 2.099.59 3.187.68-2.196.363-5.196 1.082-6.005 1.276l2.578-1.794Zm-1.173-3.27c.123 1.055.486 2.144.661 2.63a644.38 644.38 0 0 0-2.533 1.762c.266-.57 1.18-2.572 1.872-4.393Z' />*/}
          {/*        <path id='Vector_88' fill='#95E8D6'*/}
          {/*              d='M86.932 130.032c-1.781-.887-6.349-.771-7.903-.706l.077-.246c-.103-.033-.894-.35-1.904-.752 1.45-.013 4.327 0 6.063.214 2.397.292 7.03-.693 6.537-1.276-.415-.493-5.092-2.229-7.716-2.682 2.85-1.205 6.582-3.848 5.857-4.179-.817-.369-9.16.376-11.39 1.464-2.014.985-3.536 4.101-3.808 4.691l-3.032-1.192c1.237-.635 5.493-2.851 7.573-4.321 2.442-1.73 6.018-6.394 5.124-6.492-.894-.097-8.59 3.214-10.365 4.943-1.503 1.464-2.384 4.768-2.624 5.753-1.632-.635-3-1.153-3.893-1.471 1.431-.796 5.04-2.967 6.815-5.597 2.242-3.33 4.502-10.87 3.336-10.566-1.166.305-9.231 8.111-10.63 11.123-.985 2.112-.655 3.881-.37 4.762a8.227 8.227 0 0 1-.194-.052c-5.513-1.036-11.408 1.814-11.655 1.937l.447.92c.059-.026 5.837-2.825 11.013-1.846.156.032.57.168 1.16.382-.745.745-2.792 3.006-3.187 5.688-.486 3.284 1.963 14.239 2.766 15.133.803.894 3.064-6.653 3.019-10.663-.039-3.414-1.684-8.545-2.183-10.003.628.234 1.392.525 2.235.849-.013 1.114-.046 5.772.42 7.891.532 2.423 5.138 9.413 5.941 9.827.797.408.376-5.454-.719-8.24-.952-2.43-4.515-7.994-5.422-9.4 1.263.486 2.676 1.043 4.023 1.581 0 .673.09 4.774 1.244 6.73 1.257 2.138 7.82 7.34 8.707 7.483.829.13-1.082-4.651-2.896-7.146 2.08 1.568 6.841 3.816 7.502 3.764.758-.058-1.782-4.055-3.667-5.558-.991-.791-2.844-1.97-4.392-2.909l.071-.233c.816.505 2.585 1.626 3.79 2.513 1.606 1.18 8.24 2.773 8.966 2.559.732-.22-2.598-3.582-4.762-4.658l.026-.019Zm-8.124-4.489c-1.01.855-1.904 1.988-2.293 2.507-1.218-.486-2.43-.966-3.589-1.426.784-.116 3.498-.55 5.882-1.081Zm-6.251 2.034c1.367.537 2.656 1.056 3.653 1.451.124.052.227.091.337.136.292.972.83 2.598 1.49 3.796-1.898-2.04-4.722-4.677-5.48-5.377v-.006Z' />*/}
          {/*        <path id='Vector_89' fill='#95E8D6'*/}
          {/*              d='M105.914 169.349c-1.782-.888-6.35-.771-7.904-.706l.078-.246c-.104-.033-.894-.35-1.905-.752 1.451-.006 4.328 0 6.064.214 2.397.291 7.029-.693 6.536-1.276-.414-.493-5.091-2.229-7.715-2.682 2.85-1.205 6.575-3.848 5.856-4.179-.816-.369-9.16.376-11.389 1.464-2.014.985-3.537 4.101-3.809 4.691-1.082-.428-2.112-.836-3.064-1.205.952-.486 5.422-2.773 7.599-4.308 2.442-1.73 6.018-6.395 5.124-6.492-.894-.097-8.59 3.214-10.359 4.943-1.574 1.536-2.364 5.021-2.526 5.792a157.165 157.165 0 0 0-3.984-1.51c1.431-.796 5.04-2.967 6.815-5.597 2.241-3.33 4.502-10.871 3.336-10.566-1.166.304-9.231 8.111-10.63 11.123-.985 2.112-.655 3.881-.37 4.762-.078-.02-.142-.039-.194-.046-5.52-1.036-11.409 1.814-11.655 1.937l.454.92c.058-.026 5.837-2.824 11.013-1.846.155.032.57.169 1.16.382-.746.745-2.793 3.006-3.194 5.688-.486 3.285 1.963 14.24 2.766 15.134.803.894 3.064-6.654 3.019-10.664-.04-3.414-1.691-8.545-2.183-10.002.628.233 1.392.525 2.235.848-.013 1.115-.046 5.773.414 7.891.531 2.423 5.144 9.413 5.94 9.828.798.408.377-5.449-.718-8.241-.953-2.423-4.516-7.994-5.423-9.4 1.264.486 2.67 1.043 4.023 1.581 0 .674.091 4.774 1.244 6.731 1.264 2.138 7.82 7.34 8.707 7.482.829.13-1.082-4.651-2.902-7.145 2.079 1.567 6.841 3.815 7.502 3.764.758-.059-1.782-4.056-3.667-5.559-.985-.79-2.844-1.969-4.392-2.915l.071-.233c.816.505 2.585 1.626 3.79 2.513 1.606 1.179 8.234 2.773 8.966 2.553.732-.22-2.598-3.583-4.762-4.658l.033-.013Zm-8.124-4.49c-1.01.856-1.905 1.989-2.294 2.508-1.218-.486-2.429-.966-3.588-1.426.783-.116 3.498-.55 5.882-1.082Zm-6.252 2.035c1.367.537 2.656 1.056 3.654 1.451.123.052.227.091.337.136.291.972.836 2.598 1.496 3.796-1.898-2.041-4.722-4.671-5.48-5.377l-.007-.006Z' />*/}
          {/*        <path id='Vector_90' fill='#95E8D6'*/}
          {/*              d='M49.157 160.551c2.87-1.671 9.912-10.417 10.106-11.602.195-1.186-7.113 1.755-10.216 4.288-2.643 2.158-5.63 6.647-6.465 7.943-.208-.635-.46-1.412-.733-2.274.882-.68 4.542-3.563 5.909-5.248 1.561-1.924 4.152-9.892 3.977-10.767-.175-.881-4.495 3.104-5.992 5.695-1.302 2.254-3.434 8.519-3.965 10.1-.408-1.29-.848-2.741-1.276-4.127.525-.421 3.673-3.045 4.483-5.17.881-2.319.862-10.695.421-11.473-.414-.732-2.96 3.751-3.777 6.725-.071-2.605-1.282-7.729-1.736-8.215-.518-.557-2.06 3.92-2.06 6.336 0 1.263.233 3.453.46 5.247l-.227.091c-.11-.952-.343-3.038-.402-4.528-.077-1.989-2.967-8.169-3.595-8.603-.628-.435-1.179 4.269-.674 6.627.421 1.943 3.356 5.448 4.373 6.621l-.246.09c.039.104.285.92.602 1.957-.913-1.127-2.695-3.382-3.614-4.872-1.27-2.053-4.924-5.066-5.073-4.314-.13.634 1.432 5.37 2.714 7.702-2.72-1.477-7.113-2.74-6.912-1.969.22.868 6.005 6.932 8.247 7.988 2.028.958 5.41.207 6.038.051l1.056 3.44c-1.102-.55-5.682-2.818-8.202-3.543-2.876-.836-8.752-.719-8.266.039.48.758 7.865 4.709 10.326 5.014 2.151.272 5.41-.985 6.206-1.309.493 1.568.907 2.877 1.206 3.732-1.516-.622-5.462-2.093-8.63-1.834-3.997.324-11.304 3.259-10.345 3.985.965.719 12.094 2.157 15.327 1.373 2.261-.551 3.44-1.911 3.952-2.682.032.078.058.136.084.181 2.63 4.963 8.532 7.787 8.778 7.904l.434-.926c-.058-.026-5.843-2.806-8.311-7.457-.078-.143-.227-.551-.428-1.147 1.05.117 4.094.311 6.433-1.049l.013-.02Zm-14.2-9.814c1.302.259 2.746.246 3.388.226l1.12 3.693c-.583-.538-2.61-2.39-4.515-3.919h.007Zm4.346-.143c-.038-.123-.07-.233-.103-.343.576-.836 1.51-2.268 2.034-3.531-.408 2.753-.712 6.608-.79 7.638-.428-1.406-.83-2.734-1.14-3.757v-.007Z' />*/}
          {/*      </g>*/}
          {/*    </g>*/}
          {/*  </g>*/}
          {/*  <path id='Vector_91' fill='#F5EDE6'*/}
          {/*        d='M44.525 57.89c.389-.998-12.762 7.152-12.762 7.152l9.866 4.968s-3.297 5.993-3.232 6.401c.07.408 6.95 3.816 10.132 5.863 3.18 2.04 8.098 6.698 8.098 6.698s-2.929-6.329-5.384-12.865c-2.449-6.537-7.19-17-6.711-18.224l-.007.006Z' />*/}
          {/*  <path id='Vector_92' fill='#F5EDE6'*/}
          {/*        d='M71.637 55.065c-.389-.998 16.079 9.828 16.079 9.828l-11.842 4.262s4.088 7.081 4.016 7.49c-.071.407-9.776 5.448-12.956 7.488-3.181 2.04-10.314 4.846-10.314 4.846s5.772-9.698 8.221-16.235c2.449-6.536 7.269-16.448 6.796-17.673v-.006Z' />*/}
          {/*</g>*/}
          {currentTop}
        </g>
        <g id='Accessories'>
          <g id='Cap'>
            <path id='Vector_93' fill='#CECFDC'
                  d='M39.328 12.272s2.115-7.406 4.841-9.692c6.425-5.38 28.829-1.506 30.216 3.974 2.763 10.938 1.142 19.44 1.142 19.44S52.82 6.958 39.333 12.267l-.005.005Z' />
            <path id='Vector_94' fill='#2A3447'
                  d='M41.204 24.013s-12.276-7.578-8.47-14.038c3.06-5.203 25.31-7.074 32.484.1 3.704 3.703 6.908 13.29 6.908 13.29-4.288-3.404-32.793-6.056-30.922.648Z' />
          </g>
          <g id='sunglasses'>
            <path id='Vector_95' fill='#2A3447'
                  d='M69.327 30.235a4.324 4.324 0 0 0-.114-.675c-.122-.39-.505-.463-2.102-.602-.962-.081-2.241-.162-3.48-.244h-.097c-2.64-.17-4.905.204-5.826.456-.823.22-1.874.87-2.02 1.236a.6.6 0 0 0-.034.114c-.415.138-.945.17-1.564.09a1.513 1.513 0 0 1-.432-.098c0-.05 0-.106-.024-.163-.122-.374-1.149-1.074-1.956-1.334-.904-.293-3.145-.789-5.793-.756h-.098c-1.238.016-2.526.032-3.487.065-1.597.057-1.988.106-2.127.496a4.13 4.13 0 0 0-.154.667c-.025.203-.106.83.456 1.016.032.009.073.025.106.033.293.081.342.13.366.22.017.08.057.276.098.504.245 1.342.514 2.7.84 3.245.073.122.138.244.203.374.277.528.587 1.13 1.646 1.578.823.35 2.061.642 3.308.675a7.047 7.047 0 0 0 1.5-.106c1.678-.317 3.071-1.285 3.552-2.489.146-.366.35-.91.554-1.48.187-.504.424-1.163.62-1.65.22.08.456.121.66.145.22.025.431.041.635.05a4.18 4.18 0 0 0 1.393-.171c.171.496.383 1.195.546 1.716.18.585.35 1.138.48 1.504.424 1.22 1.76 2.26 3.423 2.66.472.113.978.17 1.49.187 1.24.032 2.502-.204 3.333-.505 1.084-.39 1.426-.976 1.728-1.496.073-.122.138-.244.22-.366.358-.529.692-1.87 1.002-3.196.057-.22.098-.415.122-.496.025-.09.082-.13.375-.195l.106-.025c.57-.146.521-.78.513-.992l.033.008Z' />
            <path id='Vector_96' fill='#2A3447'
                  d='M68.944 29.251s4.628-2.106 5.646-2.245c1.019-.138 1.41 2.001 1.41 2.001l-1.084.512s-.13-1.252-.554-1.049c-.423.204-5.548 2.757-5.548 2.757l.13-1.976Z' />
          </g>
          <g id='Pouch'>
            <g id='Layer_1' clipPath='url(#clip0_232_261)'>
              <path id='Vector_97' fill='#595A63'
                    d='M31 160.705s41.681.926 57.587-.705c0 0 .962.88.962 2.385s-8.85 4.726-24.522 4.519C49.354 166.702 31 165.651 31 165.651v-4.946Z' />
              <path id='Vector_98' fill='#676470'
                    d='M48.487 163.266s.88 6.648 5.903 12.115c5.022 5.462 15.07 7.852 22.98 7.726 7.911-.126 11.792-4.083 12.807-5.948 3.203-5.88 1.132-15.524 1.132-15.524s-34.907-1.132-42.817 1.635l-.005-.004Z' />
              <path id='Vector_99' fill='#504E57'
                    d='M65.409 164.577s19.212-.377 21.471-.431c2.26-.054 2.197 7.088.912 8.113-1.289 1.024-18.615 2.47-20.685.381-2.071-2.088-1.694-8.063-1.694-8.063h-.004Z' />
              <path id='Vector_100' fill='#504E57'
                    d='M57.12 164.142s-.282 3.485 0 7.438c.284 3.958 1.569 7.156 1.569 7.156l1.42.759s-1.546-6.922-1.42-10.569c.125-3.652.408-4.78.408-4.78h-1.976v-.004Z' />
              <path id='Vector_101' fill='#504E57'
                    d='M90.083 164.142s1.747 7.78.871 11.234c0 0 .917-2.655.957-5.206.04-2.552-.916-6.028-.916-6.028h-.916.004Z' />
              <path id='Vector_102' fill='#D1D2DE'
                    d='M57.31 164.142s-1.406 3.705-1.007 3.993c.4.292.737.445 1.007 0 .27-.445.467-3.796.467-3.796l-.468-.202v.005Z' />
              <path id='Vector_103' fill='#D1D2DE'
                    d='M90.919 164.142s1.406 3.705 1.006 3.993c-.4.292-.737.445-1.006 0-.27-.445-.468-3.796-.468-3.796l.468-.202v.005Z' />
              <path id='Vector_104' fill='#D1D2DE'
                    d='M80.273 172.03a.999.999 0 0 0-.998-.998.995.995 0 0 0-.997.998c0 .552.445.997.997.997a.995.995 0 0 0 .998-.997Z' />
              <path id='Vector_105' fill='#676470'
                    d='M39.463 160.045H41.3c.418 0 .755.337.755.755v5.323a.753.753 0 0 1-.755.754h-1.837a.753.753 0 0 1-.755-.754V160.8c0-.418.337-.755.755-.755Z' />
              <path id='Vector_106' fill='#D1D2DE' d='M40.263 160.517c-.97 0-.97 1.504 0 1.504s.97-1.504 0-1.504Z' />
              <path id='Vector_107' fill='#D1D2DE' d='M40.402 164.613c-.97 0-.97 1.505 0 1.505s.97-1.505 0-1.505Z' />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_232_261'>
          <path fill='#fff' d='M31 160h60.997v23.111H31z' />
        </clipPath>
      </defs>
    </svg>

  );
}