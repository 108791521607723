import React, { ChangeEvent, useState } from 'react';
import { useGameMechanic } from 'src/hooks';
import { TEssayGame } from './types';
import GameNotFound from '../../../not-found';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The Essay game component
 * @constructor
 */
function EssayGameEditor() {
  const [currentEssay, setCurrentEssay] = useState<string>('');
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    points,
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    maxLength,
    question,
    answer,
    text,
    keyWords
  } = gameMechanic.content as unknown as TEssayGame;

  // Validation function
  function isValidInput(input: string): boolean {
    // Regular expression for valid characters
    // This includes English letters (both uppercase and lowercase), Maltese specific characters, and space
    const validCharactersRegex = /^[A-Za-zċġħżĊĠĦŻ0-9\s]+$/;

    return validCharactersRegex.test(input);
  }

  const changeAnswer = (newText: string) => {
    if (!isValidInput(newText)) {
      alert("Input contains invalid characters.");
      return;
    }

    let currentPoint = 100;
    keyWords.map((keyWord) => {
      if (!newText.includes(keyWord)) {
        currentPoint = 0;
      }
    })
    changeCorrectAnswersPoints(points + currentPoint);
    setCurrentEssay(newText);
  }

  return (
    <div className='essay'>
      <div dangerouslySetInnerHTML={{__html:question ?? text ?? ''}} />
      <input
        className='form-control'
        onChange={(e) => changeAnswer(e.target.value)}
        value={currentEssay}
        maxLength={maxLength}
        placeholder="type your answer here"
      />
    </div>
  );
}

export default EssayGameEditor;
