import { TJumbleWordsGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the "highlight word game"
 *    game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function jumbleWordsGameDbToViewConverter(
  dbData: Record<string, any>
): TJumbleWordsGame {
  return {
    letters: dbData.letters,
    filePath: dbData.filePath || null,
    answer: dbData.answer,
    draggedItems: dbData.draggedItems,
    text: dbData.text || null
  }
}
