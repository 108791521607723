import { motion } from 'framer-motion';
import { memo, useEffect, useRef, useState } from 'react';
import AvatarIcon from '../../assets/svg/avatar-icon';
import { navigationLinks } from '../../constants';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { getNotifications, getScores } from '../../api/v0';
import LogOutIcon from 'src/assets/images/sidebar-icons/logout-icon.svg';
import {AzureAuth} from "../../azure-component";

interface LinkObject {
  title: string;
  url: string;
  icon: any;
  role?: string[]
}


const menuVariants = {
  open: {
    y: 0,
    opacity: 1,
    width: 'auto',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      opacity:{delay:0.6}
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    width: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};


export const navbar = {
  open:{height:"100vh"},
  close:{height: "70px"}

}
const MenuContent = ({ isOpen, isMobile }: { isOpen: boolean, isMobile: boolean }) => {
  const root = useRef(document.getElementById('root'));
  const { user, logout } = useAuth();
  const [notifications, setNotifications] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (user?.account?.localAccountId) {
      getNotifications(user.account.localAccountId, setNotifications, (errorMessage: string) => console.log(errorMessage));
    }
  }, [user]);

  const [currentPoints, setCurrentPoints] = useState<number>(0);


  const parseCurrentPoints = (finalData: Record<string, any>[]) => {
    const finalPoints = finalData.reduce((acc, curr) => +curr.finalPoints + acc, 0);
    setCurrentPoints(finalPoints);
  };

  useEffect(() => {
    if (isOpen && user) {
      if (!isMobile)
        root.current?.classList.add('open');
      getScores(user.account.localAccountId, parseCurrentPoints);
    } else {
      root.current?.classList.remove('open');
    }
    return ()=> root.current?.classList.remove('open')
  }, [isOpen, user]);

  return (
    <motion.div className='sidebar-menu' variants={navbar}>
      <motion.div
        className='d-flex flex-column justify-content-md-between justify-content-around px-3 py-5 h-100 position-relative'>
        <motion.div variants={menuVariants}>
          <svg viewBox='0 0 112 29' fill='none' xmlns='http://www.w3.org/2000/svg' className='w-75 just-fy-self-center'>

            <path
              d='M20.54 13.7H25.33V24.56C22.49 26.86 18.52 28.02 14.67 28.02C6.23 28.02 0 22.14 0 14.01C0 5.88 6.23 0 14.75 0C19.3 0 23.12 1.52 25.68 4.44L22.49 7.55C20.35 5.41 17.9 4.44 14.98 4.44C9.18 4.44 5.1 8.37 5.1 14.01C5.1 19.65 9.19 23.58 14.94 23.58C16.89 23.58 18.79 23.19 20.54 22.18V13.7Z'
              fill='white' />
            <path d='M31.98 0.390015H37.04V23.35H51.28V27.63H31.98V0.390015Z' fill='white' />
            <path
              d='M80.31 27.63L80.27 9.58001L71.32 24.52H69.06L60.11 9.81001V27.63H55.29V0.390015H59.45L70.27 18.44L80.89 0.390015H85.05L85.09 27.63H80.3H80.31Z'
              fill='white' />
            <path
              d='M90.15 24.75L91.9 20.82C94.08 22.57 97.54 23.82 100.89 23.82C105.13 23.82 106.92 22.3 106.92 20.28C106.92 14.4 90.73 18.26 90.73 8.14001C90.73 3.74001 94.23 0.0100098 101.7 0.0100098C104.97 0.0100098 108.39 0.87001 110.77 2.42001L109.17 6.35001C106.72 4.91001 104.03 4.21001 101.66 4.21001C97.46 4.21001 95.75 5.84001 95.75 7.91001C95.75 13.71 111.9 9.93001 111.9 19.93C111.9 24.29 108.36 28.02 100.89 28.02C96.65 28.02 92.41 26.7 90.15 24.75Z'
              fill='white' />
          </svg>
        </motion.div>
        <motion.div className='d-flex flex-column align-items-center position-relative' variants={menuVariants}>
          <div
            className='mt-3 sidebar-img rounded-circle bg-white d-flex align-items-center justify-content-center overflow-hidden'>
            <AvatarIcon />
          </div>
          <div className='text-center mt-2 text-white'>
            <h2>{user?.first_name ?? user?.email}</h2>
            <span className='role text-nowrap'>{user?.role}</span>
          </div>
        </motion.div>
        <ul className='mb-0 list-unstyled'>
          {navigationLinks.map((link: LinkObject) => {
            if ((link.role && link.role.includes(user?.role?.toLowerCase() ?? 'na')) || !link.role || user?.email?.toLowerCase() === 'test.mentor02@mcast.edu.mt') {
              return (
                <motion.li
                  className='d-flex align-items-center text-nowrap py-2'
                  key={link.title} variants={menuVariants}><img
                  width='25' height='25'
                  src={link.icon} alt={link.title}/><Link
                  className='ms-2 text-decoration-none'
                  to={link.url}>{link.title}  </Link>
                </motion.li>
              );
            }
          })}
          <motion.li
            className='d-flex align-items-center text-nowrap py-2'
            key={'logout'}
            variants={menuVariants}
            onClick={logout}
          >
            <img
              width='25'
              height='25'
              src={LogOutIcon}
              alt={'logout'}
            />
            <label className='ms-2 text-decoration-none'>Log Out</label>
          </motion.li>
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default memo(MenuContent);