import React, { ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { IslandGameMechanicFactory } from '../game-mechanics';
import { ButtonMotion, NoEditorPermission } from 'src/components';
import { useAuth, useEditGame } from 'src/hooks';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import EditorPopups from '../../../components/popups';
import { PATH_GLMS_PLAYER } from '../../../routes/paths';
import { GameMechanicPickerView } from './index';
import { uploadFile } from '../../../api/v0';
import EditorCloseBtn from '../../../assets/svg/buttons/editor-close-btn';
import DeleteBtn from '../../../assets/svg/buttons/delete-btn';
import {motion, Reorder} from 'framer-motion';
import TinyMceEditor from '../../../components/tiny-mce-editor';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The playable view component of the game that renders the game mechanic and
 *    allow to edit the game.
 * @constructor
 */
function IslandGameEditView() {
  /**
   * @description
   *    The editing game hook methods and variables in order to parse and
   *    process the game play mode.
   */
  const {
    gameMechanic,
    game,
    page,
    error,
    submitUpdatedGame,
    updateGame,
    changePage,
    showTheHintsPopup
  } = useEditGame();

  const { user } = useAuth();

  // Navigation
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showFile, setShowFile] = useState<boolean>(game?.file?.show ?? false);
  const [showPages, setShowPages] = useState(false);
  const [reorderContent, setReorderContent] = useState<any[]>(game?.content || []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file && (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpg')) {
      setSelectedFile(file);
      handleFileUpload(file);
    } else {
      alert('Please upload a PDF/PNG/JPG file.');
      setSelectedFile(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Programmatically click the hidden file input
  };

  const handleFileUpload = (file: File) => {
    if (file && game) {
      uploadFile(
        `${game.id}/image/${file.name}`,
        file,
        (url: string) => updateGame({
          ...game,
          file: {
            url,
            type: file.type === 'application/pdf' ? 'pdf' : 'image',
            show: showFile ?? false
          }
        }),
        (error: string) => console.log(error)
      );
    }
  };

  const handleCloseEditView = () => {
    navigate(`/${PATH_GLMS_PLAYER.games.game.get(game?.id || '0')}`);
  };

  /**
   * @description
   *    The method is handling the page content change event.
   */
  const handleGameMetadataContentChange = (
    contentType: 'title' | 'description',
    value: string
  ) => {
    if (game) {
      updateGame({
        ...game,
        [contentType]: value
      });
    }
  };

  /**
   * @description
   *    The method is handling the event of the submit button click.
   *    The logic is to submit
   *    and save the final edited game data into the server.
   */
  const handleGameSubmitClick = () => {
    if (game && gameMechanic) {
      const { id } = game;
      let finalContent = game.content ? [...game.content] : [];
      finalContent[page] = gameMechanic;
      submitUpdatedGame({
        ...game,
        file: game?.file ? {
          ...game.file,
          show: showFile
        } : null,
        timer: timerMinutes ?? 0,
        hints_timer: hintsTimer ?? 0,
        points_by_time: pointsByTime ?? 0,
        points_by_answers: pointsByAnswers ?? 0,
        content: finalContent
      });
    }
  };

  /**
   * @description
   *    The method is handling the page change event
   * @param {number} pageNumber
   *    The final page number that need to be selected
   */
  const handlePageChangeClick = (pageNumber: number) => {
    if (game && gameMechanic) {
      let finalContent = game.content ? [...game.content] : [];
      finalContent[page] = gameMechanic;

      updateGame({
        ...game,
        content: finalContent
      });
    }

    changePage(pageNumber);
  };

  /**
   * @description
   *    The method is changing the page of the internal storage
   */
  const handleOnNextPageClick = () => {
    handlePageChangeClick(page + 1);
  };

  /**
   * @description
   *    The method is changing the page to the previous one in the internal
   *    storage.
   */
  const handleOnPrevPageClick = () => {
    if (page > 0) {
      handlePageChangeClick(page - 1);
    }
  };

  /**
   * @description
   *    The method is changing the game to have a randomized order every time
   */

  const handleRandomizeClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (game && gameMechanic) {
      updateGame({
        ...game,
        randomize: e.target.checked
      });
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null); // Reference to the file input
  const [timerMinutes, setTimerMinutes] = useState<number>(game?.timer ?? 0);
  const [hintsTimer, setHintsTimer] = useState<number>(game?.hints_timer ?? 0);
  const [pointsByAnswers, setPointsByAnswers] = useState<number>(game?.points_by_answers ?? 0);
  const [pointsByTime, setPointsByTime] = useState<number>(game?.points_by_time ?? 0);

  useEffect(() => {
    if (game?.timer && timerMinutes !== game.timer) {
      setTimerMinutes(game.timer);
      setPointsByTime(game.points_by_time ?? 0);
      setPointsByAnswers(game.points_by_answers ?? 0);
      setHintsTimer(game.hints_timer ?? 0);
    }
  }, [game]);

  const handleOrderChange = (index: number, finalIndex: string) => {
    const finalContent = game?.content?.map((contentData, currentIndex) => {
      if (currentIndex === index) {
        if (parseInt(finalIndex) < 1) {
          return null;
        }
        return game?.content?.[parseInt(finalIndex) - 1];
      }

      if (currentIndex === parseInt(finalIndex) - 1) {
        return game?.content?.[index];
      }

      return contentData;
    });

    if (finalContent) {
      setReorderContent(finalContent.filter(item => item !== null));
    }
  };

  const handleRemovePage = (pageIndex: number) => {
    const finalReorder = reorderContent.filter((contentData, index) => index !== pageIndex);
    setReorderContent(finalReorder);
  }

  const submitReOrder = () => {
    if (reorderContent && game) {
      updateGame({
        ...game,
        content: reorderContent
      });
    }
  };

  useEffect(() => {
    if (reorderContent.length > 0)
      setReorderContent(prev => prev.map(mechanic => ({ ...mechanic, id: Math.random().toString(16).slice(2) })));
  }, [reorderContent.length]);

  if (!user || (user?.role?.toLowerCase() === 'student' && game?.created_by?.toLowerCase() !== user?.email.toLowerCase()) || user?.email?.toLowerCase() !== 'test.mentor02@mcast.edu.mt') {
    return <NoEditorPermission />;
  }

  /**
   * @description
   *    The view of the Island game play component
   */
  if (gameMechanic) {
    return (
      <Container className='islands editor'>
        <Modal show={showPages} onHide={() => setShowPages(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Change orders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Reorder.Group className='game-order-list' axis='y' onReorder={setReorderContent} layoutScroll
                           style={{ overflowY: 'scroll' }} values={reorderContent}>
              {reorderContent.map((mechanic, index) => (
                <Reorder.Item
                  key={`${mechanic.category}-${mechanic.id}`}
                  value={mechanic}
                  id={`${mechanic.category}-${mechanic.id}`}
                >
                  <span>{index + 1}. {mechanic.category} <DeleteBtn onClick={() => handleRemovePage(index)}/></span>
                </Reorder.Item>
              ))}
            </Reorder.Group>
            {/* {game?.content?.map((mechanic, index) => (
              <label key={`label-${index}`}><input onChange={(e) => handleOrderChange(index, e.target.value)} /> {mechanic.category}</label>
            ))}*/}
          </Modal.Body>
          <Modal.Footer>
            <ButtonMotion variant={'success'} onClick={submitReOrder}>submit</ButtonMotion>
          </Modal.Footer>
        </Modal>
        <div className='game-wrapper animate'>
          <EditorCloseBtn close={handleCloseEditView} />
          <ButtonMotion variant={'warning'} onClick={() => setShowPages(true)}>Pages</ButtonMotion>
          {/* HEADER SECTION OF THE GAME PLAYABLE VIEW */}
          <div className='game-info'>
            <input
              className='w-100'
              placeholder='Game Title'
              value={game?.title}
              onChange={(e) => handleGameMetadataContentChange('title', e.target.value)}
            />
            <TinyMceEditor
              maxHeight={250}
              value={game?.description ?? ''}
              handleChange={(value) => handleGameMetadataContentChange('description', value)}
            />
          </div>
          {/* BODY/PLAYABLE SECTION OF THE GAME VIEW */}
          <div className={`game-container editor ${gameMechanic.category}`}>
            <IslandGameMechanicFactory
              category={gameMechanic.category}
              isEditView={true}
              page={page}
              gameId={game?.id}
            />
            <div className='flex-column flex-md-row d-flex justify-content-md-end main-btns mt-5'>
            <div className='d-flex justify-content-center justify-content-md-start'>
              {/*<DeleteBtn onClick={() => {}} />*/}
              <ButtonMotion
                className='me-3'
                variant='hint'
                onClick={() => showTheHintsPopup(true)}
              >
                Add Feedback
              </ButtonMotion>
              <ButtonMotion
                className='me-3'
                variant='hint'
                onClick={() => showTheHintsPopup(true)}
              >
                Add hints
              </ButtonMotion>
            </div>
              <div className='slide-btns mt-3 mt-md-0 d-flex flex-grow-1 flex-md-grow-0'>
                <ButtonMotion
                  variant='success'
                  className={classnames('col-6 col-md-auto', { disabled: page === 0 ?? false })}
                  onClick={handleOnPrevPageClick}
                >
                  &#60; Previous
                </ButtonMotion>
                <ButtonMotion
                  variant='success'
                  className={classnames('col-6 col-md-auto', { disabled: false })}
                  onClick={handleOnNextPageClick}
                >
                  Next &#62;
                </ButtonMotion>
              </div>
            </div>
          </div>
          {/* FOOTER SECTION OF THE GAME PLAYABLE VIEW */}
          <div className='configs'>
            <Row>
              <Col md={9}>
                <div className='config-item d-flex'>
                  <label>Select Time Limit <br /> <span>(in minutes)</span></label>
                  <input type={'number'} onChange={(e) => setTimerMinutes(+e.target.value)} value={timerMinutes} />
                </div>
                <div className='config-item d-flex'>
                  <label>Percentage limit of the total time <br /> <span>(under which the students gets an additional point)</span></label>
                  <input type={'number'}
                         onChange={(e) => setPointsByTime(+e.target.value)}
                         value={pointsByTime} />
                </div>
                <div>
                  <div className='d-flex justify-content-between align-items-center mt-2'>
                    <input
                      ref={fileInputRef}
                      type='file'
                      accept='application/pdf,image/png,image/jpeg'
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <ButtonMotion
                      variant='outline-success'
                      onClick={handleButtonClick}
                      className={classnames({ disabled: false })}
                    >
                      Upload results PDF
                    </ButtonMotion>
                    <Form.Check
                      className='mt-2'
                      type='switch'
                      id='custom-switch'
                      label='Show file?'
                      checked={showFile}
                      onChange={() => setShowFile(!showFile)}
                    />
                    <Form.Check
                      type='switch'
                      id='randomize-switch'
                      label='Randomize games'
                      checked={game?.randomize}
                      onChange={handleRandomizeClick}
                    />
                  </div>
                  {selectedFile?.name
                    ? (<p className='mt-2' style={{ marginLeft: 8 }}>{selectedFile.name}</p>)
                    : (game?.file?.url && <a href={game.file.url}>attached file</a>)
                  }
                </div>
              </Col>
              <Col md={3}>
                <div className='editor-btns'>
                  <div>
                    <ButtonMotion className="d-block" variant={'warning'} onClick={() => showTheHintsPopup(true)}>Info </ButtonMotion>
                    <ButtonMotion
                      variant='secondary'
                      onClick={handleGameSubmitClick}
                      className={classnames( 'mt-2',{ disabled: false })}
                    >
                      Save
                    </ButtonMotion>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

        </div>
        <br />

        <EditorPopups />
      </Container>
    );
  }

   return <GameMechanicPickerView />

}

export default memo(IslandGameEditView);
